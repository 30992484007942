import React, {Component} from 'react';
import { Line } from 'react-chartjs-2';


export default class Graphic extends Component {
    render() {
        const { items } = this.props
        // console.log(items)
        const data = {
            labels: [1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            datasets: [
                {
                    label: 'ваш риск',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(17,87,204,0.4)',
                    borderColor: '#1157CC',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#1157CC',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#1157CC',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: items.illness_chance
                },
                {
                    label: 'средний риск',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(255,164,125,0.4)',
                    borderColor: '#FFA47D',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#FFA47D',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#FFA47D',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: items.illness_chance_middle
                }
            ]
        };

        return (
            <div>
                <p className="graphicTitle">ваш риск</p>
                <Line ref="chart" data={data}  options={{ scales: {
                        yAxes: [{
                            ticks: {
                                suggestedMin: items.chart_percent[0].suggestedMin,
                                stepSize: items.chart_percent[1].stepSize,
                                suggestedMax: items.chart_percent[2].suggestedMax
                            }
                        }]
                    } }} />
            </div>
        );
    }

    componentDidMount() {
        const { datasets } = this.refs.chart.chartInstance.data
    }
}