export const data = [
    {
        link: 'depression',
        title: 'Депрессия',
        description: 'Психическое расстройство, которое является ответной реакцией организма на стресс, эмоциональное перенапряжение или физическое переутомление.',
        recommendation: 'Вы обладаете вариантом гена, который эффективно превращает неактивную форму Витамина А в активную. Потребление продуктов, которые имеют более высокое содержание активного витамина А для вас не является обязательным.',
        doctors: [
            {text: 'Психотерапевт'},
            {text: 'Психиатр'},
            {text: 'Клинический психолог'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'пожилой возраст, одиночество'},
                    {text: 'предменструальный, послеродовой период'},
                    {text: 'ранняя потеря родителей'},
                    {text: 'наследственная предрасположенность'},
                    {text: 'особенности воспитания'},
                    {text: 'пищевые расстройства, гиповитаминоз'}
                ]
            },
            {
                causesList: [
                    {text: 'эмоциональная чувствительность'},
                    {text: 'гормональный дисбаланс при менопаузе'},
                    {text: 'отсутствие социальной поддержки'},
                    {text: 'эндокринные и ревматоидные заболевания'},
                    {text: 'частые стрессовые ситуации'},
                    {text: 'онкология, хронический болевой синдром'}
                ]
            },
            {
                causesList: [
                    {text: 'неврологические расстройства'},
                    {text: 'зависимость от наркотиков, алкоголя и никотина'},
                    {text: 'прием некоторых медикаментов'},
                    {text: 'инфекционные и аутоиммунные заболевания'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'сниженная самооценка'},
                    {text: 'неадекватное чувство вины'},
                    {text: 'нарушение концентрации'},
                    {text: 'резкое ухудшение аппетита'}
                ]
            },
            {
                symptomsList: [
                    {text: 'нарушение сна, бессонница'},
                    {text: 'заторможенность мышления'},
                    {text: 'выраженная утомляемость'},
                    {text: 'потеря интереса к жизни'}
                ]
            },
            {
                symptomsList: [
                    {text: 'раздражительность'},
                    {text: 'суицидальные мысли'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'Тесты самооценки'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'Госпитальная шкала тревоги и депрессии'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'Медикаментозное – врач назначает антидепрессанты и оставляет оптимальную схему их приема с учетом индивидуальных особенностей пациента'}
                ]
            },
            {
                treatmentList: [
                    {text: 'Консервативное – достаточно эффективными являются групповые и индивидуальные тренинги, регулярные сеансы у психотерапевта'}
                ]
            }
        ]
    },{
        link: 'alcohol',
        title: 'Алкоголизм',
        description: 'Тяжелое прогрессирующее заболевание, при котором человек становится психологически и физически зависимым от спиртного.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'проблемы с адаптацией в обществе'},
                    {text: 'замкнутость, застенчивость'},
                    {text: 'хронический тяжелый стресс'}
                ]
            },
            {
                causesList: [
                    {text: 'смерть родного человека'},
                    {text: 'неурядицы дома или на работе'},
                    {text: 'наследственная предрасположенность'}
                ]
            },
            {
                causesList: [
                    {text: 'негативные факторы окружающей среды'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'снижение рвотного рефлекса'},
                    {text: 'приступы агрессии, раздражительность'}
                ]
            },
            {
                symptomsList: [
                    {text: 'снижение устойчивости к алкоголю'},
                    {text: 'провалы в памяти, спутанность сознания'}
                ]
            },
            {
                symptomsList: [
                    {text: 'нарушение сна, поведенческие расстройства'},
                    {text: 'синдром отмены (бессонница, рвота)'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'установить наличие алкоголизма у человека можно после разговора с больным либо его родственниками'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'тест на определение уровня алкоголя в крови, а также анализ симптомов (потеря контроля над количеством выпитого, частичная амнезия и хронические запои)'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'медикаментозное – направленное на устранение симптомов интоксикации организма и формирование у пациента стойкого отвращения к спиртным напиткам'},
                    {text: 'консервативное – психотерапия и посещение групп поддержки'}
                ]
            },
            {
                treatmentList: [
                    {text: 'комбинированное – сочетание консервативной и медикаментозной терапии является наиболее эффективным'}
                ]
            }
        ]
    },{
        link: 'celiac',
        title: 'Целиакия',
        description: 'Расстройство пищеварения, связанное с непереносимостью глютена (белка, содержащегося в злаковых культурах), который вызывает патологическую иммунную реакцию организма.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'генетическая предрасположенность'},
                    {text: 'лимфоцитарный колит'},
                    {text: 'аутоиммунный тиреоидит'}
                ]
            },
            {
                causesList: [
                    {text: 'хронический активный гепатит'},
                    {text: 'синдром Дауна'},
                    {text: 'отягощенный семейный анамнез'}
                ]
            },
            {
                causesList: [
                    {text: 'сахарный диабет 1-го типа'},
                    {text: 'синдром раздраженной кишки'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'частый жидкий стул сероватого цвета'},
                    {text: 'снижение аппетита'},
                    {text: 'остеопороз'},
                    {text: 'зудящая папулезно-везикулярная сыпь'},
                    {text: 'вялость и усталостьх'},
                    {text: 'боль в костях'}
                ]
            },
            {
                symptomsList: [
                    {text: 'гипергидроз (повышенная потливость)'},
                    {text: 'потеря массы тела'},
                    {text: 'боль в животе'},
                    {text: 'тревожность, бессонница'},
                    {text: 'отеки в конечностях'},
                    {text: 'метеоризм'}
                ]
            },
            {
                symptomsList: [
                    {text: 'примеси крови в каловых массах'},
                    {text: 'психические расстройства'},
                    {text: 'бледность кожи'},
                    {text: 'частая смена настроения'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'фиброэзофагогастродуоденоскопия'},
                    {text: 'биопсия двенадцатиперстной кишки с помощью эндоскопии'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'антитела к тканевой трансглутаминазе'},
                    {text: 'ультразвуковое исследование органов брюшной полости'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'консервативное – больному следует пожизненно исключить из своего пищевого рациона продукты на основе злаков, рекомендована безглютеновая диета'}
                ]
            },
            {
                treatmentList: [
                    {text: 'медикаментозное – иногда назначаются противодиарейные препараты и мультивитаминные комплексы'}
                ]
            }
        ]
    },{
        link: 'crohn',
        title: 'Болезнь Крона',
        description: 'Это хроническое воспалительное заболевание, которое поражает органы желудочно-кишечного тракта.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'молодой возраст (от 16 до 40 лет.)'},
                    {text: 'генетическая предрасположенность'}
                ]
            },
            {
                causesList: [
                    {text: 'сниженный иммунитет'},
                    {text: 'несбалансированное питание'}
                ]
            },
            {
                causesList: [
                    {text: 'наличие вредных привычек'},
                    {text: 'частые бактериальные инфекции'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'диарея, общая слабость'},
                    {text: 'боли и спазмы в области живота'},
                    {text: 'отсутствие аппетита'},
                    {text: 'дискомфорт после приема пищи'},
                    {text: 'вздутие живота, метеоризм'}
                ]
            },
            {
                symptomsList: [
                    {text: 'примеси крови в каловых массах'},
                    {text: 'снижение массы тела'},
                    {text: 'воспалительные процессы в кишечнике'},
                    {text: 'быстрая утомляемость'},
                    {text: 'повреждения перианальной области'}
                ]
            },
            {
                symptomsList: [
                    {text: 'повышение температуры'},
                    {text: 'повреждение слизистых оболочек'},
                    {text: 'сухость кожи, ломкость ногтей'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'общий анализ крови'},
                    {text: 'копрограмма'},
                    {text: 'рентгенографическое исследование'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'ирригоскопия'},
                    {text: 'УЗИ кишечника'},
                    {text: 'биопсия'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'гастродуоденоскопия'},
                    {text: 'колоноскопия'},
                    {text: 'компьютерная томография'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'медикаментозное – пробиотики, антибиотики, иммуносупрессоры, противовоспалительные, противодиарейные препараты и витаминные комплексы)'},
                    {text: 'консервативное – соблюдение специальной диеты, умеренная физическая активность'}
                ]
            },
            {
                treatmentList: [
                    {text: 'хирургическое – применяется при осложнениях и неэффективности консервативных методов терапии'}
                ]
            }
        ]
    },{
        link: 'pancreatic-cancer',
        title: 'Рак поджелудочной железы',
        description: 'Злокачественное новообразование, вызванное неконтролируемым ростом атипичных клеток поджелудочной железы.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'несбалансированный рацион с преобладанием жирной и острой пищи'},
                    {text: 'курение, злоупотребление алкоголем'},
                    {text: 'желчнокаменная болезнь, цирроз печени, сахарный диабет'}
                ]
            },
            {
                causesList: [
                    {text: 'хронический панкреатит, избыточный вес'},
                    {text: 'генетическая предрасположенность к развитию заболевания'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'нарушение работы кишечника (жидкий стул, тошнота, рвота)'},
                    {text: 'снижение аппетита, общая слабость, нарушение сна'},
                    {text: 'боль в эпигастральной области, усиливающаяся по ночам'}
                ]
            },
            {
                symptomsList: [
                    {text: 'отвращение к жирной, мясной пище, алкоголю, кофеину, табаку'},
                    {text: 'желудочно-кишечные кровотечения, вздутие живота'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'ультразвуковое исследование'},
                    {text: 'рентгеноскопия'},
                    {text: 'фиброгастродуоденоскопия'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'лапаротомия'},
                    {text: 'компьютерная томография'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'медикаментозное – гормональная терапия, химиотерапия, прием препаратов для снятия неприятных симптомов'}
                ]
            },
            {
                treatmentList: [
                    {text: 'хирургическое – применяется только в определенных случаях и только при отсутствии метастазов'}
                ]
            }
        ]
    },{
        link: 'ulcerative-colitis',
        title: 'Язвенный колит',
        description: 'Хроническое заболевание, характеризующееся воспалением толстой кишки с развитием осложнений разной степени сложности.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'частые инфекционные заболевания'},
                    {text: 'наследственная предрасположенность'}
                ]
            },
            {
                causesList: [
                    {text: 'наличие пищевой аллергии'},
                    {text: 'нарушение микрофлоры кишечника'}
                ]
            },
            {
                causesList: [
                    {text: 'хронические стрессы, алкоголизм'},
                    {text: 'аутоиммунные заболевания'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'боли в эпигастральной области'},
                    {text: 'сонливость, депрессия'},
                    {text: 'жидкий стул, вздутие'},
                    {text: 'ложные позывы к дефекации'}
                ]
            },
            {
                symptomsList: [
                    {text: 'снижение аппетита'},
                    {text: 'стремительная потеря веса'},
                    {text: 'нарушение функции почек'},
                    {text: 'постоянная слабость'}
                ]
            },
            {
                symptomsList: [
                    {text: 'боли в мышцах и суставах'},
                    {text: 'обезвоживание'},
                    {text: 'слизь, гной и кровь в каловых массах'},
                    {text: 'повышение температуры'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'общие анализы крови и мочи'},
                    {text: 'ректороманоскопия'},
                    {text: 'бактериологическое исследование кала'},
                    {text: 'ирригоскопия'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'анализ кала на скрытую кровь'},
                    {text: 'колоноскопия'},
                    {text: 'УЗИ органов брюшной полости'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'консервативное – рациональное и сбалансированное питание, прием витаминов'},
                    {text: 'медикаментозное – противодиарейные и гормональные препараты'}
                ]
            },
            {
                treatmentList: [
                    {text: 'хирургическое – показано при тяжелом течении заболевания (пациенту удаляют часть пораженного кишечника)'}
                ]
            }
        ]
    },{
        link: 'coronary-heart-disease',
        title: 'Ишемическая болезнь сердца',
        description: 'Патологическое состояние сердца, при котором миокард получает недостаточно крови из-за повреждения коронарных артерий.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'атеросклероз сердечных артерий'},
                    {text: 'высокий индекс массы тела'},
                    {text: 'наследственная предрасположенность'}
                ]
            },
            {
                causesList: [
                    {text: 'пожилой возраст человека'},
                    {text: 'повышенное артериальное давление'},
                    {text: 'злоупотребление алкоголем'}
                ]
            },
            {
                causesList: [
                    {text: 'генетическая предрасположенность'},
                    {text: 'малоподвижный образ жизни'},
                    {text: 'сильное психоэмоциональное напряжение'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'болевые ощущения в области грудной клетки, шеи, плеча'},
                    {text: 'хроническая сердечная недостаточность'}
                ]
            },
            {
                symptomsList: [
                    {text: 'отек и одышка даже при незначительных физических нагрузках'},
                    {text: 'нестабильная стенокардия, инфаркт миокарда'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'биохимический анализ крови'},
                    {text: 'электрокардиография'},
                    {text: 'селективная коронарография'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'компьютерная томография'},
                    {text: 'сцинтиграфия миокарда'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'консервативное – физиотерапия, соблюдение специальной диеты, ограничение физической нарузки'},
                    {text: 'медикаментозное – прием антикоагулянтов, препаратов, снижающих уровень холестерина в крови'}
                ]
            },
            {
                treatmentList: [
                    {text: 'хирургическое – чрескожное коронарное вмешательство, аортокоронарное шунтирование'}
                ]
            }
        ]
    },{
        link: 'atrial-fibrillation',
        title: 'Мерцательная аритмия',
        description: 'Состояние, при котором мышцы предсердий работают асинхронно.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'генетическая предрасположенность'},
                    {text: 'избыточный вес, сахарный диабет'},
                    {text: 'повышенное давление'}
                ]
            },
            {
                causesList: [
                    {text: 'хронические заболевания сердца'},
                    {text: 'дефицит электролитов'},
                    {text: 'злоупотребление алкоголем'}
                ]
            },
            {
                causesList: [
                    {text: 'инфекционные процессы в сердце'},
                    {text: 'проблемы со щитовидной железой'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'гипергидроз (повышенная потливость)'},
                    {text: 'приступы необоснованного страха'},
                    {text: 'несовпадение пульса с сердечным ритмом'}
                ]
            },
            {
                symptomsList: [
                    {text: 'аритмичное сокращение сердечной мышцы'},
                    {text: 'слабость, потеря сознания, головокружения'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'электрокардиография'},
                    {text: 'эхокардиография'},
                    {text: 'УЗИ щитовидной железы'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'рентгенография грудной клетки'},
                    {text: 'лабораторные исследования'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'медикаментозное – прием антикоагулянтов и препаратов, снимающих неприятные симптомы'}
                ]
            }
        ]
    },{
        link: 'tela',
        title: 'Венозная тромбоэмболия легочной артерии (ТЭЛА)',
        description: 'Опасное состояние, которое приводит к плохой проходимости вен из-за образования кровяного сгустка.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'хроническая сердечная недостаточность'},
                    {text: 'малоподвижный образ жизни'},
                    {text: 'травмы, физическое перенапряжение'},
                    {text: 'оперативные вмешательства'}
                ]
            },
            {
                causesList: [
                    {text: 'частые заболевания органов дыхания'},
                    {text: 'генетическая предрасположенность'},
                    {text: 'повреждение стенок сосудов'},
                    {text: 'пожилой возраст (старше 60 лет)'}
                ]
            },
            {
                causesList: [
                    {text: 'прием некоторых лекарственных препаратов'},
                    {text: 'сдавление сосудов извне (опухоли)'},
                    {text: 'послеродовой период, гормональный дисбаланс'},
                    {text: 'нарушение свертываемости крови'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'артериальная гипотензия'},
                    {text: 'мерцательная аритмия'},
                    {text: 'синусовая тахикардия'},
                    {text: 'повышение температуры'}
                ]
            },
            {
                symptomsList: [
                    {text: 'боль за грудиной, одышка'},
                    {text: 'затрудненное дыхание'},
                    {text: 'головокружение, обмороки'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'лабораторные исследования'},
                    {text: 'электрокардиография'},
                    {text: 'ренгенография'},
                    {text: 'эхокардиография'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'компьютерная томография'},
                    {text: 'УЗИ глубоких вен'},
                    {text: 'ангиография сосудов легких'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'медикаментозное – улучшение гемодинамики за счет приема антикоагулянтов'}
                ]
            },
            {
                treatmentList: [
                    {text: 'хирургическое – удаление тромбов, иногда установка кавального фильтра'}
                ]
            }
        ]
    },{
        link: 'ischaemic-stroke',
        title: 'Ишемический инсульт',
        description: 'Патологическое состояние, вызванное ухудшением кровообращения в головном мозге, сопровождающиеся характерными неврологическими расстройствами.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'атеросклероз сонных артерий'},
                    {text: 'изменения сердечного ритма'},
                    {text: 'тромбоз вен нижних конечностей'},
                    {text: 'артериальная гипертензия'}
                ]
            },
            {
                causesList: [
                    {text: 'нарушение свертываемости крови'},
                    {text: 'пожилой и старческий возраст'},
                    {text: 'повышенный уровень холестерина'},
                    {text: 'сердечно-сосудистые заболевания'}
                ]
            },
            {
                causesList: [
                    {text: 'курение, наркотическая зависимость'},
                    {text: 'сахарный диабет, сильный стресс'},
                    {text: 'генетическая предрасположенность'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'постоянное головокружение'},
                    {text: 'ощущение вращения предметов'},
                    {text: 'расшатанность походки'}
                ]
            },
            {
                symptomsList: [
                    {text: 'нарушение координации движений'},
                    {text: 'общая слабость, потеря сознания'},
                    {text: 'паралич, потеря чувствительности'}
                ]
            },
            {
                symptomsList: [
                    {text: 'снижение остроты зрения, диплопия'},
                    {text: 'несвязная, спутанная речь'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'лабораторные исследования'},
                    {text: 'электрокардиография'},
                    {text: 'МРТ головы и шейного отдела'},
                    {text: 'КТ с контрастным веществом'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'полный неврологический осмотр'},
                    {text: 'УЗИ сердца и экстракраниальных артерий'},
                    {text: 'транскраниальная допплерография'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'медикаментозное – прием нейропротекторов, тромболитиков, препаратов для снижения артериального давления'},
                    {text: 'консервативное – соблюдение диеты, отдых и наблюдение врача в условиях стационара'}
                ]
            },
            {
                treatmentList: [
                    {text: 'хирургическое – удаление тромба с целью восстановления нормального кровотока'}
                ]
            }
        ]
    },{
        link: 'disseminated-sclerosis',
        title: 'Рассеянный склероз',
        description: 'Хроническое заболевание, которое поражает головной и спинной мозг.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'генетическая предрасположенность'},
                    {text: 'травмы головы и спины'}
                ]
            },
            {
                causesList: [
                    {text: 'возраст пациента от 18 до 40 лет'},
                    {text: 'сниженный иммунитет'}
                ]
            },
            {
                causesList: [
                    {text: 'частые стрессы, переутомление'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'ухудшение зрения, диплопия, расплывчатость'},
                    {text: 'снижение чувствительности в конечностях'},
                    {text: 'покалывание или онемение лица, частей тела'}
                ]
            },
            {
                symptomsList: [
                    {text: 'нарушение функции мочеполовой системы, запоры'},
                    {text: 'депрессии, эйфории, изменения в поведении'},
                    {text: 'снижение интеллектуальных способностей'}
                ]
            },
            {
                symptomsList: [
                    {text: 'резкое ухудшение состояния при перегреве'},
                    {text: 'потеря ловкости, нарушение координации'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'электромиография'},
                    {text: 'магнитно- резонансная томография'},
                    {text: 'биохимический анализ крови'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'иммунологический анализ крови'},
                    {text: 'люмбальная пункция'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'медикаментозное – прием препаратов для устранения симптомов и замедления процесса развития заболевания'}
                ]
            }
        ]
    },{
        link: 'parkinson',
        title: 'Болезнь Паркинсона',
        description: 'Заболевание, связанное с постепенной гибелью нервных клеток, вырабатывающих медиатор дофамин.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'генетическая предрасположенность'},
                    {text: 'черепно-мозговые травмы'}
                ]
            },
            {
                causesList: [
                    {text: 'отравление организма разными токсинами'},
                    {text: 'хронические головные боли и мигрени'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'обрывистая и гнусавая речь, изменения почерка'},
                    {text: 'слабость, упадок сил, недомогание'},
                    {text: 'снижение выраженности проявления эмоции на лице'},
                    {text: 'шаткость походки, неуверенные шаги'}
                ]
            },
            {
                symptomsList: [
                    {text: 'резкие перепады настроения, периоды депрессии'},
                    {text: 'повышенный тонус мышц, тремор'},
                    {text: 'изменения осанки, нарушение координации движений'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'осмотр у невропатолога'},
                    {text: 'компьютерная томография'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'электроэнцефалография'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'медикаментозное – пациенту назначают препараты для уменьшения выраженности симптомов паркинсонизма'}
                ]
            },
            {
                treatmentList: [
                    {text: 'хирургическое – глубокая стимуляция головного мозга, в особо сложных ситуациях – иссечение поврежденных участков'}
                ]
            }
        ]
    },{
        link: 'alzheimer',
        title: 'Болезнь Альцгеймера',
        description: 'Заболевание, которое сопровождается разрушение и гибель нервных клеток.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'возраст старше 65 лет'},
                    {text: 'генетическая предрасположенность'},
                    {text: 'асоциальный образ жизни'}
                ]
            },
            {
                causesList: [
                    {text: 'частые инфекционные заболевания'},
                    {text: 'артериальная гипертензия'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'нарушение двигательных рефлексов'},
                    {text: 'потеря кратковременной памяти'},
                    {text: 'головокружение, головная боль'}
                ]
            },
            {
                symptomsList: [
                    {text: 'ухудшение памяти, проблемы с речью'},
                    {text: 'неспособность ориентироваться в обстановке'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'магнитно-резонансная диагностика'},
                    {text: 'компьютерная томография'},
                    {text: 'позитронно-эмиссионная томография'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'психические тесты'},
                    {text: 'электроэнцефалографию'},
                    {text: 'анализ на тиреотропный гормон гипофиза'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'медикаментозное – прием препаратов, позволяющих замедлить процесс развития болезни и повысить качество жизни пациента'}
                ]
            }
        ]
    },{
        link: 'obesity',
        title: 'Ожирение',
        description: 'Чрезмерное накопление жира в организме, приводящее к появлению избыточной массы тела.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'злоупотребление легкоусвояемыми углеводами'},
                    {text: 'малоподвижный образ жизни'},
                    {text: 'прием психотропных гормональных препаратов'}
                ]
            },
            {
                causesList: [
                    {text: 'генетическая предрасположенность'},
                    {text: 'расстройства со стороны эндокринной системы'},
                    {text: 'частые стрессы, недосыпание'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'увеличение массы тела'},
                    {text: 'снижение работоспособности'},
                    {text: 'затрудненное дыхание, одышка'},
                    {text: 'повышение артериального давления'}
                ]
            },
            {
                symptomsList: [
                    {text: 'желудочно-кишечные расстройства'},
                    {text: 'боль в суставах и сердце'},
                    {text: 'сниженная самооценка'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'подсчет индекса массы тела'},
                    {text: 'компьютерная томография'},
                    {text: 'анализ крови на гормоны и сахар'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'магнитно-резонансная томография'},
                    {text: 'определение холестерина, липидов'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'консервативное - диетотерапия и повышение физической активности'}
                ]
            },
            {
                treatmentList: [
                    {text: 'медикаментозное – прием препаратов, ускоряющих метаболизм и подавляющих чувство голода'}
                ]
            }
        ]
    },{
        link: 'type-2-diabetes',
        title: 'Сахарный диабет 2-го типа',
        description: 'Метаболическое заболевание, сопровождающееся повышенным уровнем сахара в крови из-за нарушения взаимодействия инсулина с клетками тканей.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'наследственная предрасположенность'},
                    {text: 'наличие избыточного веса, ожирение'}
                ]
            },
            {
                causesList: [
                    {text: 'снижение функции поджелудочной железы'},
                    {text: 'несбалансированное питание, гиподинамия'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'постоянная сухость в ротовой полости'},
                    {text: 'частое мочеиспускание'},
                    {text: 'быстрое повышение массы тела'},
                    {text: 'общая слабость, усталость'}
                ]
            },
            {
                symptomsList: [
                    {text: 'раздражительность, смена настроения'},
                    {text: 'тошнота, рвота, недомогание'},
                    {text: 'повышенная потливость (гипергидроз)'},
                    {text: 'плохое заживление ран'}
                ]
            },
            {
                symptomsList: [
                    {text: 'отек лица, верхних и нижних конечностей'},
                    {text: 'ухудшение остроты зрения'},
                    {text: 'расстройство двигательных функций'},
                    {text: 'сухость и зуд кожных покровов'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'общий и биохимический анализ крови'},
                    {text: 'УЗИ сердца и почек'},
                    {text: 'тест на толерантность к глюкозе'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'электрокардиография'},
                    {text: 'ультразвуковая допплерография сосудов'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'консервативное – диетотерапия, повышение физической активности'}
                ]
            },
            {
                treatmentList: [
                    {text: 'медикаментозное – прием препаратов для регулировки уровня сахара в крови'}
                ]
            }
        ]
    },{
        link: 'type-1-diabetes',
        title: 'Сахарный диабет 1 типа',
        description: 'Эндокринное заболевание, вызванное абсолютным дефицитом гормона поджелудочной железы (инсулина).',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'наследственность'},
                    {text: 'тяжелый стресс'},
                    {text: 'инфекционные болезни'}
                ]
            },
            {
                causesList: [
                    {text: 'подростковый возраст'},
                    {text: 'сниженный иммунитет'},
                    {text: 'дефицит витамина D'}
                ]
            },
            {
                causesList: [
                    {text: 'врожденные патологии'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'учащенное мочеиспускание'},
                    {text: 'потеря веса при хорошем аппетите'},
                    {text: 'запах ацетона изо рта'}
                ]
            },
            {
                symptomsList: [
                    {text: 'снижение работоспособности'},
                    {text: 'чувство жажды и сухости во рту'},
                    {text: 'нарушение сна, бессонница'}
                ]
            },
            {
                symptomsList: [
                    {text: 'нарушение зрительной функции'},
                    {text: 'боль в животе, тошнота, рвота'},
                    {text: 'спутанность сознания'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'анализ крови на определение уровня сахара'},
                    {text: 'тест на чувствительность к глюкозе'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'анализ мочи на наличие кетонов'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'консервативное – строгая лечебная диета и коррекция образа жизни'}
                ]
            },
            {
                treatmentList: [
                    {text: 'медикаментозное – снижение уровня сахара при введении инсулина'}
                ]
            }
        ]
    },{
        link: 'asthma',
        title: 'Бронхиальная астма',
        description: 'Заболевание дыхательных путей, сопровождающееся хроническим воспалением.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'генетическая предрасположенность'},
                    {text: 'неблагоприятные факторы внешней среды'},
                    {text: 'чрезмерные физические нагрузки'},
                    {text: 'склонность к аллергическим реакциям'}
                ]
            },
            {
                causesList: [
                    {text: 'наличие вредных привычек (курение)'},
                    {text: 'прием некоторых лекарственных препаратов'},
                    {text: 'систематический контакт с химикатами'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'кашель, возникающий преимущественно в ночное и утреннее время'},
                    {text: 'приступы сухого непродуктивного кашля, одышка при контакте с аллергенами'},
                    {text: 'возникновение сухих свистящих хрипов во время приступа удушья'}
                ]
            },
            {
                symptomsList: [
                    {text: 'поверхностное, учащенное дыхание, невозможность сделать полный вдох'},
                    {text: 'синеватость кожных покровов из-за кислородной недостаточности'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'клинический анализ крови'},
                    {text: 'анализ крови на иммуноглобулин E'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'спирография (анализ функции внешнего дыхания)'},
                    {text: 'рентгенография органов грудной клетки'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'консервативное – дыхательная гимнастика для облегчения приступов'}
                ]
            },
            {
                treatmentList: [
                    {text: 'медикаментозное – прием бронхолитиков, кортикостероидов, иммуномодуляторов'}
                ]
            }
        ]
    },{
        link: 'rheumatoid-arthritis',
        title: 'Ревматоидный артрит',
        description: 'Системное заболевание опорно-двигательного аппарата, которое поражает соединительную ткань.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'чрезмерная физическая нагрузка и частые травмы'},
                    {text: 'генетическая предрасположенность'},
                    {text: 'частые переохлаждения и инфекционные процессы'}
                ]
            },
            {
                causesList: [
                    {text: 'серьезный психоэмоциональный стресс'},
                    {text: 'систематический контакт с токсическими веществами'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'ограничение подвижности по утрам'},
                    {text: 'сильная слабость, чувство усталости'},
                    {text: 'болезненные ощущения в мышцах'}
                ]
            },
            {
                symptomsList: [
                    {text: 'снижение веса при нормальном аппетите'},
                    {text: 'повышенная потливость (гипергидроз)'},
                    {text: 'покраснение кожи в месте воспаленного сустава'}
                ]
            },
            {
                symptomsList: [
                    {text: 'нарушение чувствительности в пальцах'},
                    {text: 'повышение температуры без видимых причин'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'общий и биохимический анализ крови'},
                    {text: 'тест на наличие антцитруллиновых антител'},
                    {text: 'артроскопия и рентгенограмма'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'исследование синовиальной жидкости'},
                    {text: 'УЗИ суставов и внутренних органов'},
                    {text: 'магнитно-резонансная томография'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'консервативное – лечебная физкультура, массаж, физиопроцедуры, диетотерапия'},
                    {text: 'медикаментозное – снятие неприятных симптомов и предотвращение осложнений'}
                ]
            },
            {
                treatmentList: [
                    {text: 'хирургическое – установка суставных протезов'}
                ]
            }
        ]
    },{
        link: 'disseminated-lupus-erythematosus',
        title: 'Системная красная волчанка',
        description: 'Аутоиммунное заболевание, при котором антитела, вырабатываемые иммунной системой человека, повреждают здоровые клетки соединительной ткани.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'наследственная предрасположенность'},
                    {text: 'нарушения гормонального баланса'},
                    {text: 'беременность, период после аборта'},
                    {text: 'частые простудные заболевания'}
                ]
            },
            {
                causesList: [
                    {text: 'сильные стрессы, депрессии'},
                    {text: 'ослабленная иммунная система'},
                    {text: 'прием лекарственных препаратов'},
                    {text: 'неблагоприятные факторы внешней среды'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'общая слабость, быстрая утомляемость'},
                    {text: 'отек нижних конечностей, глаз'},
                    {text: 'боль в груди при глубоком вдохе'},
                    {text: 'внезапное повышение температуры тела'}
                ]
            },
            {
                symptomsList: [
                    {text: 'увеличение лимфатических узлов'},
                    {text: 'выпадение волос, бледность кожи'},
                    {text: 'болезненные ощущения в мышцах и суставах'},
                    {text: 'чувствительность к ультрафиолету'}
                ]
            },
            {
                symptomsList: [
                    {text: 'язвы на слизистых оболочках'},
                    {text: 'феномен Рейно (изменение цвета кожи пальцев)'},
                    {text: 'сыпь на лице в области скул'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'сбор анамнеза, общий осмотр пациента'},
                    {text: 'электрокардиография'},
                    {text: 'реакция Вассермана'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'рентгенография грудной клетки и суставов'},
                    {text: 'УЗИ органов брюшной полости'},
                    {text: 'неврологические исследование'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'общий и биохимический анализ крови'},
                    {text: 'биопсия кожи или почек'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'медикаментозное – глюкокортикостероиды, цитостатики, противовоспалительные средства, препараты аминохинолинового ряда'}
                ]
            },
            {
                treatmentList: [
                    {text: 'консервативное – плазмофорез, пульс-терапия, прием витаминов'}
                ]
            }
        ]
    },{
        link: 'systemic-sclerosis',
        title: 'Системный склероз',
        description: 'Заболевание соединительной ткани с преобладанием фиброза, которое характеризуется поражением мелких сосудов.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'врожденные патологии иммунной системы'},
                    {text: 'переохлаждение, травмы, частые инфекции'},
                    {text: 'наследственная предрасположенность'}
                ]
            },
            {
                causesList: [
                    {text: 'действие некоторых лекарственных препаратов'},
                    {text: 'эндокринные нарушения и гормональный дисбаланс'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'отек кожных покровов на кистях рук'},
                    {text: 'нарушение сердечного ритма'},
                    {text: 'атрофия кожи, некрозы и остеолиз'},
                    {text: 'изменение черт лица'},
                    {text: 'боли в мышцах и суставах, деформация конечностей'}
                ]
            },
            {
                symptomsList: [
                    {text: 'повышение температуры тела'},
                    {text: 'высокая утомляемость'},
                    {text: 'поражение щитовидной железы'},
                    {text: 'снижение массы тела'},
                    {text: 'снижение чувствительности конечностей'}
                ]
            },
            {
                symptomsList: [
                    {text: 'почечная недостаточность'},
                    {text: 'нарушения функции системы пищеварения'},
                    {text: 'синдром Рейно (изменение цвета кожи пальцев)'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'сбор анамнеза, общий осмотр пациента'},
                    {text: 'рентгенография'},
                    {text: 'общий и биохимический анализ крови'},
                    {text: 'биопсия пораженных участков'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'капилляроскопия'},
                    {text: 'иммунологические исследования'},
                    {text: 'компьютерная томография'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'медикаментозное – нестероидные противовоспалительные и сосудистые препараты, антикоагулянты'}
                ]
            },
            {
                treatmentList: [
                    {text: 'консервативное – пациент должен избегать переохлаждения и контакта с химическими веществами'}
                ]
            }
        ]
    },{
        link: 'psoriatic-arthritis',
        title: 'Псориатический артрит',
        description: 'Воспалительное заболевание суставов у пациентов, больных псориазом.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'перенесенные стрессы'},
                    {text: 'частые инфекционные заболевания'}
                ]
            },
            {
                causesList: [
                    {text: 'наличие псориаза'},
                    {text: 'генетическая предрасположенность'}
                ]
            },
            {
                causesList: [
                    {text: 'возраст от 30 до 50 лет'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'боль в суставах, изменение цвета кожи вокруг них'},
                    {text: 'отек пальцев, боли в позвоночнике'}
                ]
            },
            {
                symptomsList: [
                    {text: 'воспаление сухожилий и межпозвоночных суставов'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'определение концентрации С-реактивного белка в сыворотке крови'},
                    {text: 'консультация у терапевта, сбор анамнеза и осмотр пациента'},
                    {text: 'рентгеновское исследование'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'анализ синовиальной жидкости на предмет наличия мочевой кислоты'},
                    {text: 'определение скорости оседания эритроцитов и ревматоидного фактора'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'медикаментозное – нестероидные противовоспалительные препараты, глюкокортикоиды, иммуносупрессоры'}
                ]
            }
        ]
    },{
        link: 'chronic-kidney-disease',
        title: 'Хроническая болезнь почек',
        description: 'Повреждение почек или снижение их функции на протяжении длительного периода.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'повышенное артериальное давление'},
                    {text: 'сахарный диабет'},
                    {text: 'генетическая предрасположенность'}
                ]
            },
            {
                causesList: [
                    {text: 'гиперлипидемия'},
                    {text: 'наличие аутоиммунных заболеваний'},
                    {text: 'избыточный вес'}
                ]
            },
            {
                causesList: [
                    {text: 'вредные привычки, особенно курение'},
                    {text: 'инфекции мочевых путей'},
                    {text: 'острая почечная недостаточность'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'анорексия, диарея, рвота'},
                    {text: 'тахикардия, аритмия'},
                    {text: 'отек лица и конечностей'}
                ]
            },
            {
                symptomsList: [
                    {text: 'снижение аппетита'},
                    {text: 'боль в костях и суставах'},
                    {text: 'запах мочи изо рта'}
                ]
            },
            {
                symptomsList: [
                    {text: 'неврологические нарушения'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'общий анализ мочи'},
                    {text: 'исследование уровня мочевины в крови'},
                    {text: 'ультразвуковое исследование'},
                    {text: 'рентгенография почек'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'допплерография сосудов почек'},
                    {text: 'компьютерная томография'},
                    {text: 'биопсия почки'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'консервативное – диетотерапия, прием витаминов, повышение физической активности'},
                    {text: 'медикаментозное – блокаторы ангиотензин-превращающего фермента, блокаторы рецепторов ангиотензина, антагонистов альдостерона и другие'}
                ]
            },
            {
                treatmentList: [
                    {text: 'хирургическое – в особо сложных ситуациях может понадобиться трансплантация почки'}
                ]
            }
        ]
    },{
        link: 'colorectal-cancer',
        title: 'Колоректальный рак',
        description: 'Злокачественное образование в толстой кишке и червеобразном отростке.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'наследственная предрасположенность'},
                    {text: 'несбалансированное питание'}
                ]
            },
            {
                causesList: [
                    {text: 'воспалительные процессы'},
                    {text: 'злоупотребление алкоголем'}
                ]
            },
            {
                causesList: [
                    {text: 'повышенный индекс массы тела'},
                    {text: 'язвенный колит и болезнь Крона'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'железодефицитная анемия'},
                    {text: 'непроходимость кишечника'},
                    {text: 'отрыжка, тошнота, рвота'}
                ]
            },
            {
                symptomsList: [
                    {text: 'запоры, диарея, метеоризм'},
                    {text: 'слабость, недомогание'},
                    {text: 'ухудшение аппетита'}
                ]
            },
            {
                symptomsList: [
                    {text: 'неприятный привкус во рту'},
                    {text: 'чувство тяжести в животе'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'исследование кала на скрытую кровь'},
                    {text: 'осмотр пациента, сбор анамнеза'},
                    {text: 'ректороманоскопия'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'ирригоскопия'},
                    {text: 'колоноскопия'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'хирургическое – удаление пораженного кишечника, печени и лимфатических узлов, а также химиотерапия.'}
                ]
            }
        ]
    },{
        link: 'lung-cancer',
        title: 'Рак легких',
        description: 'Злокачественные новообразования в легочной ткани.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'генетическая предрасположенность'},
                    {text: 'наличие вредных привычек (табакокурение)'},
                    {text: 'радиационное облучение'},
                    {text: 'хронические заболевания органов дыхания'}
                ]
            },
            {
                causesList: [
                    {text: 'работа на вредном производстве'},
                    {text: 'неблагоприятные факторы внешней среды'},
                    {text: 'несбалансированное питание'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'продолжительный беспричинный кашель'},
                    {text: 'боль в грудной клетке, кровохарканье, одышка'}
                ]
            },
            {
                symptomsList: [
                    {text: 'снижение аппетита, потеря массы тела'},
                    {text: 'усталость, раздражительность'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'рентгенография органов грудной клетки'},
                    {text: 'компьютерная томография'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'бронхография'},
                    {text: 'эндоскопическое бронхологическое обследование'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'хирургическое – удаление злокачественного образования с проведением лучевой, химио- и иммунотерапии'}
                ]
            }
        ]
    },{
        link: 'melanoma',
        title: 'Меланома',
        description: 'Злокачественное новообразование, которое поражает эпидермис.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'генетическая предрасположенность'},
                    {text: 'длительное воздействие ультрафиолета'},
                    {text: 'светлый эпидермис и рыжие волосы'}
                ]
            },
            {
                causesList: [
                    {text: 'наличие многочисленных пигментных пятен'},
                    {text: 'частые дерматологические заболевания'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'специфическая форма новообразования'},
                    {text: 'изменение цвета и размера меланомы'},
                    {text: 'кровотечение, зуд и жжение кожи'}
                ]
            },
            {
                symptomsList: [
                    {text: 'увеличение лимфоузлов, отеки'},
                    {text: 'головная боль, слабость, судороги'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'гистологическое исследование'},
                    {text: 'компьютерная томография'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'ультразвуковое исследование'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'хирургическое – удаление пораженного участка с проведением иммуно- или адъювантной лучевой терапии'}
                ]
            }
        ]
    },{
        link: 'schizophrenia',
        title: 'Шизофрения',
        description: 'Тяжелое психическое заболевание, характеризующееся специфическими изменениями личности.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'генетическая предрасположенность'},
                    {text: 'злоупотребление алкоголем'}
                ]
            },
            {
                causesList: [
                    {text: 'наркотическая зависимость'},
                    {text: 'прием некоторых лекарств'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'эмоционально-волевые нарушения'},
                    {text: 'дисфункции восприятия и мышления'}
                ]
            },
            {
                symptomsList: [
                    {text: 'появление бреда, мания, апатия'},
                    {text: 'избегание социальных контактов'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'диагностировать шизофрению может только врач-психиатр на основании наблюдений за больным человеком'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'консервативное – сеансы психотерапии, посещение групповых занятий'}
                ]
            },
            {
                treatmentList: [
                    {text: 'медикаментозное – пациенту назначают антипсихотические препараты'}
                ]
            }
        ]
    },{
        link: 'alopecia-areata',
        title: 'Гнездная алопеция',
        description: 'Патологическое выпадение волос, приводящее к их полному исчезновению.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'нарушение обмена веществ'},
                    {text: 'неправильный уход за волосами'},
                    {text: 'гормональный дисбаланс'}
                ]
            },
            {
                causesList: [
                    {text: 'инфекционные процессы в организме'},
                    {text: 'аутоиммунные заболевания'},
                    {text: 'наследственная предрасположенность'}
                ]
            },
            {
                causesList: [
                    {text: 'частые стрессы, депрессия'},
                    {text: 'воздействие токсических веществ'},
                    {text: 'травмы головы, операции'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'выпадение волос в области головы, конечностей, туловища и ног'},
                    {text: 'отечность и покраснение пораженной зоны'}
                ]
            },
            {
                symptomsList: [
                    {text: 'ощущение покалывания и жжения, зуд на коже'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'осмотр у дерматолога'},
                    {text: 'диагностическая микроскопия волос'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'анализ крови на гормоны'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'консервативное – прием мультивитаминных комплексов'},
                    {text: 'медикаментозное – прием гормональных модуляторов'}
                ]
            },
            {
                treatmentList: [
                    {text: 'хирургическое – пересадка донорских волос'}
                ]
            }
        ]
    },{
        link: 'сhronic-inflammatory-bowel-disease',
        title: 'Хронические воспалительные заболевания кишечника',
        description: 'Группа болезней, которые поражают органы желудочно-кишечного тракта (язвенный колит, болезнь Крона).',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'наследственная предрасположенность'},
                    {text: 'воспалительные процессы в организме'},
                    {text: 'склонность к пищевым аллергиям'}
                ]
            },
            {
                causesList: [
                    {text: 'изменение микрофлоры кишечника'},
                    {text: 'несбалансированное питание'},
                    {text: 'малоподвижный образ жизни'}
                ]
            },
            {
                causesList: [
                    {text: 'негативные факторы внешней среды'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'диарея с примесью крови и слизи'},
                    {text: 'ухудшение общего самочувствия'},
                    {text: 'быстрая утомляемость, усталость'}
                ]
            },
            {
                symptomsList: [
                    {text: 'повышение температуры, озноб'},
                    {text: 'резкое снижение массы тела'},
                    {text: 'воспаление в заднем проходе'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'исследование кала на скрытую кровь '},
                    {text: 'ирригоскопия'},
                    {text: 'бактериологическое исследование кала'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'ректороманоскопия'},
                    {text: 'компьютерная томография'},
                    {text: 'колоноскопия'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'УЗИ органов брюшной полости'},
                    {text: 'биопсия пораженных тканей'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'консервативное – соблюдение диеты, коррекция образа жизни, физиотерапия'}
                ]
            },
            {
                treatmentList: [
                    {text: 'медикаментозное – прием антибиотиков и противовоспалительных препаратов'}
                ]
            }
        ]
    },{
        link: 'allergic-diseases',
        title: 'Аллергические заболевания',
        description: 'Повышенная чувствительность к веществам, вызывающим ответную реакцию организма на раздражитель.',
        recommendation: 'xxx',
        doctors: [
            {text: 'x'},
            {text: 'x'},
            {text: 'x'}
        ],
        causes: [
            {
                causesList: [
                    {text: 'наследственная предрасположенность'},
                    {text: 'частый контакт с антигеном-аллергеном'},
                    {text: 'инфекции на фоне сниженного иммунитета'}
                ]
            },
            {
                causesList: [
                    {text: 'нарушение гормонального баланса'},
                    {text: 'прием некоторых лекарственных препаратов'}
                ]
            }
        ],
        symptoms: [
            {
                symptomsList: [
                    {text: 'высыпания на коже, зуд и жжение'},
                    {text: 'аллергический ринит'},
                    {text: 'слезотечение из глаз'},
                    {text: 'внезапные приступы кашля'}
                ]
            },
            {
                symptomsList: [
                    {text: 'головокружение, головная боль'},
                    {text: 'тошнота, рвота, диарея'},
                    {text: 'потеря сознания'},
                    {text: 'изменение сердечного ритма'}
                ]
            },
            {
                symptomsList: [
                    {text: 'затруднение дыхания'},
                    {text: 'покраснение склер глаз'},
                    {text: 'анафилактический шок'}
                ]
            }
        ],
        diagnosis: [
            {
                diagnosisList: [
                    {text: 'исследования крови на содержание специфических антител IgE'}
                ]
            },
            {
                diagnosisList: [
                    {text: 'анализ на определение аллергена'}
                ]
            }
        ],
        treatment: [
            {
                treatmentList: [
                    {text: 'медикаментозное – прием антигистаминных препаратов для снятия симптомов'}
                ]
            }
        ]
    }
];