import React from 'react'
import {Tab,  Nav, } from 'react-bootstrap'

import './vitamins.sass'
import { withTranslation } from 'react-i18next'
import { data } from './data'

const Vitamins = ({t, customerFoodLk}) => {
        return(
                <Tab.Container defaultActiveKey="a">
                    <div className="vitamins-container">
                        <div className="vitaminsTab">
                            <Nav variant="pills" className="flex-column">
                                {
                                    data.map((item, index) => {
                                        const getData = customerFoodLk.vitamin[index]
                                        if(item.getName===getData.name){
                                            return (
                                                <Nav.Item className={item.color}  key={index}>
                                                    <Nav.Link eventKey={item.name}>{item.name}</Nav.Link>
                                                    <div className="vitaminsTab-bar">
                                                        <div className="vitaminsTab-bar-line" style={{'height': getData.percent+'%'}}/>
                                                    </div>
                                                </Nav.Item>
                                            )
                                        }


                                    })
                                }
                            </Nav>
                        </div>
                        <div className="vitaminsTab-content">
                            <Tab.Content>
                                {
                                    data.map((item, index) => {
                                        const getData = customerFoodLk.vitamin[index]
                                        if(item.getName===getData.name) {
                                            return (
                                                <Tab.Pane eventKey={item.name} key={index} className={item.color}>
                                                    <div className="vitaminsTab-content-title">
                                                        <div className="vitaminsTab-content-title-name">
                                                            <h3 className="h3">{t(`${item.fullName}`)}</h3>
                                                            <div className="vitaminsTab-bar">
                                                                <div className="vitaminsTab-bar-line"
                                                                     style={{'width': getData.percent + '%'}}/>
                                                            </div>
                                                        </div>
                                                        <div className="vitaminsTab-content-title-info">
                                                            <p className="vitaminsTab-content-title-info-title">{t(`Уровень`)}:</p>
                                                            <p className="vitaminsTab-content-title-info-desc">{t(`${getData.chance}`)}</p>
                                                        </div>
                                                    </div>
                                                    <div className="vitaminsTab-content-description border-padding">
                                                        <img className="vitaminsTab-content-description-img"
                                                             src={item.img} alt="vitamins"/>
                                                        <div>
                                                            <h3 className="h3">{t(`Описание`)}</h3>
                                                            {item.description.map((list, indexGroup) => {
                                                                return (
                                                                    <p key={indexGroup}
                                                                       className="vitaminsTab-content-description-text">{t(`${list.text}`)}</p>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div
                                                            className="vitaminsTab-content-list vitaminsTab-content-list-2 border-padding">
                                                            <div className="vitaminsTab-content-list-img">
                                                                <span className="icon-up"></span>
                                                            </div>
                                                            <ul className="vitaminsTab-content-list-box">
                                                                <h3 className="h3">{t(`Признаки дефицита витамина A`)}</h3>
                                                                {item.deficit.map((list, indexGroup) => {
                                                                    return (
                                                                        <li key={indexGroup}
                                                                            className="vitaminsTab-content-description-text">{t(`${list.text}`)}</li>
                                                                    )
                                                                })}

                                                            </ul>
                                                        </div>
                                                        <div className="vitaminsTab-content-list border-padding">
                                                            <div className="vitaminsTab-content-list-img">
                                                                <span className="icon-up"/>
                                                            </div>
                                                            <ul className="vitaminsTab-content-list-box">
                                                                <h3 className="h3">{t(`Симптомы избытка витамина A`)}</h3>
                                                                {item.excess.map((list, indexGroup) => {
                                                                    return (
                                                                        <li key={indexGroup}
                                                                            className="vitaminsTab-content-description-text">{t(`${list.text}`)}</li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="vitaminsTab-content-result">
                                                        <h3 className="h3">{t(`Ваши результаты`)}</h3>
                                                        <ul>
                                                            <li className="vitaminsTab-content-description-text">{t(`Ген`)}</li>
                                                            <li className="vitaminsTab-content-description-text">{t(`Полиморфизм`)}</li>
                                                            <li className="vitaminsTab-content-description-text"> {t(`Ваш тип`)}</li>
                                                        </ul>

                                                        {getData.results.map((resultsList, indexResults) => {
                                                            return (
                                                                <ul key={indexResults}>
                                                                    <li className="vitaminsTab-content-description-text">{t(`${resultsList.gene}`)}</li>
                                                                    <li className="vitaminsTab-content-description-text">{t(`${resultsList.polymorphism}`)}</li>
                                                                    <li className="vitaminsTab-content-description-text">{t(`${resultsList.type}`)}</li>
                                                                </ul>
                                                            )
                                                        })}

                                                    </div>
                                                    <div className="vitaminsTab-content-recommendation">
                                                        <h3 className="h3">
                                                            <span className="icon-like"/>
                                                            <span>{t(`Расшифровка и рекомендации генетика`)}</span>
                                                        </h3>
                                                        <p className="vitaminsTab-content-description-text">{t(`${getData.recommendations}`)}</p>
                                                    </div>
                                                </Tab.Pane>
                                            )
                                        }
                                    })

                                }
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
        )
}
export default withTranslation()(Vitamins)