import React, { useState } from 'react';
import './psychogenetics-page.sass';
// import { data } from './data';
import Pagination from "../../../Pagination.jsx";
import { withTranslation } from 'react-i18next';

const PsychogeneticsPage = ({i18n, t, customerPsychogeneticLk}) => {
	// console.log(customerPsychogeneticLk)
	const [currentPage, setCurrentPage] = useState(1);
	// const [dataLng, setDataLng] = useState(data);
	
	const changePage = (page) => {
	  setCurrentPage(page);
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        }
	};
	const itemPerPage = 10;
	const numFromSplice = currentPage < 2 ? 0 : ((currentPage - 1) * itemPerPage);
	const numToSplice = currentPage < 2 ? itemPerPage : (currentPage * itemPerPage);
	// const filteredListNews = dataLng.slice(numFromSplice,numToSplice);
	const filterData = customerPsychogeneticLk.psychogeneticItems.slice(numFromSplice,numToSplice);

	return(
		<div className="psychogenetic-risks">
				<h3 className="h3">{t(`Психогенетика`)}</h3>
				{filterData.map((item, index) => {
					return (
						<div className="multi-risk" key={index}>
							<ul>
								<li className="title">
									<span>{t(`Ген`)}:</span>
									<p className="gen">{item.gen}</p>
								</li>
								<li className="title">
									<span>{t(`Продукт гена`)}:</span>
									<p>{item.product_gen}</p>
								</li>
								<li className="title">
									<span>{t(`Полиморфизм`)}:</span>
									<p>{item.polymorphism}</p>
								</li>
								<li className="title">
									<span>{t(`Результат`)}:</span>
									<p>{item.result}</p>
								</li>
								<li className="title last">
									<span>{t(`Признаки, с которыми ассоциировано носительство генотипа`)}:</span>
									<p>{item.signs}</p>
								</li>
							</ul>
						</div>
					)
				})}		
		
				<Pagination
					onChange={(event) => {
						changePage(event);
				}}
					itemsPerPage={itemPerPage}
					currentPage={currentPage}
					totalItems={customerPsychogeneticLk.psychogeneticItems.length}
				/>
		</div>
	);
};
export default withTranslation()(PsychogeneticsPage)