const TOKEN_KEY = 'customer_token';

export const logout = () => {
    localStorage.removeItem('customer_token');

}

export const isLogin = () => {
    if (localStorage.getItem(TOKEN_KEY)) {
        return true;
    }
    return false;
}