import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { Doughnut } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';
import './chart-section.sass';

const ChartSection = ({ t}) => {
    const data = {
        labels: ["Восточные европейцы", "Южные европейцы", "Евреи ашкенази", "Северные европейцы"],
        tooltipText: ["Восточные европейцы", "Южные европейцы", "Евреи ашкенази", "Северные европейцы"],

        datasets: [{
            data: [90, 3, 3, 4],
            backgroundColor: [
                '#5897CF',
                '#FF8B8B',
                '#636E8F',
                '#29B5E3'
            ],
            hoverBackgroundColor: [
                '#5897CF',
                '#FF8B8B',
                '#636E8F',
                '#29B5E3'
            ],
            hoverBorderColor: [
                '#5897CF',
                '#FF8B8B',
                '#636E8F',
                '#29B5E3'
            ],
            borderWidth: 3,
            hoverBorderWidth: 0,
            
        }]
    };
        return(
            <div className="ethnic-section">
                <div className="h4">{t(`Этнический состав`)}</div>
                <div className="d-flex align-items-center content">
                    <ul className="tab-block">
                        <li className="title d-flex justify-content-between">
                            Европейцы:<span>100%</span>
                        </li>
                        <li className="grey d-flex justify-content-between">
                            Восточные европейцы: <span>90%</span>
                        </li>
                        <li className="grey d-flex justify-content-between">
                            Южные европейцы: <span>3%</span>
                        </li>
                        <li className="grey d-flex justify-content-between">
                            Евреи ашкенази: <span>3%</span>
                        </li>
                        <li className="grey d-flex justify-content-between">
                            Северные европейцы: <span>4%</span>
                        </li>
                    </ul>
                    <div className="chart-block d-flex align-items-center">
                        <div className="chart-item">
                            <div className="number">
                                <p className="grey">Восточные европейцы:</p>
                                <p className="percent">90%</p>
                            </div>
                            <Doughnut
                                data={data}
                                width={279}
                                height={279}
                                options={{
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    cutoutPercentage: 75,
                                    legend: { display: false },
                                    tooltips: { enabled: true },
                                    rotation: 0.3
                                    
                                }}
                                
                            />
                        </div>
                        <ul>
                            <li><span>3%</span>Южные европейцы</li>
                            <li><span>3%</span>Южные европейцы</li>
                            <li><span>4%</span>Южные европейцы</li>
                        </ul>
                    </div>
                </div>
            </div>
        )
}
export default withTranslation()(ChartSection)