import React, {useEffect, useState} from 'react';
import {Route, Redirect, withRouter} from 'react-router-dom';
import { isLogin } from './utils-customer';
import {withTranslation} from "react-i18next";

const PrivateRouteCustomer = ({i18n: {language}, component: Component, ...rest}) => {
    const [lang, setLang]= useState(`/${language}`)
    useEffect(()=>{
        if (language === 'ro'){
            setLang('')
        }else {
            setLang(`/${language}`)
        }
    },[language])
    return (
        <Route {...rest} render={props => (
            isLogin() ?
                <Component {...props} />
                : <Redirect to={`${lang}`} />
        )} />
    );
};

export default withTranslation()(withRouter(PrivateRouteCustomer))