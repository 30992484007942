import React, {Component} from 'react';

import AncestralRisk from '../ancestral-risk/ancestral-risk'
import withGenobiosisService from "../../../../hoc/with-genobiosis-service";
import compose from "../../../../../utils/compose";
import {fetchCustomerLKAncestral} from "../../../../../actions";
import {connect} from "react-redux";
import Spinner from '../../../../icons/Spinner';
import ErrorIndicator from '../../../../error-indicator';

class AcestralRiskWrapped extends Component{
    componentDidMount(){
        this.props.fetchCustomerLKAncestral()
    }

    render(){
        const { customerAncestralLk, loadingAncestralLk, errorAncestralLk } = this.props;
        if (loadingAncestralLk) {
            return <Spinner />;
        }

        if (errorAncestralLk) {
            return <ErrorIndicator />;
        }

        return <AncestralRisk customerAncestralLk={customerAncestralLk}/>
    }
}
const mapStateToProps = ({ customerAncestralLk, loadingAncestralLk, errorAncestralLk }) => {
    return {  customerAncestralLk, loadingAncestralLk, errorAncestralLk };
};

const mapDispatchToProps = (dispatch, { genobiosisService }) => {
    return {
        fetchCustomerLKAncestral: fetchCustomerLKAncestral(genobiosisService, dispatch)
    };
};

export default compose(withGenobiosisService(), connect(mapStateToProps, mapDispatchToProps)
)(AcestralRiskWrapped);
