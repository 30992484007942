import React,{useEffect,useState} from 'react'
import {Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MultiDisease from './multi-disease'
import DiseaseItem from '../disease/diseaseItem'
import NoMatchPage from '../../../../404/not-found'
import {data} from '../disease/data'
const MultiDiseaseWrapped = ({customerDiseaseLk})=>{
    const { i18n } = useTranslation();
    const [lang, setLang]= useState(`/${i18n.language}`)
    useEffect(()=>{
        if (i18n.language === 'ro'){
            setLang('')
        }else {
            setLang(`/${i18n.language}`)
        }
    },[i18n.language])
    return(
        <Switch>
            <Route path={`${lang}/my-cabinet/disease-risk`} render={()=><MultiDisease customerDiseaseLk={customerDiseaseLk}/>} exact/>
            {
                data.map((item, idx) => {
                    const getData = customerDiseaseLk.illnessItems[idx]
                    // if(item.title===getData.name){

                        return <Route key={idx} path={`${lang}/my-cabinet/disease-risk/${item.link}`} render={()=><DiseaseItem data={item} getData={getData}/>} />

                    // }
                })
            }
            <Route component={NoMatchPage}/>
        </Switch>
    )
}
export default MultiDiseaseWrapped