import React, {useState, useEffect} from 'react'
import './not-found.sass'
import { Link, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
// import arr from '../pages/ancestry-page/tenth-section/img/arrow.svg'
const NoMatchPage = () => {
    const { t, i18n: {language} } = useTranslation();
    const [localPage, setLocalPage]= useState('')
    useEffect(()=>{
        setLocalPage(window.localStorage.getItem('getPage'))
    })
    const [lang, setLang]= useState(`/${language}`)
    useEffect(()=>{
        if (language === 'ro'){
            setLang('')
        }else {
            setLang(`/${language}`)
        }
    },[language])

    return (
        <div className="wrapp-no-found">
            <div className="link-wrapped">
                <ul className='burger-menu-list' >
                    <li className='burger-menu-list-wrap'>
                        <NavLink to={`${lang}/`} className="normal burger-menu-list-link" activeClassName='active' exact>
                            <span className="burger-menu-list-link-text">{t(`Главная`)}</span>
                        </NavLink>
                    </li>
                    <li className='burger-menu-list-wrap'>
                        <NavLink to={`${lang}/price-list`} className="normal burger-menu-list-link" activeClassName='active' exact>
                            <span className="burger-menu-list-link-text">{t(`Генетические тесты`)}</span>
                        </NavLink>
                    </li>

                            <li className='burger-menu-list-wrap'>
                                <NavLink  to={`${lang}/genetic-passport`} className="normal burger-menu-list-link" activeClassName='active' exact>
                                    <span className="burger-menu-list-link-text">{t(`Генетический паспорт`)}</span></NavLink>
                            </li>
                            <li className='burger-menu-list-wrap'>
                                <NavLink to={`${lang}/ancestry`} className="normal burger-menu-list-link" activeClassName='active' exact>
                                    <span className="burger-menu-list-link-text">{t(`Происхождение`)}</span></NavLink>
                            </li>
                            <li className='burger-menu-list-wrap'>
                                <NavLink to={`${lang}/cancer-tests`} className="normal burger-menu-list-link" activeClassName='active' exact>
                                    <span className="burger-menu-list-link-text">{t(`Онкотесты`)}</span></NavLink>
                            </li>
                    <li className='burger-menu-list-wrap'>
                        <NavLink to={`${lang}/blog`} className="normal burger-menu-list-link" activeClassName='active' exact>
                            <span className="burger-menu-list-link-text">{t(`Блог`)}</span>
                        </NavLink>
                    </li>
                    <li className='burger-menu-list-wrap'>
                        <NavLink  to={`${lang}/about`} className="normal burger-menu-list-link" activeClassName='active' exact>
                            <span className="burger-menu-list-link-text">{t(`О нас`)}</span>
                        </NavLink>
                    </li>
                    <li className='burger-menu-list-wrap'>
                        <NavLink to={`${lang}/contacts`} className="normal burger-menu-list-link" activeClassName='active' exact>
                            <span className="burger-menu-list-link-text">{t(`Контакты`)}</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
            <div className="arr">
                {/*<img src={arr} alt="arrow"/>*/}
            </div>
            <div className="content-wrapped">
                <h2>404</h2>
                <p>Not found</p>
            </div>

        </div>

    );
};
export default NoMatchPage