import React from 'react';
import bgMass from "./mass-index-bg.png";
import whaterI from "./ico/whater-ico.svg";
import cookingI from "./ico/cooking-ico.svg";
import fatsI from "./ico/fats-ico.svg";
import flourI from "./ico/flour-ico.svg";
import proteinI from "./ico/protein-ico.svg";
import portionI from "./ico/portion-ico.svg";
import aFatsI from "./ico/animals-fat-ico.svg";
import fastI from "./ico/fast-carbohydrates.svg";
import sausagesI from "./ico/sausages-ico.svg";
import warnI from "./ico/warn-ico.svg";
import {
    CircularProgressbarWithChildren,
    buildStyles,
  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import RadialSeparators from './RadialSeparators';
import { Doughnut } from 'react-chartjs-2';
import './atsio.sass'
// import { withTranslation } from 'react-i18next';


const TotalInformation = ({customerFoodLk}) => {
    const data = {
        labels: ["Белки", "Жиры", "Углеводы"],
        tooltipText: ["Белки", "Жиры", "Углеводы"],
        legend: ['display: false'],

        datasets: [{
            data: [customerFoodLk.composition.proteins, customerFoodLk.composition.fats, customerFoodLk.composition.carbohydrates],
            backgroundColor: [
                '#E84C3D',
                '#43C738',
                '#FD9C43'
            ],
            hoverBackgroundColor: [
                '#E84C3D',
                '#43C738',
                '#FD9C43'
            ],
            hoverBorderColor: [
                '#E84C3D',
                '#43C738',
                '#FD9C43',
            ],
            borderWidth: 3,
            hoverBorderWidth: 0
        }]
    };
        return(
            <section>
                 <div className="first-section">
                    <div className="d-flex">
                        <div className="calories-per-dey">
                            <div className="calories-progress">
                                <div className="text-block">
                                    <p className="calc-number">{customerFoodLk.daily_calorie_determination}</p>
                                    <p>ккалорий в день</p>
                                </div>
                                <Doughnut
                                    data={data}
                                    width={157}
                                    height={157}
                                    options={{
                                        maintainAspectRatio: false,
                                        responsive: true,
                                        cutoutPercentage: 60,
                                        // responsive:false,
                                        legend: { display: false },
                                        tooltips: { enabled: true }
                                    }}
                                />
                            </div>
                            <button>рассчитать</button>
                            
                        </div>
                        <div className="calories-count">
                            <p>Тип диеты:<span>{customerFoodLk.diet_type}</span></p>
                            <ul className="composition">
                                <li className="title">
                                    <p>Состав</p>
                                    <p>% в порции</p>
                                </li>
                                <li className="protein">
                                    <p>Белки</p>
                                    <p className="percent">{customerFoodLk.composition.proteins}%</p>
                                </li>
                                <li className="fats">
                                    <p>Жиры</p>
                                    <p className="percent">{customerFoodLk.composition.fats}%</p>
                                </li>
                                <li className="carbohydrates">
                                    <p>Углеводы</p>
                                    <p className="percent">{customerFoodLk.composition.carbohydrates}%</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="mass-index d-flex">
                        <p>Индекс массы тела</p>
                        <img src={bgMass} alt="mass index pic"/>
                        <div className="count">
                            <div>{customerFoodLk.body_mass}</div>
                        </div>
                        <div className="calculate-block">
                            <form>
                                <input type="text" placeholder="Введите ваш веc:" />
                                <input type="text" placeholder="Введите ваш рост:" />
                                <div>
                                    <button>Рассчитать</button>
                                    <button className="norms-btn">Нормы</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="recommendation-section">
                    <h5>Придерживаться</h5>
                    <ul className="information-block">
                        <li className="item portions">
                            <div className="icon__container">
                                <img src={portionI} alt="portions ico pic"/>
                            </div>
                            <div className="info">
                                <span>Порции. </span>
                                <p className="portion-info">{customerFoodLk.follow.portion}</p>
                            </div>
                        </li>
                        <li className="item portions">
                            <div className="icon__container">
                                <img src={whaterI} alt="portions ico pic"/>
                            </div>
                            <div className="info">
                                <span>Вода.</span>
                                <p className="portion-info"> {customerFoodLk.follow.water}</p>
                            </div>
                        </li>
                        <li className="item portions">
                            <div className="icon__container">
                                <img src={cookingI} alt="portions ico pic"/>
                            </div>
                            <div className="info">
                                <span>Приготовление.</span>
                                <p className="portion-info"> {customerFoodLk.follow.cooking}</p>
                            </div>
                        </li>
                        <li className="item portions">
                            <div className="icon__container">
                                <img src={fatsI} alt="portions ico pic"/>
                            </div>
                            <div className="info">
                                <span>Жиры.</span>
                                <p className="portion-info"> {customerFoodLk.follow.fats}</p>
                            </div>
                        </li>
                        <li className="item portions">
                            <div className="icon__container">
                                <img src={flourI} alt="portions ico pic"/>
                            </div>
                            <div className="info">
                                <span>Продукты из муки.</span>
                                <p className="portion-info"> {customerFoodLk.follow.flour_products}</p>
                            </div>
                        </li>
                        <li className="item portions">
                            <div className="icon__container">
                                <img src={proteinI} alt="portions ico pic"/>
                            </div>
                            <div className="info">
                                <span>Белковые продукты.</span>
                                <p className="portion-info"> {customerFoodLk.follow.protein_products}</p>
                            </div>
                        </li>    
                    </ul>
                </div>
                <div className="restrict-section">
                    <h5>Ограничить</h5>
                    <ul className="information-block">
                        <li className="item animal-fat">
                            <div className="icon__container">
                                <img src={aFatsI} alt="animal fat ico pic"/>
                            </div>
                            <div className="info">
                                <span>Животные жиры.</span>
                                <p className="animal-fat-info"> {customerFoodLk.restriction.animal_fats}</p>
                            </div>
                        </li>
                        <li className="item portions">
                            <div className="icon__container">
                                <img src={fastI} alt=" ico pic"/>
                            </div>
                            <div className="info">
                                <span>"Быстрые углеводы".</span>
                                <p className="portion-info"> {customerFoodLk.restriction.fast_carbohydrates}</p>
                            </div>
                        </li>
                        <li className="item portions">
                            <div className="icon__container">
                                <img src={sausagesI} alt="ico pic"/>
                            </div>
                            <div className="info">
                                <span>Колбасы любых видов.</span>
                                <p className="portion-info"> {customerFoodLk.restriction.sausages}</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="warn-products">
                    <img src={warnI} alt="warn ico pic"/>
                    <div className="info">
                        <span>Запретные продукты:</span>
                        <p className="warn-products_lest"> {customerFoodLk.forbidden_products}</p>
                    </div>
                </div>
                 <div className="healthy-plate-section">
                    <h5>Ваша тарелка здорового питания</h5>
                    <div className="progress-plate">
                        <div className="plate-block">
                            <div className="vegetables-status">
                                <CircularProgressbarWithChildren
                                    value={customerFoodLk.foodPlate.vegetables}
                                    text={`${customerFoodLk.foodPlate.vegetables}%`}
                                    strokeWidth={4}
                                    styles={buildStyles({
                                        strokeLinecap: 'round',
                                    })}
                                >
                                    <RadialSeparators
                                    count={10}
                                    style={{
                                        background: '#fff',
                                        width: '8px',
                                        // This needs to be equal to props.strokeWidth
                                        height: `${4}%`,
                                    }}
                                    />
                                </CircularProgressbarWithChildren>
                            </div>
                            <p>Овощи<br />(кроме картошки)</p>
                        </div>
                        <div className="plate-block">
                            <div className="vegetables-status">
                                <CircularProgressbarWithChildren
                                    value={customerFoodLk.foodPlate.healthy_proteins}
                                    text={`${customerFoodLk.foodPlate.healthy_proteins}%`}
                                    strokeWidth={4}
                                    styles={buildStyles({
                                        strokeLinecap: 'round',
                                    })}
                                >
                                    <RadialSeparators
                                    count={10}
                                    style={{
                                        background: '#fff',
                                        width: '8px',
                                        // This needs to be equal to props.strokeWidth
                                        height: `${4}%`,
                                    }}
                                    />
                                </CircularProgressbarWithChildren>
                            </div>
                            <p>Полезные белки<br />(рыба, птица, орехи)</p>
                        </div>
                        <div className="plate-block">
                            <div className="vegetables-status">
                                <CircularProgressbarWithChildren
                                    value={customerFoodLk.foodPlate.whole_grains}
                                    text={`${customerFoodLk.foodPlate.whole_grains}%`}
                                    strokeWidth={4}
                                    styles={buildStyles({
                                        strokeLinecap: 'round',
                                    })}
                                >
                                    <RadialSeparators
                                    count={10}
                                    style={{
                                        background: '#fff',
                                        width: '8px',
                                        // This needs to be equal to props.strokeWidth
                                        height: `${4}%`,
                                    }}
                                    />
                                </CircularProgressbarWithChildren>
                            </div>
                            <p>Цельнозерновые<br />(бурый рис, отруби)</p>
                        </div>
                        <div className="plate-block">
                            <div className="vegetables-status">
                                <CircularProgressbarWithChildren
                                    value={customerFoodLk.foodPlate.fruit}
                                    text={`${customerFoodLk.foodPlate.fruit}%`}
                                    strokeWidth={4}
                                    styles={buildStyles({
                                        strokeLinecap: 'round',
                                    })}
                                >
                                    <RadialSeparators
                                    count={10}
                                    style={{
                                        background: '#fff',
                                        width: '8px',
                                        // This needs to be equal to props.strokeWidth
                                        height: `${4}%`,
                                    }}
                                    />
                                </CircularProgressbarWithChildren>
                            </div>
                            <p>Фрукты</p>
                        </div>
                    </div>
                </div>
            </section>
        )
}
export default TotalInformation