import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import CustomerCabinet from '../pages/customer-page/customer-cabinet'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import PrivateRouteCustomer from '../../utils/private-route-cust'
import LoginPage from '../pages/login-page'
import 'bootstrap/dist/css/bootstrap.min.css'
import './app.sass'
const App = ()=>{
    const { t, i18n } = useTranslation();
    const [lang, setLang]= useState(`/${i18n.language}`)
    useEffect(()=>{
        if (i18n.language === 'ro'){
            setLang('')
        }else {
            setLang(`/${i18n.language}`)
        }
    },[i18n.language])
    return(
        <div>
            <Switch>
                <Route path={`${lang}/login`} component={LoginPage} exact/>
                <PrivateRouteCustomer component={CustomerCabinet} path={`${lang}/my-cabinet`}/>
            </Switch>
        </div>
    )
}

const AppContainer = ({ history }) => {
    const {  i18n } = useTranslation();
    const url = history.location.pathname;

    // const languageArray = ['/ro', '/ru', '/ro/', '/ru/'];
    // const hasLanguage = () => languageArray.includes(i18n.language);
    // const hasURL = () => languageArray.includes(url);
    // const [lang, setLang] = useState(':locales(ro|ru)?');
    // const [lang, setLang] = useState(!hasLanguage() ? 'ro' : i18n.language);

    const regRo = new RegExp('\/ro.*')
    const regRu = new RegExp('\/ru.*')
    const regEn = new RegExp('\/en.*')

    useEffect(() => {
        if (url === '/ro') {
            history.push('/')
            window.localStorage.setItem('i18nextLng','ro')
            i18n.changeLanguage('ro');
        }
        if(url === '/ro'&&window.localStorage.getItem('i18nextLng')==='ru'){
            history.push('/')
            window.localStorage.setItem('i18nextLng','ro')
            i18n.changeLanguage('ro');

        }
        if(url === '/ro'&&window.localStorage.getItem('i18nextLng')==='en'){
            history.push('/')
            window.localStorage.setItem('i18nextLng','ro')
            i18n.changeLanguage('ro');

        }
        if(window.localStorage.getItem('i18nextLng')!=='ro'&&window.localStorage.getItem('i18nextLng')!=='ru'&&window.localStorage.getItem('i18nextLng')!=='en'){
            window.localStorage.setItem('i18nextLng','ro')
        }

        if(regRo.test(history.location.pathname)){
            i18n.changeLanguage('ro');
        }
        if(regRu.test(history.location.pathname)){
            i18n.changeLanguage('ru');
        }
        if(regEn.test(history.location.pathname)){
            i18n.changeLanguage('en');
        }
        history.push(`${url.split()[0].replace('/ro/', '/')}`)

    }, []);



    // useEffect(() => {
    //   const tagManagerArgs = {
    //     gtmId: 'GTM-P9862JX',
    //   };
    //   if (window.localStorage.getItem('i18nextLng') === 'ro' || window.localStorage.getItem('i18nextLng') !== 'ru'){
    //       TagManager.initialize(tagManagerArgs);
    //   }
    // });

    return <App/>
};
export default withRouter(AppContainer)
