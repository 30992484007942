import React from 'react';
import {withTranslation} from "react-i18next";

import './gens-head.sass';

const GensHead = ({ i18n, t }) => {
	return (
		<div className="gens-head">
            <p className="gens-title gens-main-title">{t(`Название действующего вещества (препаратa)`)}:</p>
            <div>
                <p className="gens-title gens-center">{t(`Гены`)}:</p>
                <ul>
                    <li>CYP1A2</li>
                    <li>CYP2B6</li>
                    <li>CYP2C19</li>
                    <li>CYP2C9</li>
                    <li>CYP3A4</li>
                    <li>CYP2D6</li>
                    <li>CYP3A5</li>
                    <li>UGT2B15</li>
                    <li>VKORC1 <br/> Rs9923231</li>
                </ul>
            </div>
        </div>
	);
}
export default withTranslation()(GensHead)