import React, {Component} from 'react';
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import compose from "../../../../utils/compose";
import {fetchCustomerLKPsychogenetic} from "../../../../actions";
import {connect} from "react-redux";
import Spinner from '../../../icons/Spinner';
import ErrorIndicator from '../../../error-indicator';
import PsychogeneticsPage from './psychogenetics-page'

class PsychogeneticsPageWrapped extends Component{
    componentDidMount(){
        this.props.fetchCustomerLKPsychogenetic()
    }

    render(){
        const { customerPsychogeneticLk, loadingPsychogeneticLk, errorPsychogeneticLk } = this.props;
        if (loadingPsychogeneticLk) {
            return <Spinner />;
        }

        if (errorPsychogeneticLk) {
            return <ErrorIndicator />;
        }

        return <PsychogeneticsPage customerPsychogeneticLk={customerPsychogeneticLk}/>
    }
}
const mapStateToProps = ({ customerPsychogeneticLk, loadingPsychogeneticLk, errorPsychogeneticLk }) => {
    return {  customerPsychogeneticLk, loadingPsychogeneticLk, errorPsychogeneticLk };
};

const mapDispatchToProps = (dispatch, { genobiosisService }) => {
    return {
        fetchCustomerLKPsychogenetic: fetchCustomerLKPsychogenetic(genobiosisService, dispatch)
    };
};

export default compose(withGenobiosisService(), connect(mapStateToProps, mapDispatchToProps)
)(PsychogeneticsPageWrapped);
