import React from 'react'
import TrainingsCreate from './trainings-create'

const TrainingsBlock = ()=>{
    return (
        <div className="d-flex flex-column">
            <TrainingsCreate/>
        </div>
    )
}
export default TrainingsBlock