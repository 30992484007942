import React,{Component} from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux';
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import { fetchCustomerData } from '../../../../actions'
import  compose  from '../../../../utils/compose';

import Spinner from '../../../icons/Spinner';
import ErrorIndicator from '../../../error-indicator';
import SettingPageItem from "./setting-page-item";
import ModalEditMail from './modal-edit-mail'
import ModalEditPass from './modal-edit-pass'
import ModalEditPhone from './modal-edit-phone'


class SettingsPage extends Component {
    state={
        openMail: false,
        openPhone: false,
        openPass: false
    };
    onOpenMail = () => {
        this.setState({ openMail: true });
    };

    onCloseMail = () => {
        this.setState({ openMail: false });
    };
    onOpenPhone = () => {
        this.setState({ openPhone: true });
    };

    onClosePhone = () => {
        this.setState({ openPhone: false });
    };
    onOpenPass = () => {
        this.setState({ openPass: true });
    };

    onClosePass = () => {
        this.setState({ openPass: false });
    };

    componentDidMount(){
        this.props.fetchCustomerData()
    }
    render(){
        const { customer, loadingC, errorC, t } = this.props;
        const { openMail, openPhone, openPass } = this.state;
        if (loadingC) {
            return <Spinner />;
        }

        if (errorC) {
            return <ErrorIndicator />;
        }

        return(
            <section className="settings-info">
                <ModalEditMail customer={customer} open={openMail} onClose={this.onCloseMail}/>
                <ModalEditPass customer={customer} open={openPass} onClose={this.onClosePass}/>
                <ModalEditPhone customer={customer} open={openPhone} onClose={this.onClosePhone}/>
                <div className='title-content'>
                    <h4>{t(`Настройки профиля`)}</h4>
                </div>
                <SettingPageItem
                    customer={customer}
                    onOpenMail={this.onOpenMail}
                    onOpenPhone={this.onOpenPhone}
                    onOpenPass={this.onOpenPass}
                />
            </section>
        )
    }
}
const mapStateToProps = ({ customer, loadingC, errorC }) => {
    return { customer, loadingC, errorC };
};

const mapDispatchToProps = (dispatch, { genobiosisService }) => {
    return {
        fetchCustomerData: fetchCustomerData(genobiosisService, dispatch)
    };
};


export default compose(withGenobiosisService(), withTranslation(), connect(mapStateToProps, mapDispatchToProps)
)(SettingsPage);