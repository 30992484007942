import React from 'react'

import v1_img from './img/caffeine.png'
import v2_img from './img/lactose.png'
import v3_img from './img/gluten.png'
import v4_img from './img/alcohol.png'


import './intolerance.sass'
import { withTranslation } from 'react-i18next'

const Intolerance = ({t, customerFoodLk}) => {

    return(
                <div className="intoPading">
                    <div className="vitamins-container">
                        <div className="vitaminsTab-content">
                                    <div className="vitaminsTab-content-title">
                                        <div className="vitaminsTab-content-title-name color-seventh">
                                            <h3 className="h3">{t(`Восприятие кофеина`)}</h3>
                                            <div className="vitaminsTab-bar">
                                                <div className="vitaminsTab-bar-line" style={{'width': customerFoodLk.caffeinePerception.percent+'%'}}></div>
                                            </div>
                                        </div>
                                        <div className="vitaminsTab-content-title-info">
                                            <p className="vitaminsTab-content-title-info-title">{t(`Чувствительность`)}:</p>
                                            <p className="vitaminsTab-content-title-info-desc">{customerFoodLk.caffeinePerception.sensitivity}</p>
                                        </div>
                                    </div>
                                    <div className="vitaminsTab-content-description border-padding">
                                        <img className="vitaminsTab-content-description-img" src={v1_img} alt="vitamins" />
                                        <div>
                                            <h3 className="h3">{t(`Описание`)}</h3>
                                            <p className="vitaminsTab-content-description-text"> {t(`Кофеин – это органическое вещество, обладающее стимулирующим эффектом. Оно содержится в чае, кофе, гуаране, коле и других растениях, а также может синтезироваться искусственным путем. Эффект от кофеина будет разным в зависимости от возраста, пола, особенностей нервной системы и состояния здоровья человека, который его употребляет.`)} </p>
                                            <p className="vitaminsTab-content-description-text">{t(`Данное вещество содержится в обычных продуктах, напитках и спортивном питании, а синтетический аналог нашел свое применение в медицине. Кофеин может оказывать как положительное, так и негативное воздействие на организм, поэтому перед употреблением рекомендуется проконсультироваться с врачом.`)}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="vitaminsTab-content-list border-padding">
                                            <div className="vitaminsTab-content-list-img">
                                                <span className="icon-plus"></span>
                                            </div>
                                            <ul className="vitaminsTab-content-list-box">
                                                <h3 className="h3">{t(`Полезные свойства кофеина`)}</h3>
                                                <ul className="d-flex">
                                                    <ul className="vitaminsTab-content-list-box">
                                                        <li className="vitaminsTab-content-description-text"> {t(`помогает бороться с сонливостью;`)} </li>
                                                        <li className="vitaminsTab-content-description-text"> {t(`является антидепрессантом;`)} </li>
                                                        <li className="vitaminsTab-content-description-text"> {t(`снимает усталость, хорошо бодрит;`)} </li>
                                                        <li className="vitaminsTab-content-description-text"> {t(`улучшает питание тканей;`)} </li>
                                                        
                                                    </ul>
                                                    <ul className="vitaminsTab-content-list-box">
                                                        <li className="vitaminsTab-content-description-text"> {t(`повышает физическую активность;`)} </li>
                                                        <li className="vitaminsTab-content-description-text"> {t(`способствует снижению веса;`)} </li>
                                                        <li className="vitaminsTab-content-description-text"> {t(`улучшает умственную деятельность;`)} </li>
                                                        <li className="vitaminsTab-content-description-text"> {t(`снижает уровень сахара;`)} </li>
                                                    </ul>
                                                    <ul className="vitaminsTab-content-list-box">
                                                        
                                                        <li className="vitaminsTab-content-description-text"> {t(`усиливает сердечные сокращения;`)} </li>
                                                        <li className="vitaminsTab-content-description-text"> {t(`повышает выносливость;`)} </li>
                                                        <li className="vitaminsTab-content-description-text"> {t(`ускоряет процесс обмена веществ;`)} </li>
                                                    </ul>
                                                </ul>
                                            </ul>
                                        </div>
                                        <div className="vitaminsTab-content-list border-padding">
                                            <div className="vitaminsTab-content-list-img">
                                                <span>—</span>
                                            </div>
                                            <ul className="vitaminsTab-content-list-box">
                                                <h3 className="h3">{t(`Негативное влияние кофеина`)}</h3>
                                                    <ul className="vitaminsTab-content-list-box">
                                                        <li className="vitaminsTab-content-description-text"> {t(`вызывает артериальную гипертензию, повышает холестерин в крови, увеличивая риск развития атеросклероза;`)}</li>
                                                        <li className="vitaminsTab-content-description-text"> {t(`передозировка кофеином может привести к истощению нервных клеток;`)}</li>
                                                        <li className="vitaminsTab-content-description-text"> {t(`обладает ярко выраженным мочегонным эффектов, тем самым вызывая обезвоживание организма;`)}</li>
                                                        <li className="vitaminsTab-content-description-text"> {t(`препятствует усвоению кальция, магния, калия и других микроэлементов;`)}</li>
                                                        <li className="vitaminsTab-content-description-text"> {t(`сильное воздействие на центральную нервную систему, что опасно при ее возбудимости;`)}</li>
                                                    </ul>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="vitaminsTab-content-result">
                                        <h3 className="h3">{t(`ваши результаты`)}</h3> 
                                        <ul>
                                            <li className="vitaminsTab-content-description-text">{t(`Ген`)}</li>
                                            <li className="vitaminsTab-content-description-text">{t(`Полиморфизм`)}</li>
                                            <li className="vitaminsTab-content-description-text"> {t(`Ваш тип`)}</li>
                                        </ul>
                                            {
                                                customerFoodLk.caffeinePerception.results.map((data, idx)=>{
                                                    return (
                                                        <ul key={idx}>
                                                            <li className="vitaminsTab-content-description-text">{t(`${data.gene}`)}</li>
                                                            <li className="vitaminsTab-content-description-text">{t(`${data.polymorphism}`)}</li>
                                                            <li className="vitaminsTab-content-description-text">{t(`${data.type}`)}</li>
                                                        </ul>
                                                    )
                                                })
                                            }

                                    </div>
                                    <div className="vitaminsTab-content-recommendation">
                                        <h3 className="h3">
                                            <span className="icon-like"></span>
                                            <span>{t(`Расшифровка и рекомендации генетика`)}</span>
                                        </h3>
                                        <p className="vitaminsTab-content-description-text">{customerFoodLk.caffeinePerception.recommendations}</p>
                                    </div>
                        </div>
                    </div>
                    <div className="vitamins-container">    
                        <div className="vitaminsTab-content">
                                    <div className="vitaminsTab-content-title">
                                        <div className="vitaminsTab-content-title-name color-third">
                                            <h3 className="h3">{t(`Непереносимость лактозы`)}</h3>
                                            <div className="vitaminsTab-bar">
                                                <div className="vitaminsTab-bar-line" style={{'width':customerFoodLk.lactoseIntolerance.percent+'%'}}></div>
                                            </div>
                                        </div>
                                        <div className="vitaminsTab-content-title-info">
                                            <p className="vitaminsTab-content-title-info-title">{t(`Риск`)}:</p>
                                            <p className="vitaminsTab-content-title-info-desc">{customerFoodLk.lactoseIntolerance.chance}</p>
                                        </div>
                                    </div>
                                    <div className="vitaminsTab-content-description border-padding">
                                        <img className="vitaminsTab-content-description-img" src={v2_img} alt="vitamins" />
                                        <div>
                                            <h3 className="h3">{t(`Описание`)}</h3>
                                            <p className="vitaminsTab-content-description-text">{t(`Лактоза - это углевод, который присутствует исключительно в молоке и молочных продуктах, за его расщепление в процессе пищеварения отвечает отдельный фермент - лактаза. Если организм вырабатывает его в недостаточном количестве, то развивается гиполактазия (непереносимость лактозы), сопровождающаяся расстройствами со стороны органов желудочно-кишечного тракта.`)}</p>
                                            <p className="vitaminsTab-content-description-text">{t(`Данное вещество является источником энергии, улучшает обмен кальция, а также поддерживает нормальную микрофлору в кишечнике, необходимую для роста лактобактерий. Молочные продукты обогащены полезными компонентами, но при гиполактазии их употребление невозможно.`)}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="vitaminsTab-content-list border-padding">
                                            <div className="vitaminsTab-content-list-img">
                                                <span className="icon-plus"></span>
                                            </div>
                                            <ul className="vitaminsTab-content-list-box">
                                                <h3 className="h3"> {t(`Причины и симптомы заболевания`)} </h3>
                                                <p className="vitaminsTab-content-description-text">{t(`Гиполактазия может быть врожденной или приобретенной. В первом случае она вызвана наследственной предрасположенностью, а во втором – возникает под воздействием экзогенных факторов (особенностей питания, образа жизни). Вне зависимости от происхождения проблемы она все равно связана с мутациями в генах LCT и MCM6, которые кодируют лактазу и обеспечивают ее синтез.`)} </p>
                                                <p className="vitaminsTab-content-description-text">{t(`Симптомы лактозной недостаточности определяются избыточным ростом и усилением жизнедеятельности микрофлоры кишечника, которая усваивает лактозу. Верными признаками гиполактазии являются метеоризм, боли в животе, нарушение стула, иногда рвота. Эти симптомы возникают только в случае употребления лактозосодержащих продуктов и не проявляются при диетическом питании.`)} </p>
                                            </ul>
                                        </div>
                                        <div className="vitaminsTab-content-list border-padding">
                                            <div className="vitaminsTab-content-list-img">
                                                <span>—</span>
                                            </div>
                                            <ul className="vitaminsTab-content-list-box">
                                                <h3 className="h3"> {t(`Особенности питания при непереносимости лактозы`)} </h3>
                                                <p className="vitaminsTab-content-description-text"> {t(`Человеку, у которого диагностирована непереносимость лактозы, необходимо придерживаться специальной диеты. Он может употреблять продукты, которые прошли процесс молочнокислого брожения (кефир, творог, йогурт). Если патология выражена слишком сильно, то лучше отдавать предпочтение безлактозному молоку. В определенных случаях врач может назначить прием фермента, который способствует расщеплению лактозы на более простые вещества.`)} </p>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="vitaminsTab-content-result">
                                        <h3 className="h3">{t(`ваши результаты`)}</h3> 
                                        <ul>
                                            <li className="vitaminsTab-content-description-text">{t(`Ген`)}</li>
                                            <li className="vitaminsTab-content-description-text">{t(`Полиморфизм`)}</li>
                                            <li className="vitaminsTab-content-description-text"> {t(`ваш тип`)}</li>
                                        </ul>
                                        {
                                            customerFoodLk.lactoseIntolerance.results.map((data, idx)=>{
                                                return (
                                                    <ul key={idx}>
                                                        <li className="vitaminsTab-content-description-text">{t(`${data.gene}`)}</li>
                                                        <li className="vitaminsTab-content-description-text">{t(`${data.polymorphism}`)}</li>
                                                        <li className="vitaminsTab-content-description-text">{t(`${data.type}`)}</li>
                                                    </ul>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="vitaminsTab-content-recommendation">
                                        <h3 className="h3">
                                            <span className="icon-like"></span>
                                            <span>{t(`Расшифровка и рекомендации генетика`)}</span>
                                        </h3>
                                        <p className="vitaminsTab-content-description-text">{customerFoodLk.lactoseIntolerance.recommendations}</p>
                                    </div>
                        </div>
                    </div>
                    <div className="vitamins-container">    
                        <div className="vitaminsTab-content">
                                    <div className="vitaminsTab-content-title">
                                        <div className="vitaminsTab-content-title-name color-seventh">
                                            <h3 className="h3">{t(`Непереносимость глютена`)}</h3>
                                            <div className="vitaminsTab-bar">
                                                <div className="vitaminsTab-bar-line" style={{'width':customerFoodLk.glutenIntolerance.percent+'%'}}></div>
                                            </div>
                                        </div>
                                        <div className="vitaminsTab-content-title-info">
                                            <p className="vitaminsTab-content-title-info-title">{t(`Риск`)}:</p>
                                            <p className="vitaminsTab-content-title-info-desc">{customerFoodLk.glutenIntolerance.chance}</p>
                                        </div>
                                    </div>
                                    <div className="vitaminsTab-content-description border-padding">
                                        <img className="vitaminsTab-content-description-img" src={v3_img} alt="vitamins" />
                                        <div>
                                            <h3 className="h3">{t(`Описание`)}</h3>
                                            <p className="vitaminsTab-content-description-text">{t(`Глютен - это группа белков, которые содержатся в злаках, но помимо данного вещества в этих продуктах есть и другие полезные компоненты. Однако для организма некоторых людей глютен является вредным – это связано с индивидуальным особенностями, определяющими способность к расщеплению и усвоению данных белков.`)}</p>
                                            <p className="vitaminsTab-content-description-text">{t(`Заболевание, которое связано с непереносимостью глютена, называется целиакия, зачастую его обнаруживают в детском возрасте, поскольку в этот период развития организма наблюдаются наиболее выраженные симптомы. Причиной болезни являются генетические нарушения, которые создают благоприятные условия для поражения слизистой оболочки тонкой кишки под воздействием глютена.`)}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="vitaminsTab-content-list border-padding">
                                            <div className="vitaminsTab-content-list-img">
                                                <span className="icon-plus"></span>
                                            </div>
                                            <ul className="vitaminsTab-content-list-box">
                                                <h3 className="h3"> {t(`Глютен`)}: {t(`польза и вред`)} </h3>
                                                <p className="vitaminsTab-content-description-text">{t(`Врожденная непереносимость глютена есть у 1% людей, но многие поддаются модным трендам и осведомленно отказываются от употребления данного белка, даже не имея для этого медицинских показаний. Безглютеновые продукты не всегда помогут снизить вес, поскольку возрастает вероятность переедания из-за их низкой энергетической ценности. Меньшее потребление клетчатки вызывает нарушение пищеварения, а также может привести к дефициту железа, цинка, фолиевой кислоты и витамина D. Если у человека диагностирована целиакия, то ему нужно внимательно следить за своим рационом и не допускать обострения заболевания.`)} </p>
                                            </ul>
                                        </div>
                                        <div className="vitaminsTab-content-list border-padding">
                                            <div className="vitaminsTab-content-list-img">
                                                <span>—</span>
                                            </div>
                                            <ul className="vitaminsTab-content-list-box">
                                                <h3 className="h3"> {t(`Факторы, провоцирующие возникновение заболевания или ведущие к его обострению`)}: </h3>
                                                <ul className="vitaminsTab-content-list-box">
                                                    <li className="vitaminsTab-content-description-text">{t(`кишечные инфекции бактериального или вирусного происхождения;`)} </li>
                                                    <li className="vitaminsTab-content-description-text">{t(`аутоиммунные заболевания, хронический активный гепатит;`)} </li>
                                                    <li className="vitaminsTab-content-description-text">{t(`избыточное потребление злаков в виде сдобной выпечки, хлеба и каш;`)} </li>
                                                    <li className="vitaminsTab-content-description-text">{t(`хирургическое вмешательство при воспалении аппендикса;`)} </li>
                                                    <li className="vitaminsTab-content-description-text">{t(`сильный стресс, недосыпание, эндокринные нарушения (сахарный диабет);`)} </li>
                                                </ul>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="vitaminsTab-content-result">
                                        <h3 className="h3">{t(`ваши результаты`)}</h3> 
                                        <ul>
                                            <li className="vitaminsTab-content-description-text">{t(`Ген`)}</li>
                                            <li className="vitaminsTab-content-description-text">{t(`Полиморфизм`)}</li>
                                            <li className="vitaminsTab-content-description-text"> {t(`ваш тип`)}</li>
                                        </ul>
                                        {
                                            customerFoodLk.glutenIntolerance.results.map((data, idx)=>{
                                                return (
                                                    <ul key={idx}>
                                                        <li className="vitaminsTab-content-description-text">{t(`${data.gene}`)}</li>
                                                        <li className="vitaminsTab-content-description-text">{t(`${data.polymorphism}`)}</li>
                                                        <li className="vitaminsTab-content-description-text">{t(`${data.type}`)}</li>
                                                    </ul>
                                                )
                                            })
                                        }                                    </div>
                                    <div className="vitaminsTab-content-recommendation">
                                        <h3 className="h3">
                                            <span className="icon-like"></span>
                                            <span>{t(`Расшифровка и рекомендации генетика`)}</span>
                                        </h3>
                                        <p className="vitaminsTab-content-description-text">{customerFoodLk.glutenIntolerance.recommendations}</p>
                                    </div>
                        </div>
                    </div>
                    <div className="vitamins-container">    
                        <div className="vitaminsTab-content">
                                    <div className="vitaminsTab-content-title">
                                        <div className="vitaminsTab-content-title-name color-third">
                                            <h3 className="h3">{t(`Непереносимость алкоголя`)}</h3>
                                            <div className="vitaminsTab-bar">
                                                <div className="vitaminsTab-bar-line" style={{'width':'40%'}}></div>
                                            </div>
                                        </div>
                                        <div className="vitaminsTab-content-title-info">
                                            <p className="vitaminsTab-content-title-info-title">{t(`Риск`)}:</p>
                                            <p className="vitaminsTab-content-title-info-desc">{t(`Низкий`)}</p>
                                        </div>
                                    </div>
                                    <div className="vitaminsTab-content-description border-padding">
                                        <img className="vitaminsTab-content-description-img" src={v4_img} alt="vitamins" />
                                        <div>
                                            <h3 className="h3">{t(`Описание`)}</h3>
                                            <p className="vitaminsTab-content-description-text">{t(`Алкоголь - это вещество, которое в небольших дозах присутствует в организме каждого человека. Он является продуктом переработки углеводов. При нормальном метаболизме система детоксикации самостоятельно справляется с вредными веществами и выводит их. Однако каждый из нас по-разному реагирует на этиловый спирт, который попадает в организм извне.`)}</p>
                                            <p className="vitaminsTab-content-description-text">{t(`Различают два основных вида данной патологии - врожденная и приобретенная. Генетически обусловленным является первый вид непереносимости, которая связана с дефектами генов, отвечающих за переработку алкоголя. Приобретенная патология возникает в течение жизни под воздействием неблагоприятных внешних факторов (питание, прием некоторых лекарственных препаратов).`)}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="vitaminsTab-content-list border-padding">
                                            <div className="vitaminsTab-content-list-img">
                                                <span className="icon-plus"></span>
                                            </div>
                                            <ul className="vitaminsTab-content-list-box">
                                                <h3 className="h3"> {t(`Симптомы патологии`)} </h3>
                                                <p className="vitaminsTab-content-description-text">{t(`При наличии непереносимости алкоголя у человека проявляются характерные симптомы. После употребления даже небольших доз этанола у пациента меняется артериальное давление, цвет кожных покровов, возникает насморк, отек конечностей, головная боль, расстройства со стороны желудочно-кишечного тракта.`)} </p>
                                                <p className="vitaminsTab-content-description-text">{t(`В группу риска развития алкогольной непереносимости попадают люди некоторых этнических принадлежностей, а также те, у кого диагностирована лимфома Ходжкина. Вероятность обнаружения патологии возрастает, если человек принимает определенные лекарственные препараты, лечится от алкоголизма или имеет аллергию на компоненты, входящие в состав спиртных напитков.`)} </p>
                                            </ul>
                                        </div>
                                        <div className="vitaminsTab-content-list border-padding">
                                            <div className="vitaminsTab-content-list-img">
                                                <span>—</span>
                                            </div>
                                            <ul className="vitaminsTab-content-list-box">
                                                <h3 className="h3"> {t(`Диагностика и терапия`)} </h3>
                                                <p className="vitaminsTab-content-description-text"> {t(`Если побочные реакции протекают в легкой форме, то обращаться за медицинской помощью нет необходимости. Однако при развитии опасных симптомов (удушье, кровотечение, острый болевой синдром) следует немедленно проконсультироваться с врачом. Важно выяснить причину такого состояния, чтобы отличить обычную пищевую аллергию от непереносимости алкоголя. Наиболее точным методом диагностики является генетическое тестирование, результаты которого покажут, есть ли у Вас предрасположенность к данной патологии, связанная с мутациями генов.`)} </p>
                                                <p className="vitaminsTab-content-description-text"> {t(`Избежать нежелательных последствий после употребления спиртного при наличии непереносимости алкоголя невозможно. Лучшей профилактикой является полный отказ от этанолсодержащих напитков или прием препаратов, устраняющих неприятные симптомы.`)} </p>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="vitaminsTab-content-result">
                                        <h3 className="h3">{t(`ваши результаты`)}</h3> 
                                        <ul>
                                            <li className="vitaminsTab-content-description-text">{t(`Ген`)}</li>
                                            <li className="vitaminsTab-content-description-text">{t(`Полиморфизм`)}</li>
                                            <li className="vitaminsTab-content-description-text"> {t(`ваш тип`)}</li>
                                        </ul>
                                        <ul>
                                            <li className="vitaminsTab-content-description-text">BCMO1</li>
                                            <li className="vitaminsTab-content-description-text">rs11645428</li>
                                            <li className="vitaminsTab-content-description-text">A/G</li>
                                        </ul>
                                    </div>
                                    <div className="vitaminsTab-content-recommendation">
                                        <h3 className="h3">
                                            <span className="icon-like"></span>
                                            <span>{t(`Расшифровка и рекомендации генетика`)}</span>
                                        </h3>
                                        <p className="vitaminsTab-content-description-text">{t(`Вы обладаете вариантом гена, который эффективно превращает неактивную форму Витамина А в активную. Потребление продуктов, которые имеют более высокое содержание активного витамина А для вас не является обязательным.`)}</p>
                                    </div>
                        </div>
                    </div>
                </div>            
        )
}
export default withTranslation()(Intolerance)