import React,{ useState } from 'react'
import withGenobiosisService from "../../hoc/with-genobiosis-service";
import './customer-page.sass'
import ico from './img/photo-icon.svg'

const NewAvatar = ({genobiosisService, avatar})=>{
    const [img, setImg]=useState(avatar)

    const onFormSubmit = (e) =>{
        fileUpload(e.target.files[0]).then((response)=>{
            console.log(response.data);
        })
        setImg(URL.createObjectURL(e.target.files[0]))

    }
    const fileUpload =(img)=>{
        const formData = new FormData();
        formData.append('avatar',img)
        return genobiosisService.loadAvatar(formData)
    }
    return(
        <div className="img-wrapped">
            <img src={img} alt="avatar"/>
            <label className="open-img">
                <span className="hover-icon">
                    <img src={ico} alt="icon"/>
                </span>
                <input type="file" accept="image/jpg, image/jpeg, image/png" onChange={onFormSubmit} />
            </label>
        </div>
    )

}


export default withGenobiosisService()(NewAvatar)