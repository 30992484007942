import React, {Component} from 'react';
import Steps, { Step } from 'rc-steps';

import {Tabs, Tab} from 'react-bootstrap'
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import '../customer-page.sass';
import { connect } from 'react-redux';
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import {fetchCustomerLKFood} from '../../../../actions';
import  compose  from '../../../../utils/compose';
import Spinner from '../../../icons/Spinner';
import ErrorIndicator from '../../../error-indicator';
import TotalInformation from './total-information/total-information.js'


import Vitamins from './vitamins/vitamins'
import Intolerance from './intolerance/intolerance'
import Habits from './habits/habits'
import { withTranslation } from 'react-i18next';

class NutritionPage extends Component{

	componentDidMount(){
		this.props.fetchCustomerLKFood()
	}

	render(){

		const { customerFoodLk, loadingFoodLk, errorFoodLk, t } = this.props;
		if (loadingFoodLk) {
			return <Spinner />;
		}

		if (errorFoodLk) {
			return <ErrorIndicator />;
		}
		return(

			<section className="nutrition-page">
                <div className="navigation-content">
                    <Tabs id="uncontrolled-tab-example" className="nutrition-page-tab">
                        <Tab eventKey="data" title="Общие данные">
                            <div className="block total-information">
                               <TotalInformation customerFoodLk={customerFoodLk}/>
                            </div>
                        </Tab>
                        <Tab eventKey="vitamins" title={t(`Витамины и минералы`)}>
                            <div className="block vitamins-content">
                                <Vitamins customerFoodLk={customerFoodLk}/>
                            </div>
                        </Tab>
                        <Tab eventKey="intolerance" title="Пищевые непереносимости">
                            <div className="block intolerance-content">
                                <Intolerance customerFoodLk={customerFoodLk}/>
                            </div>
                        </Tab>
                        <Tab eventKey="habits" title="Пищевые привычки">
                            <div className="block habits-content">
                                <Habits customerFoodLk={customerFoodLk}/>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
			</section>
		)
	}
}
const mapStateToProps = ({ customerFoodLk, loadingFoodLk, errorFoodLk }) => {
	return {  customerFoodLk, loadingFoodLk, errorFoodLk };
};

const mapDispatchToProps = (dispatch, { genobiosisService }) => {
	return {
        fetchCustomerLKFood: fetchCustomerLKFood(genobiosisService, dispatch)
	};
};

export default compose(withGenobiosisService(), withTranslation(), connect(mapStateToProps, mapDispatchToProps)
)(NutritionPage);