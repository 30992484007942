import a from "./img/vitamin-a.png";
import b9 from "./img/vitamin-b9.png";
import c from "./img/vitamin-c.png";
import e from "./img/vitamin-e.png";
import d from "./img/vitamin-d.png";
import fe from "./img/vitamin-fe.png";
import b12 from "./img/vitamin-b12.png";
import omega from "./img/vitamin-omega3.png";
import antioxidants from "./img/vitamin-oxydants.png"

export const data = [
    {
      name: 'a',
      getName: 'A',
      fullName:'Витамин А',
      color: 'color-one',
      height: '80%',
      img: a,
      chance: 'Рисков не обнаружено',
      description:[
        {text: 'Витамин A - это группа органических соединений, которая включает в себя несколько химических форм одного и того же вещества. Биохимические предшественники витамина A (провитамины ретинол, ретиналь, каротиноиды, каротин, ксантофилл) содержатся в моркови, тыкве, брокколи, шпинате, зелени лука и петрушки, бобовых, фруктах, рыбе, печени, яичном желтке, молокопродуктах.'},
        {text: 'Данный витамин выполняет целый ряд важных функций в человеческом организме, начиная от обеспечения антиоксидантной защиты, заканчивая укреплением структуры клеточных мембран. Он нормализует секрецию потовых желез, предотвращает нарушение сумеречного зрения и положительно влияет на репродуктивную систему человека.'}
      ],
      deficitTitle: 'Признаки дефицита витамина A',
      excessTitle: 'Симптомы избытка витамина A',
      deficit: [
        {text:'снижение остроты зрения, сухость глаз, нарушение способности адаптироваться к слабому освещению;'},
        {text:'общая слабость, быстрая утомляемость, снижение работоспособности, апатия, снижение качества сна;'},
        {text:'эректильная дисфункция вплоть до импотенции, гинекологические заболевания, мастопатии, аменорея;'},
        {text:'сухость слизистых оболочек, бледность кожных покровов, медленное заживление ран;'},
        {text:'повышенная чувствительность зубов, поражение эмали и другие стоматологические проблемы'},
        {text:'преждевременное старение и шелушение кожи, возникновение перхоти, активное выпадение волос;'},
        {text:'заболевания пищеварительной системы, снижение аппетита, стремительная потеря веса;'},
        {text:'частые респираторные заболевания из-за нарушения нормальной функции иммунной системы.'}
      ],
      excess:[
        {text:'эмоциональная неустойчивость, резкие перепады настроения, повышенная возбудимость;'},
        {text:'ощущения слабости, боли в животе, суставах и костях, судороги, иногда потеря сознания;'},
        {text:'головные боли, сопровождающиеся тошнотой, рвотой и расстройством пищеварения;'},
        {text:'повышение температуры тела, лихорадочные состояния, увеличение частоты сердечного ритма;'},
        {text:'гепатоспленомегалия - увеличение селезенки и печени из-за нарушения обмена веществ;'},
        {text:'ухудшение состояния ногтей, выпадение волос, пожелтение кожи, шелушение, зуд и сыпь;'},
        {text:'повышение уровня холестерина, нарушение функции мочевыделительной системы;'},
        {text:'у грудничков наблюдается потеря аппетита, рвота, выбухание родничка, иногда гидроцефалия.'}
      ],
      results:[
        {gen:'BCMO1',polymorphism:'rs11645428',type:'A/G'},
        {gen:'BCMO1',polymorphism:'rs11645428',type:'A/G'}
      ],
      recommendation:'Вы обладаете вариантом гена, который эффективно превращает неактивную форму Витамина А в активную. Потребление продуктов, которые имеют более высокое содержание активного витамина А для вас не является обязательным.'
    },
    {
      name: 'b9',
      getName: 'B9',
      fullName:'Витамин В9',
      color: 'color-second',
      height: '15%',
      img: b9,
      chance: 'Рисков не обнаружено',
      deficitTitle: 'Признаки дефицита витамина В9',
      excessTitle: 'Симптомы избытка витамина В9',
      description:[
        {text: 'Витамин B9, известный как фолиевая кислота, представляет собой водорастворимое вещество, необходимое для нормального функционирования кровеносной системы, формирования и укрепления иммунитета. Данный витамин поступает в организм вместе с продуктами питаниями в виде фолатов, превращаясь в активную форму с помощью фермента, количество которого определяется структурой специального гена. Также витамин может синтезироваться микрофлорой кишечника. В больших количествах вещество присутствует в зеленых овощах, цитрусовых, цельнозерновом хлебе, печени, дрожжах и меде, спарже, изюме, арахисе и семечках. 100 г каждого из этих продуктов закрывает потребность в В9 на 27-52% от суточной нормы.'},
        {text: 'Фолиевая кислота жизненно необходимая для формирования новых клеток, а также поддержания их в здоровом состоянии. Она способствует синтезу аминокислот, снижает риск развития патологий центральной нервной системы и улучшает функцию репродуктивной системы.'}
      ],
      deficit: [
        {text:'снижение гемоглобина, которое сопровождается слабостью, бледностью кожных покровов из-за недостатка кислорода в периферических тканях;'},
        {text:'ухудшение состояния ногтей (они становятся ломкими, слоятся) и волос (седеют, активно выпадают, теряют здоровый блеск и привлекательный вид);'},
        {text:'вздутие и болезненность языка, кровоточивость десен, стоматит, гастрит, энтерит, язва;'},
        {text:'плохой аппетит;'},
        {text:'резкое снижение мышечной массы, частые пищевые интоксикации из-за дефицита желудочного сока;'},
        {text:'появление раздражительности, общей слабости, сонливости, агрессивности, нарушения сна, апатии, депрессивного состояния;'},
        {text:'нарушение процессов кроветворения, сопровождающееся анемией (снижением уровня эритроцитов) и лейкопенией (дефицитом лейкоцитов);'},
        {text:'невриты, воспаление слизистых оболочек, головные боли, продолжительное заживление ран, незначительное повышение температуры тела.'}
      ],
      excess:[
        {text:'неприятный металлический привкус во рту;'},
        {text:'резкие перепады настроения, раздражительность;'},
        {text:'повышенная возбужденность, тревожный сон;'},
        {text:'тошнота, рвота, дискомфорт и боль в области желудка;'},
        {text:'нарушение стула, повышенное газообразование;'},
        {text:'аллергические реакции на коже, экзема, сыпь, зуд;'},
        {text:'головная боль, учащение сердечного ритма;'},
        {text:'у детей - нарушение зрение, замедленное развитие.'}
      ],
      results:[
        {gen:'BCMO1',polymorphism:'rs11645428',type:'A/G'},
        {gen:'BCMO1',polymorphism:'rs11645428',type:'A/G'},
        {gen:'BCMO1',polymorphism:'rs11645428',type:'A/G'}
      ],
      recommendation:"recommendation b9999"
    },
    {
      name: 'c',
      getName: 'C',

      fullName:'Витамин C',
      color: 'color-third',
      height: '60%',
      img: c,
      chance: 'Обнаружено риски',
      deficitTitle: 'Признаки дефицита витамина C',
      excessTitle: 'Симптомы избытка витамина C',
      description:[
        {text: 'Витамин С, известный как аскорбиновая кислота, представляет собой биологически активное вещество, обладающее антиоксидантными свойствами. Относится к группе водорастворимых витаминов. Не вырабатывается в организме, поэтому для профилактики дефицита требуется употребление содержащих ее продуктов или препаратов (по назначению врача). Усвояемость витамина С зависит от типа гена GSTTI делеционный тип приводит к ее уменьшению, а инсерционный вариант говорит о нормальном поглощении вещества организмом.'},
        {text: 'Витамин С необходим для синтеза коллагеновых волокон, укрепления стенок сосудов, капилляров, улучшения состояния кожи, зубов и опорно-двигательного аппарата. Аскорбиновая кислота способствует укреплению иммунитета, снижению холестерина, а также улучшает усвоение железа, без которого невозможно нормальное кроветворение. Главные источники витамина сладкий перец (216 мг в одном), клубника (96 мг/чашка), ананас (92 мг/чашка), брюссельская капуста (90 мг/чашка), апельсиновый сок (86 мг/чашка), брокколи (82 мг/чашка).'}
      ],
      deficit: [
        {text:'апатия, подавленное состояние, нарушение сна, чувство усталости, раздражительность, истерия, снижение работоспособности;'},
        {text:'склонность к кровотечениям, внезапное возникновение гематом, медленное заживление ран;'},
        {text:'кровоточивость ясен, цинга (выпадение зубов);'},
        {text:'сухая, грубая, неровная и неэластичная кожа;'},
        {text:'истончение и выпадение волос, деформация ногтевых пластин (форма ложки);'},
        {text:'отек лица, стремильное увеличения веса при отсутствии аппетита;'},
        {text:'частые вирусные и бактериальные инфекции;'},
        {text:'гипохромная анемия.'}
      ],
      excess:[
        {text:'изменение цвета кожных покровов (зачастую покраснение), зуд;'},
        {text:'частые головные боли, нарушение координации движений, головокружения;'},
        {text:'образование песка и камней в почках, раздражение мочевого тракта;'},
        {text:'тошнота, рвота, изжога, воспаление пищеварительного тракта, диарея;'},
        {text:'гемолиз эритроцитов (разрушение красных кровяных клеток);'},
        {text:'нарушение синтеза инсулина;'},
        {text:'повышение артериального давления и уменьшение свертываемости крови.'}
      ],
      results:[
        {gen:'BCMO1',polymorphism:'rs11645428',type:'A/G'}
      ],
      recommendation:'Вы обладаете вариантом гена, который эффективно превращает неактивную форму Витамина С в активную. Потребление продуктов, которые имеют более высокое содержание активного витамина А для вас не является обязательным.'
    },
    {
      name: 'e',
      getName: 'E',
      fullName:'Витамин E',
      color: 'color-fourth',
      height: '84%',
      img: e,
      chance: 'Рисков не обнаружено',
      deficitTitle: 'Признаки дефицита витамина E',
      excessTitle: 'Симптомы избытка витамина E',
      description:[
        {text: 'Витамин E (токоферол) - природный жирорастворимый витамин, который отличается хорошими антиоксидантными свойствами. Витамин Е не вырабатывается в организме, поэтому важно регулярно употреблять продукты, в которых он присутствует в достаточном количестве. Высокое содержание токоферола отмечается в подсолнечном масле, миндале, пшеничных отрубях, арахисе, сухофруктах, мясе, молоке, яйцах и морепродуктах.'},
        {text: 'Витамин E замедляет процесс старения клеток, способствует укреплению иммунной системы, предотвращает развитие сердечной недостаточности, вызванной поражением сосудов. Токоферол необходим для нормального функционирования нервной, репродуктивной, дыхательной, кровеносной и эндокринной систем.'}
      ],
      deficit: [
        {text:'физическая слабость из-за дистрофии мышечной ткани, которая распространяется на все виды мышц, включая миокард;'},
        {text:'снижение частоты сердечных сокращений, что характеризуется брадикардией (урежением пульса) и замедлением дыхания;'},
        {text:'нарушение чувствительности, координации движений, расстройство речи, вызванное поражением нервных клеток;'},
        {text:'изменение цвета кожных покровов, наличие буровато-коричневой пигментации в виде пятен на тыльной стороне кистей;'},
        {text:'нарушения со стороны желудочно-кишечного тракта - снижение перистальтики кишечника, что сопровождается частыми запорами;'},
        {text:'у детей отмечается задержка развития, дефицит массы тела, иногда гиповитаминоз сопровождается себореей, рахитом и нарушением зрения;'},
        {text:'снижение потенции и половая дисфункция (у мужчин), нарушение менструального цикла и склонность к природным абортам (у женщин).'}
      ],
      excess:[
        {text:'изменение цвета кожных покровов, сухость и шелушение;'},
        {text:'недомогание, сопровождающееся головной болью;'},
        {text:'тошнота, рвота, частые позывы к дефекации, жидкий стул;'},
        {text:'повышение артериального давления (гипертензия);'},
        {text:'частые кровотечения из-за плохой свертываемости крови;'},
        {text:'дискомфорт, боли и спазмы в эпигастральной области;'},
        {text:'снижение концентрации глюкозы в крови (гипогликемия);'},
        {text:'повышенная утомляемость, раздражительность;'},
        {text:'нарушение сумеречного зрения, повреждения сетчатки;'},
        {text:'ухудшение состояния волос, ломкость ногтевых пластин.'}
      ],
      results:[
        {gen:'BCMO1',polymorphism:'rs11645428',type:'A/G'},
        {gen:'BCMO1',polymorphism:'rs11645428',type:'A/G'},
        {gen:'BCMO1',polymorphism:'rs11645428',type:'A/G'},
        {gen:'BCMO1',polymorphism:'rs11645428',type:'A/G'}
      ],
      recommendation:"recommendation c9999"
    },
    {
      name: 'd',
      getName: 'D',
      fullName:'Витамин D',
      color: 'color-fifth',
      height: '12%',
      img: d,
      chance: 'Рисков не обнаружено',
      deficitTitle: 'Признаки дефицита витамина D',
      excessTitle: 'Симптомы избытка витамина D',
      description:[
        {text:'Витамин D (кальциферолы) - это группа веществ в которую входят холекальциферол (D3) и эргокальциферол (D2). Они синтезируются в коже под воздействием ультрафиолетовых лучей, а также попадают в организм с едой, накапливаясь в жировой ткани. Количество вырабатываемого эпидермисом витамина зависит от генетических особенностей человека и времени пребывания на солнце (норма - 30 минут в сутки). Биохимический предшественник вещества (провитамин D3) содержится в рыбе (100 г лосося закрывает 100% суточной нормы), желтках куриных яиц (20%), молочных продуктах (15%), сливочном масле и грибах (15%).'},
        {text:'Основной функцией кальциферолов является обеспечение нормального образования и роста костей. Витамин D повышает всасывание кальция и фосфора, поступающего в организм из пищи растительного происхождения. Достаточное количество кальциферолов способствует укреплению иммунитета, улучшению мышечно-нервной проводимости, нормализации работы сердечно-сосудистой системы. Также современные исследования говорят о Витамине D как о важном геропротекторе - веществе, которое препятствует старению.'}
      ],
      deficit: [
        {text:'частые инфекции и острые респираторные вирусные заболевания;'},
        {text:'угнетенное психоэмоциональное состояние, раздражительность, сезонные депрессии, чувство хронической усталости;'},
        {text:'проблемы с опорно-двигательным аппаратом - слабые кости, остеопороз, болезненные ощущения в тазобедренном и коленном суставах;'},
        {text:'спазмы, судороги и боли в мышцах, которые носят хронический характер и могут усиливаться даже без сильной физической нагрузки;'},
        {text:'у детей рахит, проблемы с закрытием родничка, прорезыванием зубов, гипергидроз.'}
      ],
      excess:[
        {text:'тошнота и рвота;'},
        {text:'постоянная жажда;'},
        {text:'колебания в весе, изменение аппетита;'},
        {text:'нарушение стула, частое и обильное мочеиспускание;'},
        {text:'судороги, артериальная гипертензия, аритмия;'},
        {text:'тревожный сон, вялость, синдром хронической усталости;'},
        {text:'у детей - частые срыгивания, капризность, сухость кожи, сыпь на теле.'}
      ],
      results:[
        {gen:'BCMO1',polymorphism:'rs11645428',type:'A/G'}
      ],
      recommendation:"recommendation d"
    },
    {
      name: 'b12',
      getName: 'B12',
      fullName:'Витамин B12',
      color: 'color-sixth',
      height: '32%',
      img: b12,
      chance: 'Обнаружено риски',
      deficitTitle: 'Признаки дефицита витамина B12',
      excessTitle: 'Симптомы избытка витамина B12',
      description:[
        {text:'Витамин B12, известный как кобаламин, представляет собой группу биологически активных веществ, в которую входит цианокобаламин, гидроксокобаламин, метилкобаламин, аденозилкобаламин, являющиеся биохимическими предшественниками данного витамина. Человеческий организм самостоятельно синтезирует витамин B12, но этот процесс протекает в толстом кишечнике, из-за чего данное вещество не усваивается. Чтобы избежать гиповитаминоза, необходимо употреблять продукты животного происхождения - мясо и субпродукты, яйца, рыбу, злаки и молочные продукты.'},
        {text:'Основной функцией кобаламина является обеспечение нормального кроветворения. Кроме того, он принимает активное участие в процессе липидного обмена, снижает уровень холестерина в крови, благоприятно влияет на центральную и периферическую нервную систему.'}
      ],
      deficit: [
        {text:'головокружения, сильные головные боли;'},
        {text:'ощущение жжения и покалывания в языке;'},
        {text:'изменение цвета кожных покровов (бледность);'},
        {text:'ухудшение памяти, снижение работоспособности;'},
        {text:'чувство хронической усталости, бессонница;'},
        {text:'быстрая утомляемость при отсутствии нагрузок;'},
        {text:'раздражительность, нервозность, невриты;'},
        {text:'патологические образования в ротовой полости;'},
        {text:'учащение сердечного ритма, слабость в мышцах;'},
        {text:'снижение остроты зрения, двоение в глазах;'},
        {text:'одышка, нарушение координации движений;'},
        {text:'ухудшение аппетита, колебания в весе.'}
      ],
      excess:[
        {text:'раздражительность, нарушение сна, чувство тревожности;'},
        {text:'тошнота, рвота и другие диспепсические проявления;'},
        {text:'сильные головные боли, частые головокружения;'},
        {text:'учащение пульса, боль в груди, повышение давления;'},
        {text:'возможны судороги нижних и верхних конечностей;'},
        {text:'аллергические реакции на кожных покровах (крапивница);'},
        {text:'спазм гортани, затрудненное дыхание, анафилактический шок.'}
      ],
      results:[
        {gen:'BCMO1',polymorphism:'rs11645428',type:'A/G'},
        {gen:'BCMO1',polymorphism:'rs11645428',type:'A/G'},
        {gen:'BCMO1',polymorphism:'rs11645428',type:'A/G'},
        {gen:'BCMO1',polymorphism:'rs11645428',type:'A/G'},
        {gen:'BCMO1',polymorphism:'rs11645428',type:'A/G'}
      ],
      recommendation:"recommendation b12"
    },
    {
      name: 'fe',
      getName: 'Fe',
      fullName:'Железо Fe',
      color: 'color-seventh',
      height: '32%',
      img: fe,
      chance: 'Обнаружено риски',
      deficitTitle: 'Признаки дефицита железа Fe',
      excessTitle: 'Симптомы избытка железа Fe',
      description:[
        {text:'Железо человек получает исключительно из пищи животного и растительного происхождения. Оно бывает гемовым и негемовым. Негемовое железо хуже всасывается, но достаточное количество витамина С улучшает его поглощение организмом. Для поддержания достаточной концентрации элемента необходимо употреблять куриную печень (9,8 мг в 75 г), мясо, яйца, сыр тофу, фасоль (5,8 мг в 175 мг), злаки, яйца, семена тыквы (5,2 мг в 2 ст. л) и кунжута, тимьян, петрушку.'},
        {text:'Железо входит в состав гемоглобина и отвечает за транспортировку кислорода, участвует в синтезе гормонов щитовидной железы, а также способствует формированию и укреплению иммунитета. Кроме того, элемент способствует улучшению холестеринового обмена, разрушению и быстрому выведению токсинов из организма.'}
      ],
      deficit: [
        {text:'общая физическая слабость, быстрая утомляемость;'},
        {text:'дискомфорт в эпигастральной области, проблемы с пищеварением;'},
        {text:'повышенная возбудимость или депрессивное состояние;'},
        {text:'болезненные ощущения в области сердца, нарушение сердечного ритма;'},
        {text:'поверхностное дыхание, частые головные боли;'},
        {text:'ухудшение аппетита, изменение пищевого поведения и вкусов;'},
        {text:'сухость слизистых оболочек ротовой полости;'},
        {text:'частые респираторные вирусные и бактериальные инфекции;'},
        {text:'бледность, шершавость и сухость кожных покровов;'},
        {text:'болезненные раны в уголках рта и глубокие трещины на коже стоп;'},
        {text:'волосы становятся ломкими, тусклыми и начинают выпадать;'},
        {text:'ногтевые пластины деформируются, истончаются.'}
      ],
      excess:[
        {text:'гиперпигментация кожи, вызванная сидерозом (повышенной концентрацией железа в тканях организма);'},
        {text:'частые головные боли, головокружения, хроническая усталость, недомогание, снижение работоспособности;'},
        {text:'нарушение сна, потеря сознания, психоэмоциональная нестабильность, одышка при физических нагрузках;'},
        {text:'нарушения со стороны желудочно-кишечного тракта, сопровождающиеся изжогой, тошнотой, рвотой, запорами или диареей;'},
        {text:'резкое снижение массы тела, потеря аппетита, почечная и печеночная недостаточность, фиброз печени;'},
        {text:'угнетение иммунитета, что существенно увеличивает риск развития различных заболеваний;'},
        {text:'изменение цвета слизистых оболочек - пожелтение неба, склер, языка и бледность кожи.'}
      ],
      results:[
        {gen:'BCMO1',polymorphism:'rs11645428',type:'A/G'}
      ],
      recommendation:"recommendation Fe"
    },
    {
      name: 'omega',
      getName: 'Омега-3',
      fullName:'Омега-3',
      color: 'color-eighth',
      height: '75%',
      img: omega,
      chance: 'Обнаружено риски',
      deficitTitle: 'Признаки дефицита',
      excessTitle: 'Симптомы избытка',
      description:[
        {text:'Омега-3 - это группа полиненасыщенных жирных кислот (ПНЖК), в которую входит альфа-линоленовая кислота (АЛК), эйкозапентаеновая кислота (ЭПК) и докозагексаеновая кислота (ДГК). Эти химические соединения, в отличие от насыщенных кислот, не вызывают ожирения, сахарного диабета, метаболического синдрома и воспаления на внутренних стенках артерий.'},
        {text:'ПНЖК способствуют укреплению стенок сосудов, снижают уровень холестерина, нормализуют давление, улучшают вязкость крови. Омега-3 препятствует развитию атеросклероза, остеопороза, артроза, ревматоидного артрита, а также укрепляет иммунитет, благоприятно влияет на кожу и повышает работоспособность. Важным открытием стало влияние уровня ненасыщенных жирных кислот на уровень триглицеридов, что обуславливается геном NOS3. Высокий уровень последних способствует развитию болезней сердца и сосудов.'},
        {text:'Организм человека не может самостоятельно синтезировать эти химические соединения, поэтому рацион должен включать продукты, обогащенные Омега-3. Основными пищевыми источниками данного вещества являются размолотые семена льна, чиа, жирная морская рыба, рыбий жир и морепродукты, мясо диких животных, масло горчицы, льна, рыжика, рапса, шпинат. В среднем, 100 г рыбы содержит от 0,25 до 2 г Омега-3.'}
      ],
      deficit: [
        {text:'сухая кожа, перхоть, шелушение, нарушение нормальной работы сальных желез;'},
        {text:'мышечная слабость, повышенная утомляемость, боль в суставах, чувство разбитости;'},
        {text:'снижение работоспособности, ухудшение памяти, невозможность сконцентрировать внимание, вялость или гиперактивность (у детей);'},
        {text:'проблемы со зрением - сухость в глазах, снижение остроты;'},
        {text:'быстрый прогресс атеросклероза;'},
        {text:'снижение иммунитета, нарушение нормальной функции мочеполовой, нервной, эндокринной и других систем организма.'}
      ],
      excess:[
        {text:'повышение уровня сахара в крови;'},
        {text:'склонность к кровотечениям;'},
        {text:'изжога, диарея;'},
        {text:'понижение артериального давления.'}
      ],
      results:[
        {gen:'BCMO1',polymorphism:'rs11645428',type:'A/G'},
        {gen:'BCMO1',polymorphism:'rs11645428',type:'A/G'}
      ],
      recommendation:"recommendation omega"
    },
    {
      name: 'antioxidants',
      getName: 'Антиоксиданты',
      fullName:'Антиоксиданты',
      color: 'color-ninth',
      height: '75%',
      img: antioxidants,
      chance: 'Обнаружено риски',
      deficitTitle: 'Признаки дефицита антиоксидантов',
      excessTitle: 'Симптомы избытка антиоксидантов',
      description:[
        {text:'Антиоксиданты - это молекулы, которые позволяют предотвратить цепные реакции, запускаемые свободными радикалами (большими молекулами со свободными электронами, готовыми образовать химическую связь с абсолютно любым органическим веществом в теле человека). К перечню основных антиоксидантов можно отнести аскорбиновую кислоту, глутатион, липоевую и мочевую кислоту, ретинол, токоферол, коэнзим Q10, ликопин, рибофлавин, танин, флавоноиды, антоцианы.'},
        {text:'Многочисленные клинические исследования показали, что нехватка антиоксидантов способна вызвать серьезные последствия. Риск развития заболеваний сердечно-сосудистой и пищеварительной систем, опорно-двигательного аппарата и органов зрения при их дефиците значительно возрастает.'},
        {text:'Антиоксиданты вырабатываются в организме, но с возрастом их запасы приходится пополнять, для чего необходимо скорректировать свой рацион. Естественными антиоксидантами являются ягоды (черника, ежевика, клюква, малина), овощи (свекла, артишоки, спаржа, брокколи, красная капуста, репчатый лук и сладкий перец), а также чернослив, чай и горький шоколад.'}
      ],
      deficit: [
        {text:'резкое ухудшение состояние кожи - гравитационный птоз тканей, потеря эластичности и упругости эпидермиса;'},
        {text:'повышенный уровень холестерина, который вызывает сердечно-сосудистые заболевания (атеросклероз, инсульт, инфаркт);'},
        {text:'частые респираторные вирусные и бактериальные инфекции на фоне ослабленного иммунитета;'},
        {text:'волосы и ногтевые пластины истончаются, ломаются, теряют здоровый вид и плохо восстанавливаются;'},
        {text:'повышенное содержание сахара в крови, эндокринные заболевания, бронхолегочные патологии;'},
        {text:'склонность к офтальмологическим заболеваниям - снижение остроты зрения, частые воспалительные процессы.'}
      ],
      excess:[
        {text:'расстройства со стороны системы пищеварения;'},
        {text:'склонность к кровотечениям, частые головные боли;'},
        {text:'нарушение функции мочевыделительной системы;'},
        {text:'увеличение печени и селезенки, тошнота, рвота;'},
        {text:'длительное течение инфекционных заболеваний.'}
      ],
      results:[
        {gen:'BCMO1',polymorphism:'rs11645428',type:'A/G'}
      ],
      recommendation:"recommendation Antioxidants"
    }
];