import React from 'react'

export default function Logo({iconClass}) {
  return (
    <svg className={iconClass}
         xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 0 77 83">
      <path fill="#FFFFFF" d="M45.2,76c-0.7,0-1.3,0.6-1.3,1.2c0,0.7,0.6,1.2,1.3,1.2c0.7,0,1.2-0.6,1.2-1.2C46.4,76.5,45.9,76,45.2,76z"/>
      <ellipse fill="#FFFFFF" cx="34.3" cy="77.2" rx="1.2" ry="1.2"/>
      <path fill="#FFFFFF" d="M39.8,79.2c-0.9,0-1.7,0.7-1.7,1.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.7-0.7,1.7-1.6C41.5,80,40.7,79.2,39.8,79.2z
		"/>
      <path fill="#FFFFFF" d="M36.6,78.3c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9S37.1,78.3,36.6,78.3z"/>
      <path fill="#FFFFFF" d="M42.9,78.3c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9S43.4,78.3,42.9,78.3z"/>
      <path fill="#FFFFFF" d="M41.7,54.9c0-1-0.8-1.9-1.9-1.9c-1.1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9C40.8,56.8,41.7,56,41.7,54.9z"/>
      <path fill="#FFFFFF" d="M41.1,57.3c0,0.9,0.8,1.7,1.7,1.7c0.9,0,1.7-0.7,1.7-1.7c0-0.9-0.7-1.7-1.7-1.7C41.9,55.6,41.1,56.3,41.1,57.3
		z"/>
      <path fill="#FFFFFF" d="M35,52.4c0,0.9,0.8,1.7,1.7,1.7c0.9,0,1.7-0.7,1.7-1.7s-0.8-1.7-1.7-1.7C35.8,50.8,35,51.5,35,52.4z"/>
      <path fill="#FFFFFF" d="M34.2,48.2c-0.9,0-1.6,0.7-1.6,1.5c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.5S35.1,48.2,34.2,48.2z"/>
      <path fill="#FFFFFF" d="M48.2,64.9c-0.8,0-1.4,0.6-1.5,1.3l-7.3-0.1c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2l7.3,0.1
		c0.1,0.8,0.7,1.4,1.5,1.4c0.8,0,1.5-0.6,1.5-1.5C49.7,65.6,49,64.9,48.2,64.9z"/>
      <path fill="#FFFFFF" d="M47.1,68.5c-0.6,0-1.1,0.4-1.3,0.9l-6.2,0c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2l6.2,0c0,0,0,0,0,0.1
		c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3C48.5,69.1,47.9,68.5,47.1,68.5z"/>
      <path fill="#FFFFFF" d="M42.6,73.6c-0.7,0-1.2,0.6-1.2,1.2c0,0.7,0.6,1.2,1.2,1.2c0.7,0,1.2-0.6,1.2-1.2
		C43.9,74.2,43.3,73.6,42.6,73.6z"/>
      <path fill="#FFFFFF" d="M39.8,75.1c-0.9,0-1.7,0.7-1.7,1.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.7-0.7,1.7-1.6
		C41.5,75.9,40.7,75.1,39.8,75.1z"/>
      <path fill="#FFFFFF" d="M34.5,71.3c-0.7,0-1.2,0.5-1.2,1.1c0,0.6,0.5,1.1,1.1,1.2c0.6,0,1.1-0.4,1.2-1l3.4,0c0.1,0,0.2-0.1,0.2-0.2
		c0-0.1-0.1-0.2-0.2-0.2l-3.4,0C35.5,71.7,35.1,71.3,34.5,71.3z"/>
      <path fill="#FFFFFF" d="M31.4,67.2c0.5,0,0.9-0.4,1-0.8l5.6,0c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2l-5.6,0
		c-0.1-0.5-0.5-0.8-1-0.8c-0.5,0-1,0.4-1,1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0C30.4,66.8,30.9,67.2,31.4,67.2z"/>
      <path fill="#FFFFFF" d="M31.2,62.9c0,0.5,0.4,0.9,0.9,0.9c0.4,0,0.8-0.3,0.9-0.7l5.5,0c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
		l-5.5,0C33,62.4,32.6,62,32.2,62C31.7,62,31.2,62.4,31.2,62.9z"/>
      <path fill="#FFFFFF" d="M45.4,49.1c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8
		C46.3,49.4,45.9,49.1,45.4,49.1z"/>
      <path fill="#FFFFFF" d="M34.2,58.9c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8c0.4,0,0.7-0.3,0.8-0.6h4c0.1,0,0.1-0.1,0.1-0.1
		s-0.1-0.1-0.1-0.1h-3.9C35,59.3,34.7,58.9,34.2,58.9z"/>
      <path fill="#FFFFFF" d="M43.4,52.5c0-0.4-0.3-0.7-0.8-0.7c-0.4,0-0.8,0.3-0.8,0.7c0,0.4,0.3,0.7,0.8,0.7
		C43.1,53.3,43.4,52.9,43.4,52.5z"/>
      <path fill="#FFFFFF" d="M36.8,56.3c-0.4,0-0.8,0.3-0.8,0.7c0,0.4,0.3,0.7,0.8,0.7c0.4,0,0.8-0.3,0.8-0.7
		C37.6,56.7,37.3,56.3,36.8,56.3z"/>
      <path fill="#FFFFFF" d="M39.9,63.2l5.9,0c0.1,0.7,0.7,1.2,1.5,1.2c0.8,0,1.5-0.6,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5
		c-0.8,0-1.5,0.6-1.5,1.4l-5.9,0c-0.1,0-0.2,0.1-0.2,0.2C39.8,63.1,39.8,63.2,39.9,63.2z"/>
      <path fill="#FFFFFF" d="M43.7,72.6c0,0.6,0.5,1,1.1,1c0.7,0,1.2-0.5,1.2-1.1c0-0.6-0.5-1.1-1.1-1.2c-0.6,0-1,0.4-1.1,0.9l-3.2,0
		c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2L43.7,72.6z"/>
      <path fill="#FFFFFF" d="M38.3,69.7c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2l-5.2,0c-0.1-0.4-0.5-0.7-0.9-0.7c-0.5,0-1,0.4-1,1
		s0.4,1,1,1c0.5,0,1-0.4,1-1L38.3,69.7z"/>
      <path fill="#FFFFFF" d="M40.1,59.8c0,0.1,0.1,0.1,0.1,0.1h3.5c0,0.8,0.7,1.5,1.6,1.5c0.9,0,1.6-0.7,1.6-1.5c0-0.8-0.7-1.6-1.6-1.6
		c-0.8,0-1.5,0.6-1.6,1.3h-3.6C40.1,59.7,40.1,59.7,40.1,59.8z"/>
      <path fill="#FFFFFF" d="M36.7,74.3c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9C37.6,74.6,37.2,74.3,36.7,74.3z"/>
      <path fill="#FFFFFF" d="M40,4.3c1.1,0,1.9-0.8,1.9-1.9c0-1-0.9-1.9-1.9-1.9S38,1.3,38.1,2.4C38.1,3.4,38.9,4.3,40,4.3z"/>
      <path fill="#FFFFFF" d="M30.9,9.8c0.8,0.5,1.8,0.3,2.3-0.4c0.5-0.7,0.3-1.8-0.4-2.3C32,6.5,31,6.7,30.5,7.5C30,8.2,30.2,9.3,30.9,9.8z
		"/>
      <path fill="#FFFFFF" d="M35.3,7.1c0.9,0,1.7-0.7,1.6-1.6c0-0.9-0.8-1.6-1.7-1.6c-0.9,0-1.7,0.7-1.6,1.6C33.6,6.4,34.4,7.1,35.3,7.1z"
      />
      <path fill="#FFFFFF" d="M29.2,13.6c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5C27.7,13,28.4,13.6,29.2,13.6z
		"/>
      <path fill="#FFFFFF" d="M28.7,18.1c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5
		C27.2,17.5,27.9,18.1,28.7,18.1z"/>
      <path fill="#FFFFFF" d="M31.6,20.6c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5C31,22,31.6,21.4,31.6,20.6z"
      />
      <path fill="#FFFFFF" d="M32,25.6c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-0.8,0-1.4,0.6-1.3,1.3C30.6,25.1,31.2,25.6,32,25.6z"
      />
      <path fill="#FFFFFF" d="M34.6,28.2c0.7,0,1.2-0.5,1.1-1.2c0-0.6-0.5-1.2-1.2-1.1c-0.7,0-1.1,0.5-1.1,1.2S34,28.3,34.6,28.2z"/>
      <path fill="#FFFFFF" d="M35.5,29.4c0,0.7,0.6,1.2,1.2,1.2c0.7,0,1.2-0.6,1.2-1.2c0-0.7-0.6-1.2-1.2-1.2C36.1,28.2,35.5,28.7,35.5,29.4
		z"/>
      <path fill="#FFFFFF" d="M42.6,32.2c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.6,1.2,1.2,1.2c0.7,0,1.2-0.6,1.2-1.2S43.3,32.2,42.6,32.2z"/>
      <path fill="#FFFFFF" d="M39.6,32.9c0.9,0,1.7-0.7,1.6-1.6c0-0.9-0.8-1.6-1.7-1.6c-0.9,0-1.6,0.7-1.6,1.6S38.7,32.9,39.6,32.9z"/>
      <path fill="#FFFFFF" d="M44.1,27c0,0.6,0.5,1.2,1.2,1.1c0.7,0,1.2-0.5,1.1-1.2c0-0.6-0.5-1.2-1.2-1.1C44.6,25.9,44.1,26.4,44.1,27z"/>
      <path fill="#FFFFFF" d="M47.8,25.1c0.5,0,1-0.4,1-1s-0.4-1-1-1c-0.5,0-1,0.4-1,1C46.8,24.7,47.3,25.1,47.8,25.1z"/>
      <path fill="#FFFFFF" d="M49.6,19.7c-0.5,0-1,0.4-1,1s0.4,1,1,1c0.5,0,1-0.4,1-1S50.2,19.7,49.6,19.7z"/>
      <path fill="#FFFFFF" d="M51.3,17.5c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9C50.9,18.4,51.3,18,51.3,17.5z
		"/>
      <path fill="#FFFFFF" d="M49.4,14.3c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8C49.8,13.5,49.4,13.8,49.4,14.3
		z"/>
      <path fill="#FFFFFF" d="M49.6,12.4c0.4,0,0.8-0.3,0.8-0.7c0-0.4-0.3-0.7-0.8-0.7c-0.4,0-0.8,0.3-0.8,0.7C48.8,12,49.1,12.4,49.6,12.4z
		"/>
      <path fill="#FFFFFF" d="M48.2,9.9c0.4,0,0.8-0.3,0.8-0.7s-0.3-0.7-0.8-0.7c-0.4,0-0.8,0.4-0.8,0.7C47.4,9.5,47.8,9.9,48.2,9.9z"/>
      <path fill="#FFFFFF" d="M46.2,7.6c0.4,0,0.8-0.3,0.8-0.7s-0.3-0.7-0.8-0.7c-0.4,0-0.8,0.3-0.8,0.7C45.5,7.2,45.8,7.6,46.2,7.6z"/>
      <path fill="#FFFFFF" d="M43.7,5.6c0.4,0,0.8-0.3,0.8-0.7s-0.3-0.7-0.8-0.7c-0.4,0-0.8,0.3-0.8,0.7S43.3,5.6,43.7,5.6z"/>
      <path fill="#FFFFFF" d="M44.9,35.1c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2S45.6,35.1,44.9,35.1z"/>
      <path fill="#FFFFFF" d="M34.6,37.4c0.7,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2c-0.7,0-1.2,0.5-1.2,1.2
		C33.4,36.9,33.9,37.4,34.6,37.4z"/>
      <path fill="#FFFFFF" d="M37.8,33.6c0-0.5-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8S37.8,34.1,37.8,33.6z"/>
      <path fill="#FFFFFF" d="M42.7,30.5c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9
		C41.8,30.1,42.2,30.5,42.7,30.5z"/>
      <path fill="#FFFFFF" d="M33.1,43c0-0.9-0.8-1.7-1.7-1.7c-1,0-1.7,0.8-1.7,1.7s0.8,1.7,1.7,1.7C32.3,44.7,33.1,43.9,33.1,43z"/>
      <path fill="#FFFFFF" d="M54.1,38.5c-1.3,0-2.4,0.4-3.4,1.3c-0.9,0.8-1.4,1.9-1.4,3.2s0.5,2.4,1.4,3.2c0.9,0.9,2.1,1.3,3.4,1.3
		c1.3,0,2.4-0.4,3.4-1.3c0.9-0.8,1.4-1.9,1.4-3.2s-0.4-2.4-1.4-3.2C56.5,39,55.4,38.5,54.1,38.5z M56.2,45.1
		c-0.6,0.6-1.3,0.9-2.1,0.9c-0.8,0-1.5-0.3-2.1-0.9C51.3,44.6,51,43.9,51,43c0-0.8,0.3-1.5,0.9-2.1c0.6-0.6,1.3-0.9,2.1-0.9
		c0.8,0,1.5,0.3,2.1,0.9c0.6,0.6,0.9,1.3,0.9,2.1C57.1,43.9,56.8,44.6,56.2,45.1z"/>
      <path fill="#FFFFFF" d="M63.9,42.3l-1.4-0.4c-0.3-0.1-0.5-0.2-0.7-0.4s-0.2-0.3-0.2-0.5c0-0.2,0.1-0.3,0.2-0.5
		c0.1-0.1,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.2,0.7-0.2c0.7,0,1.3,0.2,1.9,0.6c0.2,0.1,0.3,0.1,0.5,0c0.2-0.1,0.3-0.2,0.5-0.4
		c0.1-0.2,0.1-0.4,0.1-0.6c-0.1-0.2-0.2-0.3-0.3-0.4c-0.8-0.5-1.7-0.8-2.7-0.8c-0.9,0-1.6,0.3-2.2,0.7c-0.6,0.5-0.9,1.1-0.9,1.8
		c0,0.6,0.2,1.1,0.6,1.5c0.4,0.4,0.9,0.7,1.6,0.9l1.5,0.4c0.4,0.1,0.7,0.3,0.9,0.5c0.2,0.3,0.2,0.5,0.1,0.8
		c-0.1,0.3-0.3,0.5-0.6,0.7c-0.3,0.2-0.6,0.3-1,0.3c-0.8,0-1.5-0.2-2.1-0.7c-0.2-0.1-0.3-0.1-0.5-0.1c-0.2,0.1-0.4,0.2-0.5,0.3
		c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.2,0.1,0.4,0.3,0.5l0,0c0.9,0.6,1.8,0.9,3,0.9c0.9,0,1.7-0.3,2.4-0.8c0.7-0.5,1-1.2,1-1.9
		c0-0.6-0.2-1.2-0.7-1.7C65.2,42.8,64.6,42.5,63.9,42.3z"/>
      <path fill="#FFFFFF" d="M68.4,38.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.3,0.3-0.3,0.5v7.5c0,0.2,0.1,0.4,0.3,0.5
		c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.3,0.3-0.5v-7.5c0-0.2-0.1-0.4-0.3-0.5
		C68.8,38.7,68.6,38.6,68.4,38.6z"/>
      <path fill="#FFFFFF" d="M76.3,43.2c-0.4-0.5-1-0.8-1.8-0.9l-1.4-0.4c-0.3-0.1-0.5-0.2-0.7-0.4c-0.2-0.2-0.2-0.3-0.2-0.5
		c0-0.2,0.1-0.3,0.2-0.5c0.1-0.1,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.2,0.7-0.2c0.7,0,1.3,0.2,1.9,0.6c0.2,0.1,0.3,0.1,0.5,0
		c0.2-0.1,0.3-0.2,0.5-0.4c0.1-0.2,0.1-0.4,0.1-0.6c-0.1-0.2-0.2-0.3-0.3-0.4c-0.8-0.5-1.7-0.8-2.7-0.8c-0.9,0-1.6,0.3-2.2,0.7
		c-0.6,0.5-0.9,1.1-0.9,1.8c0,0.6,0.2,1.1,0.6,1.5c0.4,0.4,0.9,0.7,1.6,0.9l1.5,0.4c0.4,0.1,0.7,0.3,0.9,0.5
		c0.2,0.3,0.2,0.5,0.1,0.8c-0.1,0.3-0.3,0.5-0.6,0.7c-0.3,0.2-0.6,0.3-1,0.3c-0.8,0-1.5-0.2-2.1-0.7c-0.2-0.1-0.3-0.1-0.5-0.1
		c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.2,0.1,0.4,0.3,0.5l0,0c0.9,0.6,1.8,0.9,3,0.9c0.9,0,1.7-0.3,2.4-0.8
		c0.7-0.5,1-1.2,1-1.9C77,44.2,76.8,43.7,76.3,43.2z"/>
      <path fill="#FFFFFF" d="M7.4,42.2H5.3c-0.2,0-0.4,0.1-0.5,0.2S4.6,42.8,4.6,43c0,0.2,0.1,0.4,0.2,0.5c0.2,0.2,0.3,0.2,0.5,0.2h1.3v1.5
		C6.1,45.7,5.5,46,4.7,46c-0.8,0-1.5-0.3-2.1-0.9C2,44.5,1.7,43.8,1.7,43c0-0.8,0.3-1.5,0.9-2.1C3.2,40.3,3.9,40,4.7,40
		c0.8,0,1.5,0.3,2,0.7C6.9,40.9,7,41,7.2,41c0.2,0,0.4-0.1,0.5-0.3C7.9,40.6,8,40.4,8,40.2C8,40,8,39.8,7.9,39.7
		c-0.9-0.8-2-1.2-3.1-1.2c-1.3,0-2.4,0.4-3.4,1.3C0.5,40.6,0,41.7,0,43c0,1.3,0.5,2.4,1.4,3.2c0.9,0.9,2.1,1.3,3.4,1.3
		s2.4-0.4,3.3-1.4c0.1-0.1,0.2-0.3,0.2-0.5v-2.8c0-0.2-0.1-0.4-0.3-0.5C7.8,42.3,7.6,42.2,7.4,42.2z"/>
      <path fill="#FFFFFF" d="M10.4,41.6c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.3,0.3-0.5v-0.6h4.2c0.2,0,0.4-0.1,0.5-0.2
		c0.1-0.2,0.2-0.4,0.2-0.6c0-0.2-0.1-0.4-0.2-0.6s-0.3-0.2-0.5-0.2h-5.1c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.3,0.3-0.3,0.5v1.5
		c0,0.2,0.1,0.4,0.2,0.5C10,41.5,10.2,41.6,10.4,41.6z"/>
      <path fill="#FFFFFF" d="M15.5,45.8h-4.2v-2h3.4c0.2,0,0.4-0.1,0.5-0.2s0.2-0.4,0.2-0.6s-0.1-0.4-0.2-0.6s-0.3-0.2-0.5-0.2h-4.2
		c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.3,0.3-0.3,0.5v3.8c0,0.2,0.1,0.4,0.3,0.5c0.2,0.1,0.4,0.2,0.6,0.2h5.1c0.2,0,0.4-0.1,0.5-0.2
		s0.2-0.4,0.2-0.6c0-0.2-0.1-0.4-0.2-0.6C15.9,45.9,15.7,45.8,15.5,45.8z"/>
      <path fill="#FFFFFF" d="M23.4,38.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.3,0.3-0.3,0.5v5.2h0l-3.4-5.4c-0.2-0.3-0.5-0.5-0.9-0.5
		c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.2-0.3,0.3-0.3,0.5v7.4c0,0.2,0.1,0.4,0.3,0.5c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2
		c0.2-0.1,0.3-0.3,0.3-0.5v-5.2h0l3.3,5.4c0.1,0.3,0.4,0.5,0.9,0.5c0.3,0,0.5-0.1,0.7-0.2c0.2-0.2,0.3-0.3,0.3-0.5v-7.4
		c0-0.2-0.1-0.4-0.3-0.5C23.8,38.7,23.6,38.6,23.4,38.6z"/>
      <path fill="#FFFFFF" d="M34.9,46.3c1-0.9,1.5-2,1.5-3.4c0-1.4-0.5-2.5-1.5-3.4c-1-0.9-2.1-1.4-3.5-1.4c-1.4,0-2.6,0.4-3.5,1.4
		c-1,0.9-1.4,2-1.4,3.4c0,1.4,0.5,2.5,1.4,3.4s2.1,1.4,3.5,1.4C32.8,47.7,33.9,47.2,34.9,46.3z M28.3,42.9c0-0.8,0.3-1.6,0.9-2.2
		c0.6-0.6,1.4-0.9,2.2-0.9c0.9,0,1.6,0.3,2.2,0.9c0.6,0.6,0.9,1.3,0.9,2.2s-0.3,1.6-0.9,2.2C33,45.7,32.3,46,31.4,46
		c-0.9,0-1.6-0.3-2.2-0.9C28.6,44.5,28.3,43.8,28.3,42.9z"/>
      <path fill="#FFFFFF" d="M47,42.9c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.2-0.3,0.2-0.5v-2.9c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.4-0.2-0.6-0.2
		c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.2,0.3-0.2,0.5v2.9c0,0.2,0.1,0.4,0.2,0.5C46.6,42.9,46.8,42.9,47,42.9z"/>
      <path fill="#FFFFFF" d="M46.2,46.7c0,0.2,0.1,0.4,0.2,0.5c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.2-0.3,0.2-0.5V44
		c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.2,0.3-0.2,0.5V46.7z"/>
      <path fill="#FFFFFF" d="M37.8,46.7c0,0.2,0.1,0.4,0.3,0.5c0.2,0.1,0.4,0.2,0.6,0.2H42c0.8,0,1.5-0.3,2-0.7c0.5-0.5,0.8-1.1,0.8-1.8
		s-0.3-1.4-0.8-1.8c-0.5-0.5-1.2-0.7-2-0.7h-1.2c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.4-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6
		c0.2,0.2,0.3,0.2,0.5,0.2H42c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.5,0.3-0.8,0.3h-2.5
		v-5.5h2.3c0.3,0,0.4,0.1,0.6,0.3c0.2,0.2,0.3,0.4,0.3,0.6c0,0.4,0.2,0.6,0.6,0.8c0.2,0.1,0.4,0.1,0.7,0c0.2-0.1,0.3-0.2,0.4-0.4
		c0.1-0.1,0.1-0.3,0.1-0.5c0-0.7-0.2-1.2-0.8-1.7c-0.5-0.4-1.1-0.7-1.9-0.7h-3.1c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.3,0.3-0.3,0.5
		V46.7z"/>
    </svg>
  )
}