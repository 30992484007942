const initialState = {
    customers: [],
    loading: true,
    error: null,
    status:[],
    loadingS: true,
    errorS: null,
    customer: [],
    loadingC: true,
    errorC: null,
    statusCustomer: [],
    loadingStatus: true,
    errorStatus: null,
    customerMainData: [],
    loadingMainData: true,
    errorMainData: null,
    subscriptionSuccess: null,
    customerFoodLk: [],
    loadingFoodLk:true,
    errorFoodLk: null,
    customerDiseaseLk: [],
    loadingDiseaseLk:true,
    errorDiseaseLk: null,
    customerAncestralLk: [],
    loadingAncestralLk:true,
    errorAncestralLk: null,
    customerPharmacogeneticLk: [],
    loadingPharmacogeneticLk:true,
    errorPharmacogeneticLk: null,
    customerPsychogeneticLk: [],
    loadingPsychogeneticLk:true,
    errorPsychogeneticLk: null
};

const reducers = (state = initialState, action) => {

    switch (action.type) {
        case 'CUSTOMERS_REQUEST':
            return {
                ...state,
                customers: [],
                loading: true,
                error: null,

            };

        case 'CUSTOMERS_SUCCESS':
            return {
                ...state,
                customers: action.payload,
                loading: false,
                error: null,

            };

        case 'CUSTOMERS_FAILURE':
            return {
                ...state,
                customers: [],
                loading: false,
                error: action.payload,

            };
        case 'STATUS_REQUEST':
            return {
                ...state,
                loadingS: true,
                errorS: null,
                status: []
            };

        case 'STATUS_SUCCESS':
            return {
                ...state,
                loadingS: false,
                errorS: null,
                status: action.payload,
            };

        case 'STATUS_FAILURE':
            return {
                ...state,
                loadings: false,
                errorS: action.payload,
                status: []
            };
        case 'CUSTOMER_REQUEST':
            return {
                ...state,
                loadingC: true,
                errorC: null,
                customer: []
            };

        case 'CUSTOMER_SUCCESS':
            return {
                ...state,
                loadingC: false,
                errorC: null,
                customer: action.payload,
            };

        case 'CUSTOMER_FAILURE':
            return {
                ...state,
                loadingC: false,
                errorC: action.payload,
                customer: []
            };
        case 'CUSTOMER_STATUS_REQUEST':
            return {
                ...state,
                loadingStatus: true,
                errorStatus: null,
                statusCustomer: []
            };

        case 'CUSTOMER_STATUS_SUCCESS':
            return {
                ...state,
                loadingStatus: false,
                errorStatus: null,
                statusCustomer: action.payload,
            };

        case 'CUSTOMER_STATUS_FAILURE':
            return {
                ...state,
                loadingStatus: false,
                errorStatus: action.payload,
                statusCustomer: []
            };
        case 'CUSTOMER_MAIN_DATA_REQUEST':
            return {
                ...state,
                loadingMainData: true,
                errorMainData: null,
                customerMainData: []
            };

        case 'CUSTOMER_MAIN_DATA_SUCCESS':
            return {
                ...state,
                loadingMainData: false,
                errorMainData: null,
                customerMainData: action.payload,
            };

        case 'CUSTOMER_MAIN_DATA_FAILURE':
            return {
                ...state,
                loadingMainData: false,
                errorMainData: action.payload,
                customerMainData: []
            };

        case 'LK_FOOD_REQUEST':
            return {
                ...state,
                loadingFoodLk:true,
                errorFoodLk: null,
                customerFoodLk: []
            };

        case 'LK_FOOD_SUCCESS':
            return {
                ...state,
                loadingFoodLk: false,
                errorFoodLk: null,
                customerFoodLk: action.payload,
            };

        case 'LK_FOOD_FAILURE':
            return {
                ...state,
                loadingFoodLk: false,
                errorFoodLk: action.payload,
                customerFoodLk: []
            };

        case 'LK_DISEASE_REQUEST':
            return {
                ...state,
                loadingDiseaseLk:true,
                errorDiseaseLk: null,
                customerDiseaseLk: []
            };

        case 'LK_DISEASE_SUCCESS':
            return {
                ...state,
                loadingDiseaseLk: false,
                errorDiseaseLk: null,
                customerDiseaseLk: action.payload,
            };

        case 'LK_DISEASE_FAILURE':
            return {
                ...state,
                loadingDiseaseLk: false,
                errorDiseaseLk: action.payload,
                customerDiseaseLk: []
            };
        case 'LK_ANCESTRAL_REQUEST':
            return {
                ...state,
                loadingAncestralLk:true,
                errorAncestralLk: null,
                customerAncestralLk: []
            };

        case 'LK_ANCESTRAL_SUCCESS':
            return {
                ...state,
                loadingAncestralLk: false,
                errorAncestralLk: null,
                customerAncestralLk: action.payload,
            };

        case 'LK_ANCESTRAL_FAILURE':
            return {
                ...state,
                loadingAncestralLk: false,
                errorAncestralLk: action.payload,
                customerAncestralLk: []
            };
        case 'LK_PHARMACOGENETiC_REQUEST':
            return {
                ...state,
                loadingPharmacogeneticLk:true,
                errorPharmacogeneticLk: null,
                customerPharmacogeneticLk: []
            };

        case 'LK_PHARMACOGENETiC_SUCCESS':
            return {
                ...state,
                loadingPharmacogeneticLk: false,
                errorPharmacogeneticLk: null,
                customerPharmacogeneticLk: action.payload,
            };

        case 'LK_PHARMACOGENETiC_FAILURE':
            return {
                ...state,
                loadingPharmacogeneticLk: false,
                errorPharmacogeneticLk: action.payload,
                customerPharmacogeneticLk: []
            };
            case 'LK_PSYCHOGENETiC_REQUEST':
            return {
                ...state,
                loadingPsychogeneticLk:true,
                errorPsychogeneticLk: null,
                customerPsychogeneticLk: []
            };

        case 'LK_PSYCHOGENETiC_SUCCESS':
            return {
                ...state,
                loadingPsychogeneticLk: false,
                errorPsychogeneticLk: null,
                customerPsychogeneticLk: action.payload,
            };

        case 'LK_PSYCHOGENETiC_FAILURE':
            return {
                ...state,
                loadingPsychogeneticLk: false,
                errorPsychogeneticLk: action.payload,
                customerPsychogeneticLk: []
            };

        default:
            return state;
    }
};

export default reducers;
