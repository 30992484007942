import React, {Component}  from 'react';
import  compose  from '../../../../utils/compose';
import { Modal } from 'react-bootstrap';
import '../../login-page/modal-customer.sass'
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import { withRouter } from "react-router-dom";
import {withTranslation} from "react-i18next";

class ModalEditMail extends Component{
    state = {
        newEmail: '',
        error: ''
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = event => {
        event.preventDefault()
        const { genobiosisService, customer, onClose } = this.props
        // const id = customer.id
            genobiosisService.updateCustomerData({
                    email: this.state.newEmail,
                    phone: customer.phone

                }
            )
                .then(()=>{
                    onClose()
                })
                .catch(err => console.log(err))

    }
    render() {
        const { t } = this.props
        return (
            <Modal show={this.props.open} onHide={this.props.onClose} centered size="xl">
                <div className="menu-form menu-one">
                    <Modal.Header closeButton/>
                    <form className="form-consult needs-validation" noValidate onSubmit={this.handleSubmit}>
                        <h5>{t(`Смена e-mail`)}</h5>
                        <label htmlFor="inputNewEmail" className="sr-only">New Email</label>
                        <input type="email" id="inputNewEmail" name='newEmail' className="form-control"
                               placeholder={t(`Введите ваш новый e-mail`)}
                               required autoFocus
                               value={this.state.newEmail}
                               onChange={this.handleChange}
                        />


                        <button className="button" type="submit">{t(`Сменить e-mail`)}</button>
                    </form>
                    {this.state.error && <p>Invalid credentials</p>}
                </div>
            </Modal>
        )
    }
}
export default compose(withTranslation(), withGenobiosisService())(withRouter(ModalEditMail))