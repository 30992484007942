import React, {Component} from 'react';
import {Tabs, Tab} from 'react-bootstrap'

// import MediaQuery from 'react-responsive'
// import BtnToTop from './btn-to-top/btn-to-top';

import '../customer-page.sass';
import './disease-risks.sass';
import MultiDiseaseWrapped from './multi-disease/multi-disease-wrapped';
import InfoBlock from '../info-block/info-block'
import AcestralRiskWrapped from './ancestral-risk/ancestral-risk-wrapped'
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import compose from "../../../../utils/compose";
import {fetchCustomerLKDisease} from "../../../../actions";
import {connect} from "react-redux";
import Spinner from '../../../icons/Spinner';
import ErrorIndicator from '../../../error-indicator';

class DiseaseRisk extends Component{
    componentDidMount(){
        this.props.fetchCustomerLKDisease()
    }

    render(){
        const { customerDiseaseLk, loadingDiseaseLk, errorDiseaseLk } = this.props;
        if (loadingDiseaseLk) {
            return <Spinner />;
        }

        if (errorDiseaseLk) {
            return <ErrorIndicator />;
        }
		
		return(
			
			<section className="nutrition-page disease-page">
				<InfoBlock />
				<div className="navigation-content">
					<Tabs id="uncontrolled-tab-example" className="utrition-page-tab">
						<Tab eventKey="multi-disease" title="Мультифакториальные болезни">
							<div className="block">
								<MultiDiseaseWrapped customerDiseaseLk={customerDiseaseLk}/>
							</div>
						</Tab>
						<Tab eventKey="ancestral-disease" title="Наследственные болезни">
							<div className="block">
								<AcestralRiskWrapped />
							</div>
						</Tab>
					</Tabs>
				</div>
				{/* <MediaQuery maxWidth={576}>
					<BtnToTop />
				</MediaQuery> */}
			</section>
			
			
		)
	}
}
const mapStateToProps = ({ customerDiseaseLk, loadingDiseaseLk, errorDiseaseLk }) => {
    return {  customerDiseaseLk, loadingDiseaseLk, errorDiseaseLk };
};

const mapDispatchToProps = (dispatch, { genobiosisService }) => {
    return {
        fetchCustomerLKDisease: fetchCustomerLKDisease(genobiosisService, dispatch)
    };
};

export default compose(withGenobiosisService(), connect(mapStateToProps, mapDispatchToProps)
)(DiseaseRisk);
