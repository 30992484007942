import React from 'react'

const Test = ()=>{
    const data = <tbody>
                    <tr>
                    <td rowSpan="2">AZFA</td>
                    <td>AZgdfgFA</td>
                    <td>
                        <div>fdf</div>
                        <div>fdgdgdfgf</div>
                    </td>
                    <td>fdsfddddfdfsfsdfsfsd</td>
                    <td>fd</td>
                    <td rowSpan="3">fd</td>
                </tr>
                <tr>
                    <td>fds</td>
                    <td>fds</td>
                    <td>fds</td>
                    <td>fds</td>
                </tr>
                <tr>
                    <td>fds</td>
                    <td>fds</td>
                    <td>
                        <div>fdgdfgf</div>
                        <div>fdgggdgdfgf</div>
                        <div>fdggghgdgdfgf</div>
                    </td>
                    <td>fds</td>
                    <td>fds</td>
                </tr>
                <tr>
                    <td>AZFA</td>
                    <td>AZgdfgFA</td>
                    <td>
                        <div>fdf</div>
                        <div>fdgdgdfgf</div>
                    </td>
                    <td>fdsfddddfdfsfsdfsfsd</td>
                    <td>fd</td>
                    <td rowSpan="3">fd</td>
                </tr>
                <tr>
                    <td>fds</td>
                    <td>fds</td>
                    <td>fds</td>
                    <td>fds</td>
                </tr>
                <tr>
                    <td>fds</td>
                    <td>fds</td>
                    <td>
                        <div>fdgdfgf</div>
                        <div>fdgggdgdfgf</div>
                        <div>fdggghgdgdfgf</div>
                        <div>fdggghgdgdfgf</div>
                        <div>fdggghgdgdfgf</div>
                        <div>fdggghgdgdfgf</div>
                    </td>
                    <td>fds</td>
                    <td>fds</td>
                </tr>
    </tbody>


    return(
        <table className="table table-bordered table-sm">
            <thead>
                <tr>
                    <th colSpan="2">Ген</th>
                    <th colSpan="3">Маркери</th>
                    <th>Генотип</th>
                </tr>
            </thead>
                {data}
            </table>
    )
}
export default Test