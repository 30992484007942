import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Tabs, Tab} from 'react-bootstrap';
import GensHead from './gens-head';
import GensData from './gens-data';
import './pharmacogenetics.sass';
import '../psychogenetics-page/psychogenetics-page.sass';
import {fetchCustomerLKPharmacogenetic} from "../../../../actions";
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import compose from "../../../../utils/compose";
import {connect} from "react-redux";
import Spinner from '../../../icons/Spinner';
import ErrorIndicator from '../../../error-indicator';

class PharmacogeneticsPage extends Component{

    componentDidMount(){
        this.props.fetchCustomerLKPharmacogenetic()
    }

    render() {
        const {customerPharmacogeneticLk, loadingPharmacogeneticLk, errorPharmacogeneticLk, t} = this.props;
        if (loadingPharmacogeneticLk) {
            return <Spinner/>;
        }

        if (errorPharmacogeneticLk) {
            return <ErrorIndicator/>;
        }
        // console.log(customerPharmacogeneticLk)
        return (
            <div className="pharmocogenetics-page psychogenetic-risks">
                <h3 className="h3">{t(`Фармакогенетика`)}</h3>

                <div className="pharmocogenetics-content">
                    <Tabs id="uncontrolled-tab-example" className="utrition-page-tab">
						{
                            customerPharmacogeneticLk.groups.map((data, idx)=>{
                            	return(
                                    <Tab key={idx} eventKey={data.name} title={t(`${data.name}`)}>
                                        <GensHead/>
                                        <GensData data={data.medicaments}/>
                                    </Tab>
								)
							})
						}
                    </Tabs>
                </div>

            </div>
        )
    }
}
const mapStateToProps = ({ customerPharmacogeneticLk, loadingPharmacogeneticLk, errorPharmacogeneticLk }) => {
    return {  customerPharmacogeneticLk, loadingPharmacogeneticLk, errorPharmacogeneticLk };
};

const mapDispatchToProps = (dispatch, { genobiosisService }) => {
    return {
        fetchCustomerLKPharmacogenetic: fetchCustomerLKPharmacogenetic(genobiosisService, dispatch)
    };
};

export default compose(withGenobiosisService(), withTranslation(), connect(mapStateToProps, mapDispatchToProps)
)(PharmacogeneticsPage);