import React from 'react'
import withGenobiosisService from "../../../hoc/with-genobiosis-service";

const TrainingsTable = ({genobiosisService, items})=>{
    // const test = ()=>{
    //     genobiosisService.getPharmacogenetic()
    //         .then(data=>console.log(data))
    //         .catch(err=>console.log(err))
    // }
    return (
        <div>
            <table className="table table-striped table-sm">
                <thead>
                    <tr>
                        <th>Дата</th>
                        <th>Тип</th>
                        <th>Продолжительность</th>
                        {/*<th>Удалить</th>*/}
                    </tr>
                </thead>
                <tbody>
                {items.map((item, idx) => {
                    return (
                        <tr key={idx}>
                            <td>{item.date}</td>
                            <td>{item.type}</td>
                            <td>{item.duration}</td>
                            {/*<td><button onClick={()=>test()}>Удалить</button></td>*/}
                        </tr>
                    )
                })}


                </tbody>
            </table>
        </div>

    )
}
export default withGenobiosisService()(TrainingsTable)
