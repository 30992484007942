import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
const detectionOptions = {
    order: ['path', 'queryString'],
    lookupFromPathIndex: 0,
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'],
    checkWhitelist: true
}
i18n
// load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
// learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: "ro",
        debug: false,
        load: 'languageOnly', // we only provide en, de -> no region specific locals like en-US, de-DE
        // have a common namespace used around the full app
        ns: ['translation', 'meta'],
        // ns: ['translation'],
        defaultNS: 'translation',
        whitelist:["ro","ru","en"],
        saveMissing: true,
        keySeparator: false,
        detection: detectionOptions,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });
export default i18n;