import React from 'react'
import './error-indicator.sass'
import logo from '../icons/Logo';
const ErrorIndicator = () =>{
    return(
        <div className="wrapp-error">
            <img src={logo} alt="logo" />
            <h2>Sorry, something went wrong.</h2>
        </div>
    )
}
export default ErrorIndicator