import React from 'react';
import './progenitor-page.sass';
import { withTranslation } from 'react-i18next';
import MapFirst from './img/map.png'
import PhoOne from './img//pho-1.png'
import PhoTwo from './img/pho-2.png'
import PhoYou from './img/you.png'
import MapSecond from './img/map.png'

import ChartSection from './chart-section/chart-section'

const ProgenitorPage = ({ t}) => {
	
	return(
		<div className="chronotype-page">
			<h3 className="h3">{t(`Происхождение`)}</h3>
            <div className="chronotype-wrapper">
                <div className="progenitor-page">
                    <div className="h4">{t(`Ваш предок`)}</div>
                    <div className="your-progenitor d-flex">
                        <div className="name grey grey-size">Неандерталец</div>
                        <div className="your-info">
                            <span className="grey grey-size d-b">{t(`Результат`)}:</span>
                            <span className="blue d-b">{t(`Неандерталец`)}</span>
                            <p>{t(`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`)}</p>
                        </div>
                    </div>

                    <ChartSection />

                    <div className="history d-flex">
                        <div className="info">
                            <div className="h4">{t(`Материнская гаплогруппа`)}</div>
                            <span className="grey grey-size d-b">{t(`Результат`)}:</span>
                            <span className="desc d-b">{t(`Ваша материнсская гаплогруппа`)}</span>
                            <p className="grey">{t(`Происхождение и миграция гаплогруппы T`)}:</p>
                            <div className="block">
                                <h5 className="h5"><span className="blue">180 000 лет назад</span> - Гаплогруппа L</h5>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            </div>
                            <div className="block">
                                <h5 className="h5"><span className="blue">65 000 лет назад</span> - Гаплогруппа L3</h5>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            </div>
                            <div className="block">
                                <h5 className="h5"><span className="blue">59 000 лет назад</span> - Гаплогруппа N</h5>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            </div>
                            <div className="block">
                                <h5 className="h5"><span className="blue">57 000 лет назад</span> - Гаплогруппа R</h5>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            </div>
                            <div className="block">
                                <h5 className="h5"><span className="blue">25 000 лет назад</span> - Гаплогруппа T</h5>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            </div>
                        </div>
                        <div className="map-section">
                            <img src={MapFirst} alt=""/>
                            <div className="map-tree d-flex">
                                <div className="w-100">
                                    <div className="person">
                                        <img src={PhoOne} alt=""/>
                                        <div className="grey grey-size">Общий предок:</div>
                                        <p>R-M269 общий предок 10 000 лет назад</p>
                                    </div>
                                    <div className="line-v line-circle"></div>
                                </div>
                                <div className="lines-block d-flex">
                                    <div className="line-g"></div>
                                    <div className="line-v-m line-circle"></div>
                                    <div className="line-v-m line-circle"></div>
                                </div>
                                <div className="persons-block d-flex">
                                    <div className="person">
                                        <img src={PhoTwo} alt=""/>
                                        <p>Мария Антуанетта</p>
                                    </div>
                                    <div className="person">
                                        <img src={PhoYou} alt=""/>
                                        <p>Вы</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="history d-flex">
                        <div className="info">
                            <div className="h4">{t(`Материнская гаплогруппа`)}</div>
                            <span className="grey grey-size d-b">{t(`Результат`)}:</span>
                            <span className="desc d-b">{t(`Ваша материнсская гаплогруппа`)}</span>
                            <p className="grey">{t(`Происхождение и миграция гаплогруппы T`)}:</p>
                            <div className="block">
                                <h5 className="h5"><span className="blue">275 000 лет назад</span> - Гаплогруппа A</h5>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            </div>
                            <div className="block">
                                <h5 className="h5"><span className="blue">76 000 лет назад</span> - Гаплогруппа F-M89</h5>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            </div>
                            <div className="block">
                                <h5 className="h5"><span className="blue">53 000 лет назад</span> - Гаплогруппа K-M9</h5>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            </div>
                            <div className="block">
                                <h5 className="h5"><span className="blue">45 000 лет назад</span> - Гаплогруппа N-M231</h5>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            </div>
                            <div className="block">
                                <h5 className="h5"><span className="blue">&lt;45 000 лет назад</span> - Гаплогруппа N-M46</h5>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            </div>
                        </div>
                        <div className="map-section">
                            <img src={MapFirst} alt=""/>
                            <div className="map-tree d-flex">
                                <div className="w-100">
                                    <div className="person">
                                        <img src={PhoOne} alt=""/>
                                        <div className="grey grey-size">Общий предок:</div>
                                        <p>R-M269 общий предок 10 000 лет назад</p>
                                    </div>
                                    <div className="line-v line-circle"></div>
                                </div>
                                <div className="lines-block d-flex">
                                    <div className="line-g"></div>
                                    <div className="line-v-m line-circle"></div>
                                    <div className="line-v-m line-circle"></div>
                                </div>
                                <div className="persons-block d-flex">
                                    <div className="person">
                                        <img src={PhoTwo} alt=""/>
                                        <p>Мария Антуанетта</p>
                                    </div>
                                    <div className="person">
                                        <img src={PhoYou} alt=""/>
                                        <p>Вы</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
};
export default withTranslation()(ProgenitorPage)