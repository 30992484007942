export const data = [
    {
        number: '01',
        title: 'Депрессия',
        description: 'Психическое расстройство, которое является ответной реакцией организма на стресс, эмоциональное перенапряжение или физическое переутомление.',
        link:'depression',
        yourRisk:'0,84%',
        mediumRisk:'14%',

    },
    {
        number: '02',  
        title: 'Алкоголизм',
        description: 'Тяжелое прогрессирующее заболевание, при котором человек становится психологически и физически зависимым от спиртного.',
        link:'alcohol',
        yourRisk:'9,9%',
        mediumRisk:'9%'
      },
    {
        number: '03',  
        title: 'Целиакия',
        description: 'Расстройство пищеварения, связанное с непереносимостью глютена (белка, содержащегося в злаковых культурах), который вызывает патологическую иммунную реакцию организма.',
        link:'celiac',
        yourRisk:'1,71%',
        mediumRisk:'0,9%'
      },
    {
        number: '04',  
        title: 'Болезнь Крона',
        description: 'Это хроническое воспалительное заболевание, которое поражает органы желудочно-кишечного тракта.',
        link:'crohn',
        yourRisk:'0,01269%',
        mediumRisk:'0,0135%'
      },
    {
        number: '05',  
        title: 'Рак поджелудочной железы',
        description: 'Злокачественное новообразование, вызванное неконтролируемым ростом атипичных клеток поджелудочной железы.',
        link:'pancreatic-cancer',
        yourRisk:'0%',
        mediumRisk:'0,001%'
      },
    {
        number: '06',  
        title: 'Язвенный колит',
        description: 'Хроническое заболевание, характеризующееся воспалением толстой кишки с развитием осложнений разной степени сложности.',
        link:'ulcerative-colitis',
        yourRisk:'0,022%',
        mediumRisk:'0,012%'
      },
    {
        number: '07',  
        title: 'Ишемическая болезнь сердца',
        description: 'Патологическое состояние сердца, при котором миокард получает недостаточно крови из-за повреждения коронарных артерий.',
        link:'coronary-heart-disease',
        yourRisk:'0,12%',
        mediumRisk:'2%'
      },
    {
        number: '08',  
        title: 'Мерцательная аритмия',
        description: 'Состояние, при котором мышцы предсердий работают асинхронно.',
        link:'atrial-fibrillation',
        yourRisk:'0,062%',
        mediumRisk:'0,241%'
      },
    {
        number: '09',  
        title: 'Венозная тромбоэмболия',
        description: 'Опасное состояние, которое приводит к плохой проходимости вен из-за образования кровяного сгустка.',
        link:'tela',
        yourRisk:'0,034%',
        mediumRisk:'0,033%'
      },
    {
        number: '10',  
        title: 'Ишемический инсульт',
        description: 'Патологическое состояние, вызванное ухудшением кровообращения в головном мозге, сопровождающиеся характерными неврологическими расстройствами.',
        link:'ischaemic-stroke',
        yourRisk:'0,116%',
        mediumRisk:'0,343%'
      },
    {
        number: '11',  
        title: 'Рассеянный склероз',
        description: 'Хроническое заболевание, которое поражает головной и спинной мозг.',
        link:'disseminated-sclerosis',
        yourRisk:'0,081%',
        mediumRisk:'0,0456%'
      },
    {
        number: '12',  
        title: 'Болезнь Паркинсона',
        description: 'Заболевание, связанное с постепенной гибелью нервных клеток, вырабатывающих медиатор дофамин.',
        link:'parkinson',
        yourRisk:'0,0007%',
        mediumRisk:'0,0004%'
      },
    {
        number: '13',  
        title: 'Болезнь Альцгеймера',
        description: 'Заболевание, которое сопровождается разрушение и гибель нервных клеток.',
        link:'alzheimer',
        yourRisk:'0%',
        mediumRisk:'0%'
      },
    {
        number: '14',  
        title: 'Ожирение',
        description: 'Чрезмерное накопление жира в организме, приводящее к появлению избыточной массы тела.',
        link:'obesity',
        yourRisk:'14%',
        mediumRisk:'21,56%'
      },
    {
        number: '15',  
        title: 'Сахарный диабет 2 типа',
        description: 'Метаболическое заболевание, сопровождающееся повышенным уровнем сахара в крови из-за нарушения взаимодействия инсулина с клетками тканей.',
        link:'type-2-diabetes',
        yourRisk:'4%',
        mediumRisk:'4%'
      },
    {
        number: '16',  
        title: 'Сахарный диабет 1 типа',
        description: 'Эндокринное заболевание, вызванное абсолютным дефицитом гормона поджелудочной железы (инсулина).',
        link:'type-1-diabetes',
        yourRisk:'0,0093%',
        mediumRisk:'0,0186%'
      },
    {
        number: '17',  
        title: 'Астма',
        description: 'Заболевание дыхательных путей, сопровождающееся хроническим воспалением.',
        link:'asthma',
        yourRisk:'0,311%',
        mediumRisk:'0,199%'
      },
    {
        number: '18',  
        title: 'Ревматоидный артрит',
        description: 'Системное заболевание опорно-двигательного аппарата, которое поражает соединительную ткань.',
        link:'rheumatoid-arthritis',
        yourRisk:'0,026%',
        mediumRisk:'0,028%'
      },
    {
        number: '19',  
        title: 'Системная красная волчанка',
        description: 'Аутоиммунное заболевание, при котором антитела, вырабатываемые иммунной системой человека, повреждают здоровые клетки соединительной ткани.',
        link:'disseminated-lupus-erythematosus',
        yourRisk:'0,0124%',
        mediumRisk:'0,007%'
      },
    {
        number: '20',  
        title: 'Системный склероз (склеродермия)',
        description: 'Заболевание соединительной ткани с преобладанием фиброза, которое характеризуется поражением мелких сосудов.',
        link:'systemic-sclerosis',
        yourRisk:'0,0017%',
        mediumRisk:'0,0009%'
      },
    {
        number: '21',  
        title: 'Псориатический артрит',
        description: 'Воспалительное заболевание суставов у пациентов, больных псориазом.',
        link:'psoriatic-arthritis',
        yourRisk:'0,162%',
        mediumRisk:'0,108%'
      },
    {
        number: '22',  
        title: 'Хронические болезни почек',
        description: 'Повреждение почек или снижение их функции на протяжении длительного периода.',
        link:'chronic-kidney-disease',
        yourRisk:'0,0042%',
        mediumRisk:'0,042%'
      },
    {
        number: '23',  
        title: 'Колоректальный рак',
        description: 'Злокачественное образование в толстой кишке и червеобразном отростке.',
        link:'colorectal-cancer',
        yourRisk:'0,0001%',
        mediumRisk:'0,00016%'
      },
    {
        number: '24',  
        title: 'Рак легких',
        description: 'Злокачественные новообразования в легочной ткани.',
        link:'lung-cancer',
        yourRisk:'0,0016%',
        mediumRisk:'0,0104%'
      },
    {
        number: '25',  
        title: 'Меланома',
        description: 'Злокачественное новообразование, которое поражает эпидермис.',
        link:'melanoma',
        yourRisk:'0,00009%',
        mediumRisk:'0,0001%'
      },
    {
        number: '26',  
        title: 'Шизофрения',
        description: 'Тяжелое психическое заболевание, характеризующееся специфическими изменениями личности.',
        link:'schizophrenia',
        yourRisk:'0,056%',
        mediumRisk:'0,1752%'
      },
    {
        number: '27',  
        title: 'Гнездная алопеция',
        description: 'Патологическое выпадение волос, приводящее к их полному исчезновению.',
        link:'alopecia-areata',
        yourRisk:'1,08%',
        mediumRisk:'3%'
      },
    {
        number: '28',  
        title: 'Хронические воспалительные заболевания кишечника',
        description: 'Группа болезней, которые поражают органы желудочно-кишечного тракта (язвенный колит, болезнь Крона).',
        link:'сhronic-inflammatory-bowel-disease',
        yourRisk:'0,607%',
        mediumRisk:'0,366%'
      },
    {
        number: '29',  
        title: 'Аллергические заболевания',
        description: 'Повышенная чувствительность к веществам, вызывающим ответную реакцию организма на раздражитель.',
        link:'allergic-diseases',
        yourRisk:'16,2%',
        mediumRisk:'54%'
      }
];