import React from 'react'
import "./sass/_pagination.sass"
import LibPagination from "rc-pagination";
export default function Pagination(props) {
  const { onChange, itemsPerPage, currentPage, totalItems } = props;
  const iconPrev = <span className="rc-pagination-item-link">
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 8.1 13.4">
      <polygon fill="#CBCCDA" points="8.1,1.4 6.7,0 0,6.7 6.7,13.4 8.1,12 2.8,6.7 "/>
    </svg>
  </span>;
  const iconNext = <span className="rc-pagination-item-link">
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 8.1 13.4">
      <polygon fill="#CBCCDA" points="1.4,0 0,1.4 5.3,6.7 0,12 1.4,13.4 8.1,6.7 "/>
    </svg>
  </span>;
  return (
    <LibPagination
      onChange={onChange}
      pageSize={itemsPerPage}
      current={currentPage}
      total={totalItems}
      showTitle={false}
      showLessItems
      prevIcon={iconPrev}
      nextIcon={iconNext}
    />
  )
}