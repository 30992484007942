import React, { useState, useEffect } from 'react';
import {withTranslation} from "react-i18next";

const SettingPageItem = ({ customer, onOpenMail, onOpenPhone, onOpenPass, i18n, t }) => {
    const { surname, given_name, patronymic, email, phone,
        gen_id, birthday, deliver_address, register_address,
        passport_data} = customer
    
    const [customerItemRu, setCustomerItemRu] = useState('block');

    useEffect(() => {
        if(i18n.language === 'ro'){
            setCustomerItemRu('none')
        }
        if (i18n.language === 'ru') {
            setCustomerItemRu('block')
        }

    },[i18n.language])

    return (
        <div className="d-flex list-part">
            <div className="d-flex wrapp-part">
                <ul className="list-customer list-customer-key">
                    <li>{t(`Фамилия`)}:</li>
                    <li>{t(`Имя`)}:</li>
                    <li style={{ display: customerItemRu }}>Отчество:</li>
                    <li>{t(`Дата рождения`)}:</li>
                    <li>ID:</li>
                    <li>E-mail:</li>
                    <li>{t(`Телефон`)}:</li>
                    <li>{t(`Пароль`)}:</li>
                </ul>
                <ul className="list-customer">
                    <li>{surname}</li>
                    <li>{given_name}</li>
                    <li>{patronymic}</li>
                    <li>{birthday}</li>
                    <li>{gen_id}</li>

                    <li>{email}<span onClick={onOpenMail}/></li>
                    <li>{phone}<span onClick={onOpenPhone}/></li>
                    <li>******************<span onClick={onOpenPass}/></li>
                </ul>
            </div>
            <div className="d-flex wrapp-part">
                <ul className="list-customer list-customer-key">
                    <li>{t(`Паспортные данные`)}:</li>
                    <li>{t(`Адрес регистрации`)}:</li>
                    <li>{t(`Адрес доставки паспорта`)}:</li>
                </ul>
                <ul className="list-customer">
                    <li>{passport_data}</li>
                    <li>{register_address}</li>
                    <li>{deliver_address}</li>
                </ul>
            </div>
        </div>


    );
};

export default withTranslation()(SettingPageItem);