import React,{useState,useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
const MultiDiseaseItem = ({disease, key, getData})=>{
    const { i18n, t } = useTranslation();
    const [lang, setLang]= useState(`/${i18n.language}`)
    useEffect(()=>{
        if (i18n.language === 'ro'){
            setLang('')
        }else {
            setLang(`/${i18n.language}`)
        }
    },[i18n.language])
    const {number,title,description,link} = disease
    return(
        <div className="multi-risk" key={key}>
            <div className="text">
                <h4 className="h4">{number}<span className="title">{t(`${title}`)}</span></h4>
                <p>{t(`${description}`)}</p>
                <Link to={`${lang}/my-cabinet/disease-risk/${link}`} className="link">
                    {t(`Подробнее`)}...
                </Link>
            </div>
            <div className="block-box with-border">
                <span className="desc">{t(`Ваш риск`)}:</span>
                <p className="number">{getData.chance+'%'}</p>
            </div>
            <div className="block-box">
                <span className="desc">{t(`Средний риск по популяции`)}:</span>
                <p className="number">{getData.illness_probability[0].middle_chance+'%'}</p>
            </div>
        </div>
    )
}
export default MultiDiseaseItem