import React, {Component} from 'react'
import { withTranslation } from 'react-i18next'
import Steps, { Step } from 'rc-steps';
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import './customer-page.sass';
import { connect } from 'react-redux';
import withGenobiosisService from "../../hoc/with-genobiosis-service";
import {fetchCustomerStatusData} from '../../../actions'
import  compose  from '../../../utils/compose';

import Spinner from '../../icons/Spinner';
import ErrorIndicator from '../../error-indicator';

class StatusPage extends Component{
    
    componentDidMount(){
        this.props.fetchCustomerStatusData()
    }

    render(){
        const { statusCustomer, loadingStatus, errorStatus, t } = this.props;
        if (loadingStatus) {
            return <Spinner />;
        }

        if (errorStatus) {
            return <ErrorIndicator />;
        }
        return(

            <section className="status-step">
                <div className='title-content'>
                    <h4>{t(`Степень готовности генетического паспорта`)}</h4>
                </div>
                <Steps labelPlacement="vertical" current={statusCustomer-1} initial={0} >
                    <Step className="t1" title={t(`ваш генетический материал взят для исследования.`)}/>
                    <Step className="t2" title={t(`Выполняется расшифровка генетического материала.`)} />
                    <Step className="t3" title={t(`Расшифровка ДНК произведена. ваш паспорт уже изготавливается.`)} />
                    <Step className="t4" title={t(`ваш генетический паспорт уже в пути. Ожидайте звонка от курьера.`)}/>
                    <Step className="t5" title={t(`Паспорт доставлен. Расшифровка ДНК доступна в личном кабинете сайта.`)}/>
                </Steps>
            </section>
        )
    }
}
const mapStateToProps = ({ statusCustomer, loadingStatus, errorStatus }) => {
    return { statusCustomer, loadingStatus, errorStatus };
};

const mapDispatchToProps = (dispatch, { genobiosisService }) => {
    return {
        fetchCustomerStatusData: fetchCustomerStatusData(genobiosisService, dispatch)
    };
};

export default compose(withGenobiosisService(),  withTranslation(), connect(mapStateToProps, mapDispatchToProps)
)(StatusPage);