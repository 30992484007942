export const data = [
    {
        number: '01',
        title: 'Синдром Андерманна (агенезия мозолистого тела с периферической невропатией)',
        results:[
            {mutation:'i5012573',genotype:'I/I',riskAllele:'D',alleleNorm:'I'},
            {mutation:'i5012575',genotype:'G/G',riskAllele:'A',alleleNorm:'G'}
        ],
    },
    {
        number: '02',
        title: 'Дефицит альфа-1-антитрипсина',
        results:[
            {mutation:'rs28929474',genotype:'C/C',riskAllele:'T',alleleNorm:'C'},
            {mutation:'rs17580',genotype:'T/T',riskAllele:'A',alleleNorm:'T'},
        ],
    },
    {
        number: '03',
        title: 'Аутосомно-рецессивная спастическая атаксия Шарлевуа - Сагенэ (MIM 270550)',
        results:[
            {mutation:'i5012578',genotype:'I/I',riskAllele:'D',alleleNorm:'I'}
        ],
    },
    {
        number: '04',
        title: 'Аутосомно-рецессивная поликистозная болезнь почек',
        results:[
            {mutation:'rs28939383',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'i5000047',genotype:'T/T',riskAllele:'C',alleleNorm:'T'},
            {mutation:'i5000045',genotype:'A/A',riskAllele:'G',alleleNorm:'A'},
            {mutation:'i5000043',genotype:'A/A',riskAllele:'G',alleleNorm:'A'},
            {mutation:'i5012610',genotype:'I/I ',riskAllele:'D',alleleNorm:'I'},
            {mutation:'i5012612',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
        ],
    },
    {
        number: '05',
        title: 'Бета-талассемия',
        results:[
            {mutation:'i3003137',genotype:'T/T',riskAllele:'A',alleleNorm:'T'},
            {mutation:'rs33950507',genotype:'C/C',riskAllele:'T',alleleNorm:'C'},
            {mutation:'rs33930165',genotype:'C/C',riskAllele:'T',alleleNorm:'C'},
            {mutation:'rs11549407',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'rs33986703',genotype:'—',riskAllele:'A',alleleNorm:'T'},
            {mutation:'rs63750783',genotype:'—',riskAllele:'T',alleleNorm:'C'},
            {mutation:'rs33971440',genotype:'C/C',riskAllele:'T',alleleNorm:'C'},
            {mutation:'rs34716011',genotype:'—',riskAllele:'T',alleleNorm:'C'},
            {mutation:'rs33960103',genotype:'—',riskAllele:'G',alleleNorm:'C'},
            {mutation:'rs35004220',genotype:'—',riskAllele:'T',alleleNorm:'C'},
            {mutation:'rs34451549',genotype:'—',riskAllele:'A',alleleNorm:'G'},
            {mutation:'rs34690599',genotype:'—',riskAllele:'C',alleleNorm:'G'},
            {mutation:'rs33915217',genotype:'—',riskAllele:'G',alleleNorm:'C'},
            {mutation:'rs33985472',genotype:'—',riskAllele:'C',alleleNorm:'T'},
            {mutation:'rs34598529',genotype:'—',riskAllele:'C',alleleNorm:'T'},
            {mutation:'rs33944208',genotype:'—',riskAllele:'A',alleleNorm:'G'}
        ],
    },
    {
        number: '06',
        title: 'Синдром Блума',
        results:[
            {mutation:'i4000396',genotype:'D/D',riskAllele:'I',alleleNorm:'D'}
        ],
    },
    {
        number: '07',
        title: 'Болезнь Канаван - ван Богерта (спонгиозная младенческая дегенерация)',
        results:[
            {mutation:'rs12948217',genotype:'C/C',riskAllele:'A',alleleNorm:'C, T'},
            {mutation:'rs28940279',genotype:'—',riskAllele:'C',alleleNorm:'A'},
            {mutation:'rs28940574',genotype:'—',riskAllele:'A',alleleNorm:'C'},
        ],
    },
    {
        number: '08',
        title: 'Врожденные нарушения гликозилирования cdg синдром тип iа (синдром Жакена)',
        results:[
            {mutation:'i5012680',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'i5012679',genotype:'C/C',riskAllele:'A',alleleNorm:'C'},
        ],
    },
    {
        number: '09',
        title: 'Аутосомно-рецессивная несиндромальная нейросенсорная тугоухость, связанная с мутациями в гене коннексина 26 (GJB2)',
        results:[
            {mutation:'i4000434',genotype:'I/I',riskAllele:'D',alleleNorm:'I'},
            {mutation:'i4000435',genotype:'I/I',riskAllele:'D',alleleNorm:'I'},
        ],
    },
    {
        number: '10',
        title: 'Муковисцидоз',
        results:[
            {mutation:'i3000001',genotype:'I/I',riskAllele:'D',alleleNorm:'I'},
            {mutation:'i4000292',genotype:'I/I',riskAllele:'D',alleleNorm:'I'},
            {mutation:'i4000294',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'i4000296',genotype:'C/C',riskAllele:'T',alleleNorm:'C'},
            {mutation:'i4000297',genotype:'G/G',riskAllele:'C',alleleNorm:'G'},
            {mutation:'i4000291',genotype:'C/C',riskAllele:'A',alleleNorm:'C'},
            {mutation:'i4000299',genotype:'G/G',riskAllele:'T',alleleNorm:'G'},
            {mutation:'i4000300',genotype:'G/G',riskAllele:'T',alleleNorm:'G'},
            {mutation:'i4000301',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'i4000305',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'i4000306',genotype:'C/C',riskAllele:'T',alleleNorm:'C'},
            {mutation:'i4000307',genotype:'G/G',riskAllele:'C',alleleNorm:'G'},
            {mutation:'i4000308',genotype:'C/C',riskAllele:'T',alleleNorm:'C'},
            {mutation:'i4000309',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'i4000311',genotype:'C/C',riskAllele:'G',alleleNorm:'C'},
            {mutation:'i4000313',genotype:'I/I',riskAllele:'D',alleleNorm:'I'},
            {mutation:'i4000314',genotype:'G/G',riskAllele:'T',alleleNorm:'G'},
            {mutation:'i4000315',genotype:'G/G',riskAllele:'T',alleleNorm:'G'},
            {mutation:'i4000316',genotype:'I/I',riskAllele:'D',alleleNorm:'I'},
            {mutation:'i4000317',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'i4000318',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'i4000320',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'i4000321',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'i4000322',genotype:'I/I',riskAllele:'D',alleleNorm:'I'},
            {mutation:'i4000324',genotype:'D/D',riskAllele:'I',alleleNorm:'D'},
            {mutation:'i4000325',genotype:'C/C',riskAllele:'T',alleleNorm:'C'}
        ],
    },
    {
        number: '11',
        title: 'Синдром недостаточности бифункционального белка пероксисом',
        results:[
            {mutation:'i5007145',genotype:'G/G',riskAllele:'A',alleleNorm:'C'},
            {mutation:'i5007146',genotype:'A/A',riskAllele:'T',alleleNorm:'A'},
        ],
    },
    {
        number: '12',
        title: 'Синдром дефицита дигидролипоил дегидрогеназы',
        results:[
            {mutation:'i5003700',genotype:'G/G',riskAllele:'T',alleleNorm:'G'}
        ],
    },
    {
        number: '13',
        title: 'Дефицит дигидропиримидиндегидрогеназы',
        results:[
            {mutation:'rs3918290',genotype:'C/C',riskAllele:'T',alleleNorm:'C'}
        ],
    },
    {
        number: '14',
        title: 'Дефицит фактора XI',
        results:[
            {mutation:'i4000397',genotype:'G/G',riskAllele:'a',alleleNorm:'g'},
            {mutation:'i4000398',genotype:'G/G',riskAllele:'T',alleleNorm:'G'},
            {mutation:'i4000399',genotype:'T/T',riskAllele:'C',alleleNorm:'T'}
        ],
    },
    {
        number: '15',
        title: 'Семейная дисавтономия (синдром Райли - Дея)',
        results:[
            {mutation:'i4000334',genotype:'A/A',riskAllele:'G',alleleNorm:'A'},
            {mutation:'i4000400',genotype:'C/C',riskAllele:'G',alleleNorm:'C'},
        ],
    },
    {
        number: '16',
        title: 'Семейная гиперхолестеринемия типа Iib',
        results:[
            {mutation:'rs5742904',genotype:'—',riskAllele:'T',alleleNorm:'C'},
            {mutation:'i4000339',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'rs12713559',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
        ],
    },
    {
        number: '17',
        title: 'Семейный гиперинсулинизм (связанный с ABCC8)',
        results:[
            {mutation:'i5012556',genotype:'I/I',riskAllele:'D',alleleNorm:'I'},
            {mutation:'i5012558',genotype:'C/C',riskAllele:'T',alleleNorm:'C'},
            {mutation:'i5008233',genotype:'A/A',riskAllele:'T',alleleNorm:'A'},
        ],
    },
    {
        number: '18',
        title: 'Семейная средиземноморская лихорадка',
        results:[
            {mutation:'i4000406',genotype:'T/T',riskAllele:'C',alleleNorm:'T'},
            {mutation:'rs28940579',genotype:'A/A',riskAllele:'G',alleleNorm:'A'},
            {mutation:'rs28940580',genotype:'—',riskAllele:'G',alleleNorm:'C'},
            {mutation:'rs28940578',genotype:'—',riskAllele:'T',alleleNorm:'C'},
            {mutation:'i4000409',genotype:'C/C',riskAllele:'A',alleleNorm:'C'},
            {mutation:'i4000403',genotype:'G/G',riskAllele:'C',alleleNorm:'G'},
            {mutation:'i4000410',genotype:'C/C',riskAllele:'T',alleleNorm:'C'},
            {mutation:'i4000407',genotype:'T/T',riskAllele:'C',alleleNorm:'T'},
            {mutation:'rs3743930',genotype:'C/C',riskAllele:'G',alleleNorm:'C'},
            {mutation:'rs11466023',genotype:'G/G',riskAllele:'A',alleleNorm:'C'}
        ],
    },
    {
        number: '19',
        title: 'Анемия Фанкони (связанная с FANCC)',
        results:[
            {mutation:'i4000336',genotype:'T/T',riskAllele:'A',alleleNorm:'T'},
            {mutation:'i4000412',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'i4000413',genotype:'I/I',riskAllele:'D',alleleNorm:'I'},
        ],
    },
    {
        number: '20',
        title: 'Дефицит глюкозо-6-фосфатдегидрогеназы',
        results:[
            {mutation:'rs1050828',genotype:'C/C',riskAllele:'T',alleleNorm:'C'},
            {mutation:'rs1050829',genotype:'T/T',riskAllele:'C',alleleNorm:'T'},
        ],
    },
    {
        number: '21',
        title: 'Болезнь Гоше (сфинголипидоз)',
        results:[
            {mutation:'i4000415',genotype:'T/T',riskAllele:'C',alleleNorm:'T'},
            {mutation:'i4000417',genotype:'D/D',riskAllele:'I',alleleNorm:'D'},
            {mutation:'i4000419',genotype:'C/C',riskAllele:'A',alleleNorm:'C'},
        ],
    },
    {
        number: '22',
        title: 'Болезнь накопления гликогена типа 1а',
        results:[
            {mutation:'i3002486',genotype:'C/C',riskAllele:'T',alleleNorm:'C'}
        ],
    },
    {
        number: '23',
        title: 'Болезнь накопления гликогена типа 1b',
        results:[
            {mutation:'i5012880',genotype:'I/I',riskAllele:'D',alleleNorm:'I'},
            {mutation:'i5012878',genotype:'A/A',riskAllele:'G',alleleNorm:'A'},
        ],
    },
        
        {number: '24',
        title: 'GRACILE – синдром (финский летальный неонатальный метаболический синдром; лактатацидоз с печеночным гемосидерозом)',
        results:[
            {mutation:'i5012660',genotype:'A/A',riskAllele:'G',alleleNorm:'A'}
        ],},
        {number: '25',
        title: 'Гемохроматоз (связанный с HFE)',
        results:[
            {mutation:'rs1800562',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'rs1799945',genotype:'C/G',riskAllele:'G',alleleNorm:'C'},
            {mutation:'i3002468',genotype:'A/A',riskAllele:'T',alleleNorm:'A'},
        ],},
        {number: '26',
        title: 'Фруктоземия',
        results:[
            {mutation:'rs1800546',genotype:'C/C',riskAllele:'G',alleleNorm:'C'},
            {mutation:'i5008215',genotype:'G/G',riskAllele:'T',alleleNorm:'G'},
            {mutation:'i5012664',genotype:'G/G',riskAllele:'C',alleleNorm:'G'},
            {mutation:'i5012665',genotype:'I/I',riskAllele:'D',alleleNorm:'I'},
        ],},
        {number: '27',
        title: 'Гипертрофическая кардиомиопатия (делеция 25bp в гене MYBPC3)',
        results:[
            {mutation:'rs36212066',genotype:'—',riskAllele:'D',alleleNorm:'I'}
        ],},
        {number: '28',
        title: 'Пограничный буллезный эпидермолиз (связанный с LAMB3)',
        results:[
            {mutation:'i5012672',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'i5012669',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'i5012671',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
        ],},
        {number: '29',
        title: 'Прогрессирующая мышечная дистрофия Эрба-Рота',
        results:[
            {mutation:'rs28933693',genotype:'C/C',riskAllele:'T',alleleNorm:'C'},
            {mutation:'rs28936383',genotype:'—',riskAllele:'C',alleleNorm:'G'},
            {mutation:'rs28937900',genotype:'—',riskAllele:'A',alleleNorm:'C'},
        ],},
        {number: '30',
        title: 'Лейциноз (болезнь кленового сиропа) тип 1B',
        results:[
            {mutation:'i3002808',genotype:'G/G',riskAllele:'C',alleleNorm:'G'},
            {mutation:'i4000422',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
        ],},
        {number: '31',
        title: 'Дефицит ацил-КоА дегидрогеназы жирных кислот со средней длиной цепи (MCAD)',
        results:[
            {mutation:'i5012759',genotype:'G/G',riskAllele:'G',alleleNorm:'A'},
            {mutation:'i5012758',genotype:'T/T',riskAllele:'C',alleleNorm:'T'},
            {mutation:'i5012760',genotype:'C/C',riskAllele:'T',alleleNorm:'C'},
            {mutation:'i5012755',genotype:'C/C',riskAllele:'T',alleleNorm:'C'},
            {mutation:'i5003117',genotype:'C/C',riskAllele:'T',alleleNorm:'C'}
        ],},
        {number: '32',
        title: 'Сиалолипидоз (муколипидоз IV)',
        results:[
            {mutation:'i4000425',genotype:'A/A',riskAllele:'G',alleleNorm:'A'},
            {mutation:'i5012573',genotype:'—',riskAllele:'',alleleNorm:''},
        ],},
        {number: '33',
        title: 'Нейрональный цероидный липофусциноз (связанный с CLN5)',
        results:[
            {mutation:'i5012678',genotype:'I/I',riskAllele:'D',alleleNorm:'I'},
            {mutation:'i5012624',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'i5012622',genotype:'T/T',riskAllele:'G',alleleNorm:'T'},
            {mutation:'i5012623',genotype:'T/T',riskAllele:'A',alleleNorm:'T'}
        ],},
        {number: '34',
        title: 'Болезнь Ниманна-Пика, тип А',
        results:[
            {mutation:'i4000381',genotype:'T/T',riskAllele:'C',alleleNorm:'T'},
            {mutation:'i4000383',genotype:'I/I',riskAllele:'D',alleleNorm:'I'},
            {mutation:'i4000430',genotype:'G/G',riskAllele:'T',alleleNorm:'G'},
        ],},
        {number: '35',
        title: 'Синдром хромосомных поломок Неймеген',
        results:[
            {mutation:'i5012770',genotype:'I/I',riskAllele:'D',alleleNorm:'I'}
        ],},
        {number: '36',
        title: 'Синдром Пендреда',
        results:[
            {mutation:'i5012616',genotype:'T/T',riskAllele:'C',alleleNorm:'T'},
            {mutation:'i5000003',genotype:'A/A',riskAllele:'G',alleleNorm:'A'},
            {mutation:'i5012618',genotype:'A/A',riskAllele:'C',alleleNorm:'A'},
            {mutation:'i5000693',genotype:'G/G',riskAllele:'T',alleleNorm:'G'},
            {mutation:'i5000002',genotype:'A/A',riskAllele:'G',alleleNorm:'A'},
            {mutation:'i5000696',genotype:'T/T',riskAllele:'G',alleleNorm:'T'},
        ],},
        {number: '37',
        title: 'Фенилкетонурия',
        results:[
            {mutation:'i4000470',genotype:'G/G',riskAllele:'C',alleleNorm:'G'},
            {mutation:'rs5030841',genotype:'—',riskAllele:'G',alleleNorm:'A'},
            {mutation:'i4000472',genotype:'A/A',riskAllele:'G',alleleNorm:'A'},
            {mutation:'i4000473',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'i3003397',genotype:'C/C',riskAllele:'T',alleleNorm:'C'},
            {mutation:'i4000481',genotype:'C/C',riskAllele:'T',alleleNorm:'C'},
            {mutation:'i3003398',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'i3003399',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'i4000474',genotype:'C/C',riskAllele:'T',alleleNorm:'C'},
            {mutation:'rs5030849',genotype:'—',riskAllele:'T',alleleNorm:'C'},
            {mutation:'i3003400',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'i4000467',genotype:'C/C',riskAllele:'G',alleleNorm:'A'},
            {mutation:'i4000478',genotype:'C/C',riskAllele:'T',alleleNorm:'C'},
            {mutation:'i3003401',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'i4000475',genotype:'A/A',riskAllele:'C',alleleNorm:'A'},
            {mutation:'rs5030853',genotype:'—',riskAllele:'A',alleleNorm:'C'},
            {mutation:'i4000476',genotype:'G/G',riskAllele:'C',alleleNorm:'G'},
            {mutation:'i4000479',genotype:'G/G',riskAllele:'C',alleleNorm:'G'},
            {mutation:'i3003403',genotype:'T/T',riskAllele:'C',alleleNorm:'T'},
            {mutation:'rs5030857',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'rs5030858',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'i3003404',genotype:'C/C',riskAllele:'T',alleleNorm:'C'},
            {mutation:'rs28934899',genotype:'C/C',riskAllele:'G',alleleNorm:'C'},
            {mutation:'i3003405',genotype:'T/T',riskAllele:'C',alleleNorm:'T'},
            {mutation:'rs5030855',genotype:'C/C',riskAllele:'T',alleleNorm:'C'},
            {mutation:'rs5030861',genotype:'—',riskAllele:'T',alleleNorm:'C'},
            {mutation:'rs5030852',genotype:'—',riskAllele:'T',alleleNorm:'C'}
        ],},
        {number: '38',
        title: 'Первичная гипероксалурия II типа (PH2)',
        results:[
            {mutation:'i5012628',genotype:'I/I',riskAllele:'D',alleleNorm:'I'},
            {mutation:'i5012629',genotype:'I/I',riskAllele:'D',alleleNorm:'I'},
        ],},
        {number: '39',
        title: 'Ризомелическая точечная хондродисплазия типа 1 (RCDP1)',
        results:[
            {mutation:'i3002517',genotype:'T/T',riskAllele:'A',alleleNorm:'T'}
        ],},
        {number: '40',
        title: 'Болезнь Салла',
        results:[
            {mutation:'i5012634',genotype:'G/G',riskAllele:'A',alleleNorm:'G'}
        ],},
        {number: '41',
        title: 'Серповидноклеточная анемия',
        results:[
            {mutation:'i3003137',genotype:'T/T',riskAllele:'A',alleleNorm:'T'}
        ],},
        {number: '42',
        title: 'Синдром Шегрена - Ларссона',
        results:[
            {mutation:'rs72547571',genotype:'—',riskAllele:'T',alleleNorm:'C'}
        ],},
        {number: '43',
        title: 'Болезнь Тея - Сакса',
        results:[
            {mutation:'i4000436',genotype:'C/C',riskAllele:'T',alleleNorm:'C'},
            {mutation:'i4000391',genotype:'D/D',riskAllele:'I',alleleNorm:'D'},
            {mutation:'i4000393',genotype:'C/C',riskAllele:'G',alleleNorm:'C'},
            {mutation:'i4000438',genotype:'C/C',riskAllele:'T',alleleNorm:'C'},
            {mutation:'i4000440',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'i4000442',genotype:'G/G',riskAllele:'A',alleleNorm:'G'}
        ],},
        {number: '44',
        title: 'Торсионная дистония',
        results:[
            {mutation:'i4000446',genotype:'I/I',riskAllele:'D',alleleNorm:'I'}
        ],},
        {number: '45',
        title: 'Транстиретиновый амилоидоз сердца',
        results:[
            {mutation:'i3002759',genotype:'G/G',riskAllele:'A',alleleNorm:'G'}
        ],},
        {number: '46',
        title: 'Транстиретиновая семейная амилоидная полинейропатия',
        results:[
            {mutation:'i3002758',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'i5004213',genotype:'A/A',riskAllele:'G',alleleNorm:'A'}
        ],},
        {number: '47',
        title: 'Тирозинемия типа I',
        results:[
            {mutation:'i5012862',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'i5012861',genotype:'C/C',riskAllele:'T',alleleNorm:'C'},
            {mutation:'i5012865',genotype:'G/G',riskAllele:'A',alleleNorm:'G'},
            {mutation:'i5012867',genotype:'G/G',riskAllele:'T',alleleNorm:'G'},
            {mutation:'i5012864',genotype:'G/G',riskAllele:'T',alleleNorm:'G'},
        ],},
        {number: '48',
        title: 'Синдром Ушера III типа',
        results:[
            {mutation:'i4990151',genotype:'A/A',riskAllele:'C',alleleNorm:'A'}
        ],},
    {
        number: '49',
        title: 'Синдром Ушера I типа',
        results:[
            {mutation:'i5012804',genotype:'G/G',riskAllele:'A',alleleNorm:'G'}
        ],
    },
    {
        number: '50',
        title: 'Расстройства спектра Зеллвегера',
        results:[
            {mutation:'i5012688',genotype:'C/C',riskAllele:'T',alleleNorm:'C'}
        ]
    }
    
];