import React, {Component}  from 'react';
import  compose  from '../../../../utils/compose';
import { Modal } from 'react-bootstrap';
import '../../login-page/modal-customer.sass'
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import { withRouter } from "react-router-dom";
import {withTranslation} from "react-i18next";

class ModalEditPhone extends Component{
    state = {
        newPhone: '',
        error: ''
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = event => {
        event.preventDefault()
        const { genobiosisService, customer, onClose } = this.props
        // const id = customer.id
        genobiosisService.updateCustomerData({
                email: customer.email,
                phone: this.state.newPhone
            }
        )
            .then(()=>{
                onClose()
            })
            .catch(err => console.log(err))
        //

    }
    render() {
        const { t } = this.props
        return (
            <Modal show={this.props.open} onHide={this.props.onClose} centered size="xl">
                <div className="menu-form menu-one">
                    <Modal.Header closeButton/>
                    <form className="form-consult" onSubmit={this.handleSubmit}>
                        <h5>{t(`Смена телефона`)}</h5>
                        <label htmlFor="inputNewPhone" className="sr-only">New Phone</label>
                        <input type="text" id="inputNewPhone" name='newPhone' className="form-control"
                               placeholder={t(`Введите ваш новый номер телефона`)}
                               required autoFocus
                               value={this.state.newPhone}
                               onChange={this.handleChange}
                        />


                        <button className="button" type="submit">{t(`Сменить телефон`)}</button>
                    </form>
                    {this.state.error && <p>Invalid credentials</p>}
                </div>
            </Modal>
        )
    }
}
export default compose(withTranslation(), withGenobiosisService())(withRouter(ModalEditPhone))