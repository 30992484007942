import React, {useState, useEffect}  from 'react'
import Button from '../../button/button'
import './modal-customer.sass'
import './media-modal-register.sass'
import withGenobiosisService from "../../hoc/with-genobiosis-service";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next'
import compose from "../../../utils/compose";
import {useForm} from "react-hook-form";

const LoginPage = ({genobiosisService, i18n, t})=>{
    const { register, handleSubmit } = useForm()
    const [lang, setLang]= useState(`/${i18n.language}`)
    useEffect(()=>{
        if (i18n.language === 'ro'){
            setLang('')
        }else {
            setLang(`/${i18n.language}`)
        }
    },[i18n.language])
    const onSubmit = (value, event) => {
        event.preventDefault()
        genobiosisService.customerLogin({
            gen_id: value.inputId,
            password: value.inputPassword
        })
            .then(()=>{
                // console.log(data)
                // this.props.history.push(`/${this.props.i18n.language}/my-cabinet`)
                if (typeof window !== 'undefined') {
                    window.location.href = `${window.location.origin}${lang}/my-cabinet`
                }
            })
            .catch(err => console.log(err))
    }

    return (
            <div className="menu-form">
                <form className="form-consult form-register" onSubmit={handleSubmit(onSubmit)}>
                    <h5>{t(`Вход в личный кабинет`)}</h5>
                    <label htmlFor="inputId" className="sr-only" >ID</label>
                    <input  type='text' name="inputId" placeholder={t(`Введите ваш ID`)} className="form-control" ref={register({ required: true })} />

                    <label htmlFor="inputPassword" className="sr-only" >Password</label>
                    <input  type='password' name="inputPassword" className="form-control" placeholder={t('Введите ваш пароль')} ref={register({ required: true })} />

                    <Button click={()=>{}} text={t('Войти')} />
                </form>
            </div>)

}

export default compose(withGenobiosisService(), withTranslation())(withRouter(LoginPage))
