import {logout} from "../utils/utils-customer";

const customersLoaded = (customers) =>{
    return {
        type: 'CUSTOMERS_SUCCESS',
        payload: customers
    }
}
const customersRequested = ()=>{
    return {
        type: 'CUSTOMERS_REQUEST'
    }
}
const customersError = (error)=>{
    return {
        type: 'CUSTOMERS_FAILURE',
        payload: error
    }
}


const fetchCustomers = (genobiosisService, dispatch, page) => () => {
    dispatch(customersRequested());
    genobiosisService.getCustomerLink(page)
        .then((data) => dispatch(customersLoaded(data)))
        .catch((err) => dispatch(customersError(err)));
};


const statusLoaded = (status) =>{
    return {
        type: 'STATUS_SUCCESS',
        payload: status
    }
}
const statusRequested = ()=>{
    return {
        type: 'STATUS_REQUEST'
    }
}
const statusError = (error)=>{
    return {
        type: 'STATUS_FAILURE',
        payload: error
    }
}
const fetchStatus = (genobiosisService, dispatch) => () => {
    dispatch(statusRequested());
    genobiosisService.getStatus()
        .then((data) => dispatch(statusLoaded(data)))
        .catch((err) => dispatch(statusError(err)));
};

const customerDataLoaded = (customer) =>{
    return {
        type: 'CUSTOMER_SUCCESS',
        payload: customer
    }
}
const customerDataRequested = ()=>{
    return {
        type: 'CUSTOMER_REQUEST'
    }
}
const customerDataError = (error)=>{
    return {
        type: 'CUSTOMER_FAILURE',
        payload: error
    }
}
const fetchCustomerData = (genobiosisService, dispatch) => () => {
    dispatch(customerDataRequested());
    genobiosisService.getCustomerData()
        .then((data) => dispatch(customerDataLoaded(data)))
        .catch((err) => {
            dispatch(customerDataError(err))

        });
};
const customerStatusDataLoaded = (customerStatus) =>{
    return {
        type: 'CUSTOMER_STATUS_SUCCESS',
        payload: customerStatus
    }
}
const customerStatusDataRequested = ()=>{
    return {
        type: 'CUSTOMER_STATUS_REQUEST'
    }
}
const customerStatusDataError = (error)=>{
    return {
        type: 'CUSTOMER_STATUS_FAILURE',
        payload: error
    }
}
const fetchCustomerStatusData = (genobiosisService, dispatch) => () => {
    dispatch(customerStatusDataRequested());
    genobiosisService.getCustomerStatus()
        .then((data) => dispatch(customerStatusDataLoaded(data.status[0].id)))
        .catch((err) => dispatch(customerStatusDataError(err)));
};
const customerStatusDataMainLoaded = (customerMainData) =>{
    return {
        type: 'CUSTOMER_MAIN_DATA_SUCCESS',
        payload: customerMainData
    }
}
const customerStatusDataMainRequested = ()=>{
    return {
        type: 'CUSTOMER_MAIN_DATA_REQUEST'
    }
}
const customerStatusDataMainError = (error)=>{
    return {
        type: 'CUSTOMER_MAIN_DATA_FAILURE',
        payload: error
    }
}
const fetchCustomerStatusMainData = (genobiosisService, dispatch) => () => {
    dispatch(customerStatusDataMainRequested());
    genobiosisService.getCustomerMainData()
        .then((data) => {
            dispatch(customerStatusDataMainLoaded(data))
        })
        .catch((err) => {
            dispatch(customerStatusDataMainError(err))
            logout()
            if (typeof window !== 'undefined') {
                window.location.href = `${window.location.origin}/`
            }

        });
};
const customerLKFoodLoaded = (customerLKFood) =>{
    return {
        type: 'LK_FOOD_SUCCESS',
        payload: customerLKFood
    }
}
const customerLKFoodRequested = ()=>{
    return {
        type: 'LK_FOOD_REQUEST'
    }
}
const customerLKFoodError = (error)=>{
    return {
        type: 'LK_FOOD_FAILURE',
        payload: error
    }
}
const fetchCustomerLKFood = (genobiosisService, dispatch) => () => {
    dispatch(customerLKFoodRequested());
    genobiosisService.getFood()
        .then((data) => dispatch(customerLKFoodLoaded(data)))
        .catch((err) => {
            dispatch(customerLKFoodError(err))
            console.log(err)
        });
};

const customerLKDiseaseLoaded = (customerLKDisease) =>{
    return {
        type: 'LK_DISEASE_SUCCESS',
        payload: customerLKDisease
    }
}
const customerLKDiseaseRequested = ()=>{
    return {
        type: 'LK_DISEASE_REQUEST'
    }
}
const customerLKDiseaseError = (error)=>{
    return {
        type: 'LK_DISEASE_FAILURE',
        payload: error
    }
}
const fetchCustomerLKDisease = (genobiosisService, dispatch) => () => {
    dispatch(customerLKDiseaseRequested());
    genobiosisService.getIllness()
        .then((data) => dispatch(customerLKDiseaseLoaded(data)))
        .catch((err) => {
            dispatch(customerLKDiseaseError(err))
            console.log(err)
        });
};
const customerLKAncestralLoaded = (customerLKAncestral) =>{
    return {
        type: 'LK_ANCESTRAL_SUCCESS',
        payload: customerLKAncestral
    }
}
const customerLKAncestralRequested = ()=>{
    return {
        type: 'LK_ANCESTRAL_REQUEST'
    }
}
const customerLKAncestralError = (error)=>{
    return {
        type: 'LK_ANCESTRAL_FAILURE',
        payload: error
    }
}
const fetchCustomerLKAncestral = (genobiosisService, dispatch) => () => {
    dispatch(customerLKAncestralRequested());
    genobiosisService.getInheritedIllness()
        .then((data) => dispatch(customerLKAncestralLoaded(data)))
        .catch((err) => {
            dispatch(customerLKAncestralError(err))
            console.log(err)
        });
};
const customerLKPharmacogeneticLoaded = (customerLKPharmacogenetic) =>{
    return {
        type: 'LK_PHARMACOGENETiC_SUCCESS',
        payload: customerLKPharmacogenetic
    }
}
const customerLKPharmacogeneticRequested = ()=>{
    return {
        type: 'LK_PHARMACOGENETiC_REQUEST'
    }
}
const customerLKPharmacogeneticError = (error)=>{
    return {
        type: 'LK_PHARMACOGENETiC_FAILURE',
        payload: error
    }
}
const fetchCustomerLKPharmacogenetic = (genobiosisService, dispatch) => () => {
    dispatch(customerLKPharmacogeneticRequested());
    genobiosisService.getPharmacogenetic()
        .then((data) => dispatch(customerLKPharmacogeneticLoaded(data)))
        .catch((err) => {
            dispatch(customerLKPharmacogeneticError(err))
            console.log(err)
        });
};
const customerLKPsychogeneticLoaded = (customerLKPsychogenetic) =>{
    return {
        type: 'LK_PSYCHOGENETiC_SUCCESS',
        payload: customerLKPsychogenetic
    }
}
const customerLKPsychogeneticRequested = ()=>{
    return {
        type: 'LK_PSYCHOGENETiC_REQUEST'
    }
}
const customerLKPsychogeneticError = (error)=>{
    return {
        type: 'LK_PSYCHOGENETiC_FAILURE',
        payload: error
    }
}
const fetchCustomerLKPsychogenetic = (genobiosisService, dispatch) => () => {
    dispatch(customerLKPsychogeneticRequested());
    genobiosisService.getPsychogenetics()
        .then((data) => dispatch(customerLKPsychogeneticLoaded(data)))
        .catch((err) => {
            dispatch(customerLKPsychogeneticError(err))
            console.log(err)
        });
};

export {
    // fetchCustomers,
    // fetchStatus,
    statusError,
    fetchCustomerData,
    fetchCustomerStatusData,
    fetchCustomerStatusMainData,
    fetchCustomerLKFood,
    fetchCustomerLKDisease,
    fetchCustomerLKAncestral,
    fetchCustomerLKPharmacogenetic,
    fetchCustomerLKPsychogenetic
};