import React, {Suspense } from 'react';
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import AppContainer from './components/app';
import ErrorBoundry from './components/error-boundry';
import GenobiosisService from './services/genobiosis-service';
import { GenobiosisServiceProvider } from './components/genobiosis-service-context';
import store from './store';
import './i18n';

const genobiosisService = new GenobiosisService();


ReactDOM.render(
    <Provider store={store}>
        <ErrorBoundry>
            <Router>
                <GenobiosisServiceProvider value={genobiosisService}>
                    <Suspense fallback="loading">
                        <AppContainer/>
                    </Suspense>
                </GenobiosisServiceProvider>
            </Router>
        </ErrorBoundry>
    </Provider>,
    document.getElementById('root'))