import React from 'react';
import {withTranslation} from "react-i18next";

const GensData = ({ i18n, t, data }) => {
	return (
        <div>
            {
                data.map((item, idx)=>{
                    return(
                        <div key={idx} className="block">
                            <ul>
                                <li className="gens-main-title">{t(`${item.name}`)}</li>
                                <li className="check-list">
                                    <ul>
                                        {
                                            item.gens.map((val, index)=>{
                                                let active = ''
                                                if(val.status===true){
                                                    active='active'
                                                } else{
                                                    active=''
                                                }
                                                return(
                                                    <li key={index} className={active}><span/></li>

                                                )
                                            })
                                        }
                                    </ul>
                                </li>
                            </ul>
                        </div>

                    )
                })
            }
        </div>
	);
}
export default withTranslation()(GensData)