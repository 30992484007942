import React,{ useState, useEffect } from 'react'
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import {useForm}  from "react-hook-form";
import TrainingsTable from './trainings-table'
const TrainingsCreate = ({ genobiosisService })=>{
    const { register, handleSubmit, errors } = useForm()
    // const [openModal, setOpenModal] = useState(false)
    const [items, setItems] = useState([]);
    useEffect(()=>{
        genobiosisService.trainingsGet()
            .then(data=> {
                if(data.length!==0){
                    const training = data.trainingItems
                    setItems(training)
                }

            })
            .catch(err=> console.log(err))
    },[])
    const onSubmit = (value, event) => {
        event.preventDefault()
        let JSONData = {
            items:[]
        }
        const field = ()=>{
            return{
                date: value[`date`],
                type: value[`type`],
                duration: value[`duration`],
            }
        }

        JSONData.items.push(...items, field())
        let data = JSON.stringify(JSONData)
        // console.log(data)
        if(items.length===0){
            genobiosisService.trainingsPost({
                data
            })
                .then(() => {
                    event.target.reset()
                    getTableData()

                })
                .catch(err => console.log(err))
        }else {
            genobiosisService.trainingsPut({
                data
            })
                .then(() => {
                    event.target.reset()
                    getTableData()

                })
                .catch(err => console.log(err))
        }


    }
    const getTableData = ()=>{
        genobiosisService.trainingsGet()
            .then(data=> {
                const training = data.trainingItems
                setItems(training)
            })
            .catch(err=> console.log(err))
    }
    return(
        <div className="create-food-wrapped">
            <form className="form-consult" onSubmit={handleSubmit(onSubmit)}>
                <div className='section-wrapped'>
                    <span>
                        <label htmlFor='date'>Дата</label>
                        <input type='date' name='date' placeholder="Дата"
                               ref={register({required: true})}
                            // defaultValue={'ISi410'+index+idx}
                        />
                        {errors['date'] && <p className="error-text">{'*required'}</p>}
                    </span>
                    <span>
                        <label htmlFor='type'>Тип</label>
                        <input type='text' name='type' placeholder="Тип"
                               ref={register({required: true})}
                            // defaultValue={'A'+index+idx}
                        />
                        {errors['type'] && <p className="error-text">{'*required'}</p>}
                    </span>
                    <span>
                        <label htmlFor='duration'>Продолжительность</label>
                        <input type='text' name='duration' placeholder="Продолжительность"
                               ref={register({required: true})}
                            // defaultValue={'C'+index+idx}
                        />
                        {errors['duration'] && <p className="error-text">{'*required'}</p>}
                    </span>
                    <button type="submit">Отправить</button>
                </div>
            </form>
            <TrainingsTable items={items}/>
        </div>
    )
}
export default withGenobiosisService()(TrainingsCreate)