import React,{ Component } from 'react';

import {Route, Switch, Link, withRouter} from 'react-router-dom';

import './customer-page.sass';
import withGenobiosisService from "../../hoc/with-genobiosis-service";


import { fetchCustomerStatusMainData } from '../../../actions';
import  compose  from '../../../utils/compose';

import Spinner from '../../icons/Spinner';
import ErrorIndicator from '../../error-indicator';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import MainCabinetPage from './main-cabinet-page'



class CustomerCabinet extends Component{
    state= {
        image: this.props.customerMainData.avatar,
    }
    componentDidMount(){
        this.props.fetchCustomerStatusMainData()
    }



    render(){
        const { customerMainData, loadingMainData, errorMainData } = this.props;
        if (loadingMainData) {
            return <Spinner />;
        }

        if (errorMainData) {
            return <ErrorIndicator />;
        }
        return (
            <section className="cabinet-page">
                <MainCabinetPage customerMainData={customerMainData}/>
            </section>
        )
    }

}
const mapStateToProps = ({ customerMainData, loadingMainData, errorMainData }) => {
    return { customerMainData, loadingMainData, errorMainData };
};

const mapDispatchToProps = (dispatch, { genobiosisService }) => {
    return {
        fetchCustomerStatusMainData: fetchCustomerStatusMainData(genobiosisService, dispatch)
    };
};


export default compose(withGenobiosisService(), withTranslation(), connect(mapStateToProps, mapDispatchToProps))(CustomerCabinet)