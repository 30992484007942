import React, {useState} from 'react';
import './chronotype-page.sass';
import { withTranslation } from 'react-i18next';

import Bird from './img/bird.svg'
import Owl from './img/owl.svg'

import ClockBlock from './clock-block/clock-block'

const ChronotypesPage = ({ t}) => {
	const  time = '08:04';
	return(
		<div className="chronotype-page">
			<h3 className="h3">{t(`Хронотип`)}</h3>
            <div className="chronotype-wrapper">
                <div className="ockclock-section">
                    <div className="with-text">
                        <div className="h4">{t(`Ваши результаты`)}: </div>
                        <p>{t(`Мы проанализировали 450 ваших генетических маркеров, для того чтобы определить какое время пробуждения вам подходит больше всего. Генетический анализ показал, что вы можете быть как совой так и жаворонком. Это будет зависеть от вашего образа жизни. Но самое оптимальное время пробуждения для вас`)}:</p>
                        <div className="clock-after-text">
                            <div className="time">{time}</div>
                            <span className="day-time">{t(`утра`)}</span>
                        </div>
                    </div>
                    <div className="clock-block">
                        <ClockBlock time={time} />
                    </div>
                </div>
                <div className="lists">
                    <div className="list">
                        <div className="pic-block pic-block-bird">
                            <img src={Bird} alt=""/>
                        </div>
                        <ul>
                            <li className="title">{t(`Жаворонки`)}</li>
                            <li>{t(`рано просыпаются;`)}</li>
                            <li>{t(`пик физической активности в первой половине дня;`)}</li>
                            <li>{t(`лучше справляются с логическими заданиями утром;`)}</li>
                            <li>{t(`склонны рано ложиться спать`)}</li>
                        </ul>
                    </div>
                    <div className="list">
                        <div className="pic-block pic-block-owl">
                            <img src={Owl} alt=""/>
                        </div>
                        <ul>
                            <li className="title">{t(`Совы`)}</li>
                            <li>{t(`рано просыпаются;`)}</li>
                            <li>{t(`пик физической активности в первой половине дня;`)}</li>
                            <li>{t(`лучше справляются с логическими заданиями утром;`)}</li>
                            <li>{t(`склонны рано ложиться спать`)}</li>
                        </ul>
                    </div>
                </div>
            </div>
		</div>
	);
};
export default withTranslation()(ChronotypesPage)