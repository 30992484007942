import React, {Component}  from 'react'
import  compose  from '../../../../utils/compose';
import { Modal } from 'react-bootstrap';
import '../../login-page/modal-customer.sass'
import withGenobiosisService from "../../../hoc/with-genobiosis-service";
import { withRouter } from "react-router-dom";
import {withTranslation} from "react-i18next";

class ModalEditPass extends Component{
    state = {
        password: '',
        newPassword: '',
        error: ''
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = event => {
        event.preventDefault()
        const { genobiosisService, onClose} = this.props
        genobiosisService.updatePass({
                    old_password:  this.state.password,
                    new_password: this.state.newPassword
                }
            )
            .then(()=>{
                    onClose()
            })
            .catch(err => console.log(err))


        //


    }
    render() {
        const { t } = this.props
        return (
            <Modal show={this.props.open} onHide={this.props.onClose} centered size="xl">
                <div className="menu-form">
                    <Modal.Header closeButton/>
                    <form className="form-consult" onSubmit={this.handleSubmit}>
                        <h5>{t(`Смена пароля`)}</h5>
                        <label htmlFor="inputPassword" className="sr-only">Password</label>
                        <input type="password" id="inputPassword" name='password' className="form-control"
                               placeholder={t(`Введите ваш старый пароль`)} required
                               value={this.state.password}
                               onChange={this.handleChange}
                        />
                        <label htmlFor="inputNewPassword" className="sr-only">New Password</label>
                        <input type="password" id="inputNewPassword" name='newPassword' className="form-control"
                               placeholder={t(`Введите ваш новый пароль`)}
                               required autoFocus
                               value={this.state.newPassword}
                               onChange={this.handleChange}
                        />


                        <button className="button" type="submit">{t(`Сменить пароль`)}</button>
                    </form>
                    {this.state.error && <p>Invalid credentials</p>}
                </div>
            </Modal>
        )
    }
}
export default compose(withTranslation(), withGenobiosisService())(withRouter(ModalEditPass))