import axios from 'axios'

export default class GenobiosisService {

    _apiBase = 'https://api.genobiosis.com/api/v1'

    axiosInstanceLead = axios.create({
        baseURL: this._apiBase,
        // timeout: 5000,

    });
    axiosGeoIp = axios.create({
        baseURL: 'https://geoip.apizero.stream'
    })
    axiosInstanceCustomer = axios.create({
        baseURL: this._apiBase,
        // timeout: 5000,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('customer_token')}`,
            // Authorization: `Bearer ${typeof window!==undefined ? window.localStorage.getItem('customer-token'):''}`,
            // 'Content-Type': 'application/x-www-form-urlencoded'
        }
    });


    customerLogin = async (data) => {
        const res = await this.axiosInstanceCustomer.post(`/auth/login`, data)
        const token = res.data.access_token
        localStorage.setItem('customer_token', token);
        return await res
    }

    customerLogout = async () => {
        const res = await this.axiosInstanceCustomer.post('/auth/logout')
        return await res
    }
    getCustomerName = async () => {
        const res = await this.axiosInstanceCustomer.get(`/auth/me`)
        return res
    }
    getCustomerData = async () => {
        const res = await this.axiosInstanceCustomer.get(`/profile`)

        return this._transformCustomerData(res.data.data)
        // return res.data.data
    }
    getCustomerMainData = async () =>{
        const res = await this.axiosInstanceCustomer.get(`/profile`)
        // console.log(res.status)
        return this._transformCustomerMainData(res.data.data)
    }
    getCustomerStatus = async () => {
        const res = await this.axiosInstanceCustomer.get(`/profile`)
        // console.log(res.data.data)
        return res.data.data
        // return res.data.data
    }

    updateCustomerData = async (data) => {
        const resp = await this.axiosInstanceCustomer.put(`/profile`, data)
        // console.log(resp)
        return resp
    }
    updatePass = async (data)=>{
        const res = await this.axiosInstanceCustomer.post(`/change/password`, data)
        // console.log(resp)
        return res
    }
    loadAvatar = async (data)=>{
        const res = await this.axiosInstanceCustomer.post(`/avatar`, data)
        // console.log(resp)
        return res
    }
    postLead = async (data)=>{
        const res = await this.axiosInstanceLead.post(`/lead`, data)
        // console.log(resp)
        return res
    }
    subscribeBlog = async (email) => {
      const res = await this.axiosInstanceLead.post(`/subscribe`, {email: email})

      return res
    }
    geoIpInfo = async() =>{
        return await axios.get('https://geoip.apizero.stream')
    }
    getFood = async () => {
        const res = await this.axiosInstanceCustomer.get(`/food`)
        // console.log(res.data.data)
        // return res
        return res.data.data
    }
    getSkin = async () => {
        const res = await this.axiosInstanceCustomer.get(`/skin`)
        // console.log(res.data.data)
        return res
        // return res.data.data
    }
    getIllness = async () => {
        const res = await this.axiosInstanceCustomer.get(`/illness`)
        // console.log(res.data.data)
        return res.data.data
    }
    getInheritedIllness = async () => {
        const res = await this.axiosInstanceCustomer.get(`/inherited/illness`)
        // console.log(res.data.data)
        return res.data.data
    }
    getChronotype = async () => {
        const res = await this.axiosInstanceCustomer.get(`/chronotype`)
        // console.log(res.data.data)
        return res
        // return res.data.data
    }
    getPharmacogenetic = async () => {
        const res = await this.axiosInstanceCustomer.get(`/pharmacogenetic`)
        // console.log(res.data.data)
        return res.data.data
    }
    getPsychogenetics = async () => {
        const res = await this.axiosInstanceCustomer.get(`/psychogenetics`)
        // console.log(res.data.data)
        return res.data.data
    }
    getPhysicalskills = async () => {
        const res = await this.axiosInstanceCustomer.get(`/physicalskills`)
        // console.log(res.data.data)
        return res
        // return res.data.data
    }
    trainingsPost = async (data)=>{
        const res = await this.axiosInstanceCustomer.post(`/trainings`, data)
        // console.log(resp)
        return res
    }
    trainingsPut = async (data)=>{
        const res = await this.axiosInstanceCustomer.put(`/trainings`, data)
        // console.log(resp)
        return res
    }
    trainingsGet = async () => {
        const res = await this.axiosInstanceCustomer.get(`/trainings`)
        // console.log(res.data.data)
        // return res
        return res.data.data
    }

    _transformCustomerData = (customer) => {
        return {
            id: customer.id,
            surname: customer.surname,
            given_name: customer.given_name,
            patronymic: customer.patronymic,
            email: customer.email,
            phone: customer.phone,
            gen_id: customer.gen_id,
            birthday: customer.birthday,
            deliver_address: customer.deliver_address,
            register_address: customer.register_address,
            passport_data: customer.passport_data
        }
    }
    _transformCustomerMainData = (customer) => {
        return {
            surname: customer.surname,
            given_name: customer.given_name,
            status: customer.status[0].name,
            avatar: customer.avatar ? 'https://api.genobiosis.com/customer/' + customer.avatar : 'https://api.genobiosis.com/img/no-avatar.svg'
        }

    }
}