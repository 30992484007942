import React, { useState } from 'react';
import MediaQuery from 'react-responsive';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';

import '../multi-disease/multi-disease.sass';
import './ancestral-risk.sass';
import { data } from './data';
import Pagination from "../../../../Pagination.jsx";
import { withTranslation } from 'react-i18next';


const AncestralRisk = ({i18n, t, customerAncestralLk}) => {
    // console.log(customerAncestralLk)
    const [currentPage, setCurrentPage] = useState(1);
    const [dataLng, setDataLng] = useState(data);
    
    const changePage = (page) => {
      setCurrentPage(page);
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        }
    };
    const itemPerPage = 10;
    const numFromSplice = currentPage < 2 ? 0 : ((currentPage - 1) * itemPerPage);
    const numToSplice = currentPage < 2 ? itemPerPage : (currentPage * itemPerPage);
    const filteredListNews = dataLng.slice(numFromSplice,numToSplice);
    const filterData = customerAncestralLk.inheritedIllnessItems.slice(numFromSplice,numToSplice);

	return(
		<div className="multi-risks">
            {filteredListNews.map((item, index) => {
                const getData = filterData[index]
                return (
					<div>
                        <MediaQuery minWidth={577}>
                          <div className="multi-risk multi-risk--update" key={index}>
                            <div className="text">
                              <h4 className="h4">{item.number}<span className="title">{t(`${getData.name}`)}</span></h4>
                            </div>
                            <div className="multi-risk-table">
                              <ul className="multi-risk-table-box title-box">
                                <li className="multi-risk-table-title">{t(`Мутация`)}:</li>
                                <li className="multi-risk-table-title">{t(`ваш генотип`)}:</li>
                                <li className="multi-risk-table-title">{t(`Аллель риска`)}:</li>
                                <li className="multi-risk-table-title">{t(`Аллель нормы`)}:</li>
                              </ul>
                              {getData.inheritedIllnessItemInfo.map((resultsList, indexResults) => {
                                return (
                                  <ul key={indexResults} className="multi-risk-table-box">
                                    <li className="multi-risk-table-title mutation">{resultsList.mutation}</li>
                                    <li className="multi-risk-table-title genotype">{resultsList.genotype}</li>
                                    <li className="multi-risk-table-title risk">{resultsList.risk_allele}</li>
                                    <li className="multi-risk-table-title norm">{resultsList.normal_allele}</li>
                                  </ul>
                                )
                              })}
                            </div>
                          </div>
                        </MediaQuery>
                        <MediaQuery maxWidth={576}>
                          <div className="multi-risk multi-risk--update" key={index}>
                            <Accordion allowZeroExpanded={true}>
                              <AccordionItem>
                                  <AccordionItemHeading>
                                    <AccordionItemButton>
                                      <div className="text">
                                        <h4 className="h4">{item.number}<span className="title">{t(`${getData.name}`)}</span></h4>
                                      </div>
                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                    <div className="multi-risk-table">

                                      {getData.inheritedIllnessItemInfo.map((resultsList, indexResults) => {
                                        return (
                                          <div>
                                            <div className="d-flex">
                                              <ul className="multi-risk-table-box title-box">
                                                <li className="multi-risk-table-title">{t(`Мутация`)}:</li>
                                              </ul>
                                              <ul key={indexResults+'i'} className="multi-risk-table-box">
                                                <li className="multi-risk-table-title mutation">{resultsList.mutation}</li>
                                              </ul>
                                            </div>
                                            <div className="d-flex">
                                              <ul className="multi-risk-table-box title-box">
                                                <li className="multi-risk-table-title">{t(`ваш генотип`)}:</li>
                                              </ul>
                                              <ul key={indexResults+'m'} className="multi-risk-table-box">
                                                <li className="multi-risk-table-title genotype">{resultsList.genotype}</li>
                                              </ul>
                                            </div>
                                            <div className="d-flex">
                                              <ul className="multi-risk-table-box title-box">
                                                <li className="multi-risk-table-title">{t(`Аллель риска`)}:</li>
                                              </ul>
                                              <ul key={indexResults+'b'} className="multi-risk-table-box">
                                                <li className="multi-risk-table-title risk">{resultsList.risk_allele}</li>
                                              </ul>
                                            </div>
                                            <div className="d-flex">
                                              <ul className="multi-risk-table-box title-box">
                                                <li className="multi-risk-table-title">{t(`Аллель нормы`)}:</li>
                                              </ul>
                                              <ul key={indexResults+'v'} className="multi-risk-table-box">
                                                <li className="multi-risk-table-title norm">{resultsList.normal_allele}</li>
                                              </ul>
                                            </div>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </AccordionItemPanel>
                              </AccordionItem>
                            </Accordion>
                          </div>
                        </MediaQuery>
                    </div>
                )
			})}
        
			<Pagination
                onChange={(event) => {
                    changePage(event);
                }}
                itemsPerPage={itemPerPage}
                currentPage={currentPage}
                totalItems={data.length}
            />
		</div>
	);
};
export default withTranslation()(AncestralRisk)