import React, { useState } from 'react';
import './multi-disease.sass';
// import {Route, Switch, Link, withRouter} from 'react-router-dom';

import { data } from './data';
import Pagination from "../../../../Pagination.jsx";
// import { useTranslation } from 'react-i18next';
import MultiDiseaseItem from "./multi-disease-item";

const MultiRisk = ({customerDiseaseLk}) => {
    // const { i18n, t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const [dataLng, setDataLng] = useState(data);
    
    const changePage = (page) => {
      setCurrentPage(page);
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        }
    };
    const itemPerPage = 10;
    const numFromSplice = currentPage < 2 ? 0 : ((currentPage - 1) * itemPerPage);
    const numToSplice = currentPage < 2 ? itemPerPage : (currentPage * itemPerPage);
    const filteredListNews = dataLng.slice(numFromSplice,numToSplice);
    const filterData = customerDiseaseLk.illnessItems.slice(numFromSplice,numToSplice);
	
	return(
		<div className="multi-risks">
			{filteredListNews.map((item, index) => {
			    const getData = filterData[index]
                if(item.title===getData.name) {
                    return <MultiDiseaseItem key={index} disease={item} getData={getData}/>
                }

			})}
			<Pagination
              onChange={(event) => {
                changePage(event);
              }}
              itemsPerPage={itemPerPage}
              currentPage={currentPage}
              totalItems={data.length}
            />
		</div>
	);
};
export default MultiRisk