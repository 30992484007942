import React from 'react'
import './info-block.sass'
import img from './info.svg'
import { withTranslation } from 'react-i18next';

const InfoBlock = ({t}) => {
    return(
        <div className="info-block">
			<img className="info-block--img" src={img} alt="Genobiosis" />
            <p>{t(`В таблице ниже находится перечень болезней, риски которых мы у Вас определили. Патологии поделены на 2 типа мультифакториальные (генетический фактор - ведущий, но не единственный) и наследственные (зависят только от наличия дефектов в генах). Нажав на название каждого из мультифакториальных заболеваний, Вы получите более подробную информацию о нем и исследованных генах, отвечающих за конкретную патологию.`)}</p>
		</div>
    )
}

export default withTranslation()(InfoBlock)