import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import './disease.sass'
import { Link } from 'react-router-dom';
import Graphic from '../graphic/graphic';
// import { data } from './data'
import WarnIco from './warn.svg'
import SearchIco from './search-ico.svg'
import DiagnosticIco from './diagnostic-ico.svg'
import FirstAidIco from './first-aid-ico.svg'

const DiseaseItem = ({data, i18n, t, getData}) => {
	const [lang, setLang]= useState(`/${i18n.language}`)
	useEffect(()=>{
		if (i18n.language === 'ro'){
			setLang('')
		}else {
			setLang(`/${i18n.language}`)
		}
	},[i18n.language])
	return(
					<section className="diseaseWrap" >
						<Link to={`${lang}/my-cabinet/disease-risk`} className="link">{t(`Назад`)}</Link> 
						<div className="diseaseWrap-title">
							<h3 className="h3">{t(`${getData.name}`)}</h3>
							<p>{t(`${data.description}`)}</p>
						</div>
						<div className="diseaseWrap-ui">
							<div className="diseaseWrap-ui-graphic">
							<Graphic items={getData.illness_probability[0]}/>
							</div>
							<div className="diseaseWrap-ui-desc">
								<div className="vitaminsTab-content-recommendation">
									<h3 className="h3">
										<span className="icon-like"></span>
										<span>{t(`Расшифровка и рекомендации генетика`)}</span>
									</h3>
									<p className="vitaminsTab-content-description-text">{t(`${getData.recommendations}`)}</p>
								</div>
								<ul className="vitaminsTab-content-list-box">
									<h3 className="h3">{t(`Врач, который занимается лечением`)}:</h3>
									{data.doctors.map((list, indexGroup) => {
										return (
											<li key={indexGroup} className="vitaminsTab-content-description-text">{t(`${list.text}`)}</li>
										)
									})}
								</ul>
							</div>
						</div>
						<div className="vitaminsTab-content-list vitaminsTab-content-list-2 border-padding">
							<div className="d-flex">
								<div className="vitaminsTab-content-list-img vitaminsTab-content-list-img-colorPink">
									<img src={WarnIco} alt=""/>
								</div>
								<h3 className="h3">{t(`Причины/факторы риска`)}</h3>
							</div>
							<div className="vitaminsTab-content-list--update">
								{data.causes.map((list, indexGroup) => {
									return (
										<ul className="vitaminsTab-content-list-box" key={indexGroup}>
											{list.causesList.map((item, index) => {
												return (
													<li className="vitaminsTab-content-description-text" key={index}>{t(`${item.text}`)}</li>
												)
											})}
										</ul> 
									)
								})}
							</div>
						</div>
						<div className="vitaminsTab-content-list vitaminsTab-content-list-2 border-padding">
							<div className="d-flex">
								<div className="vitaminsTab-content-list-img vitaminsTab-content-list-img-colorBlue">
									<img src={SearchIco} alt=""/>
								</div>
								<h3 className="h3">{t(`Симптомы`)}</h3>
							</div>
							<div className="vitaminsTab-content-list--update">
								{data.symptoms.map((list, indexGroup) => {
									return (
										<ul className="vitaminsTab-content-list-box" key={indexGroup}>
											{list.symptomsList.map((item, index) => {
												return (
													<li className="vitaminsTab-content-description-text" key={index}>{t(`${item.text}`)}</li>
												)
											})}
										</ul> 
									)
								})}
							</div>
						</div>
						<div className="vitaminsTab-content-list vitaminsTab-content-list-2 border-padding">
							<div className="d-flex">
								<div className="vitaminsTab-content-list-img vitaminsTab-content-list-img-colorBlue">
									<img src={DiagnosticIco} alt=""/>
								</div>
								<h3 className="h3">{t(`Диагностика`)}</h3>
							</div>
							<div className="vitaminsTab-content-list--update">
								{data.diagnosis.map((list, indexGroup) => {
									return (
										<ul className="vitaminsTab-content-list-box" key={indexGroup}>
											{list.diagnosisList.map((item, index) => {
												return (
													<li className="vitaminsTab-content-description-text" key={index}>{t(`${item.text}`)}</li>
												)
											})}
										</ul> 
									)
								})}
							</div>
						</div>
						<div className="vitaminsTab-content-list vitaminsTab-content-list-2 border-padding">
							<div className="d-flex">
								<div className="vitaminsTab-content-list-img">
									<img src={FirstAidIco} alt=""/>
								</div>
								<h3 className="h3">{t(`Лечение`)}</h3>
							</div>
							<div className="vitaminsTab-content-list--update">
								{data.treatment.map((list, indexGroup) => {
									return (
										<ul className="vitaminsTab-content-list-box" key={indexGroup}>
											{list.treatmentList.map((item, index) => {
												return (
													<li className="vitaminsTab-content-description-text" key={index}>{t(`${item.text}`)}</li>
												)
											})}
										</ul> 
									)
								})}
							</div>
						</div>
						<Link to={`${lang}/my-cabinet/disease-risk`} className="link">{t(`Назад`)}</Link>
					</section>
				)
}
export default withTranslation()(DiseaseItem)