import React, { Component } from 'react';
import Clock from 'react-clock';
import './clock-block.sass'

class ClockBlock extends Component {

    render() {
        const {time}=this.props
        return (
            <div className="clock">
                <Clock
                    value={time} 
                    renderNumbers={true}
                    renderSecondHand={false}
                    size={215}
                    hourHandWidth={4}
                    hourHandLength={45}
                    minuteHandWidth={1}
                    minuteHandLength={61}
                />
            </div>
        );
    }
}
export default ClockBlock