import React, {useEffect, useState} from 'react'
import PsychogeneticsPageWrapped from "./psychogenetics-page/psychogenetics-page-wrapped";
import DiseaseRisk from "./disease-risks/disease-risks";
import StatusPage from "./status-page";
import PharmacogeneticsPage from "./pharmacogenetics/pharmacogenetics";
import SettingPage from "./settings-page/settings-page";
import SkinPassport from "./skin-passport/skin-passport";
import NutritionPage from "./nutrition-page/nutrition-page";
import ChronotypePage from "./chronotype-page/chronotype-page";
import ProgenitorPage from "./progenitor-page/progenitor-page";
import Button from '../../button/button';
import {Route, Switch, Link, withRouter} from 'react-router-dom';
import './customer-page.sass';
import withGenobiosisService from "../../hoc/with-genobiosis-service";
import {logout} from "../../../utils/utils-customer";
import {withTranslation} from "react-i18next";
import compose from "../../../utils/compose";
import NewAvatar from './new-avatar'
import TrainingsBlock from "./physical-skills/trainings-block";
import Test from './test'
const MainCabinetPage = ({genobiosisService, customerMainData, t, i18n: {language}, history})=>{
    const [lang, setLang]= useState(`/${language}`)
    const [links, setLinks]= useState(true)
    useEffect(()=>{
        if (language === 'ro'){
            setLang('')
        }else {
            setLang(`/${language}`)
        }
    },[language])
    useEffect(()=>{
        genobiosisService.getFood()
            .then((data)=>{
                if(data.length===0){
                    setLinks(false)
                }
            })
            .catch(err=>console.log(err))
    },[])
    const logOut=(e)=>{
        e.preventDefault()
        genobiosisService.customerLogout()
            .then(()=>{
                history.push(`${lang}/login-page`)

            })
            .catch(err =>console.log(err))
        logout()
        // history.push(`${lang}`)
        // if (typeof window !== 'undefined') {
        //     window.location.href = `${window.location.origin}${lang}`
        // }

    }
    return (
        <div className="container-fluid main-wrapp">
            <div className="row">
                <nav className="col-md-2 sidebar">
                    <div className="sidebar-sticky">
                        <div className="nav flex-column">
                            <NewAvatar avatar={customerMainData.avatar}/>
                            <div className="d-flex flex-column align-items-center head-info">
                                <h3>{customerMainData.given_name} {customerMainData.surname}</h3>
                                <p>{t(customerMainData.status)}</p>
                            </div>
                            <div className="d-flex flex-column align-items-center nav-item">
                                <Link to={`${lang}/my-cabinet/settings`}>{t(`Настройки профиля`)}
                                </Link>
                            </div>
                            <div className="d-flex flex-column align-items-center nav-item">
                                <Link to={`${lang}/my-cabinet`}>{t(`Скачать весь анализ ДНК в PDF`)}</Link>
                            </div>
                            {
                                links ?
                                    <div className="d-flex flex-column p-3 align-items-start nav-item">
                                        <Link to={`${lang}/my-cabinet/nutrition-page`} >
                                            {t(`Питание`)}
                                        </Link>
                                        <Link to={`${lang}/my-cabinet/physicalskills`} >
                                            {t(`Фитнес и спорт`)}
                                        </Link>
                                        <Link to={`${lang}/my-cabinet/skin-passport`} >
                                            {t(`Паспорт кожи`)}
                                        </Link>
                                        <Link to={`${lang}/my-cabinet/disease-risk`} >
                                            {t(`Риски болезней`)}
                                        </Link>
                                        <Link to={`${lang}/my-cabinet/psychogenetics`} >
                                            {t(`Психогенетика`)}
                                        </Link>
                                        <Link to={`${lang}/my-cabinet/chronotype`} >
                                            {t(`Хронотип`)}
                                        </Link>
                                        <Link to={`${lang}/my-cabinet/progenitor`} >
                                            {t(`Происхождение`)}
                                        </Link>
                                        <Link to={`${lang}/my-cabinet/pharmacogenetics`} >
                                            {t(`Эффективность лекарств`)}
                                        </Link>
                                    </div>
                                    :
                                    <div className="menu-def d-flex flex-column p-3 align-items-start nav-item">
                                        <p>
                                            {t(`Питание`)}
                                        </p>
                                        <p>
                                            {t(`Фитнес и спорт`)}
                                        </p>
                                        <p>
                                            {t(`Паспорт кожи`)}
                                        </p>
                                        <p>
                                            {t(`Риски болезней`)}
                                        </p>
                                        <p>
                                            {t(`Психогенетика`)}
                                        </p>
                                        <p>
                                            {t(`Хронотип`)}
                                        </p>
                                        <p>
                                            {t(`Происхождение`)}
                                        </p>
                                        <p>
                                            {t(`Эффективность лекарств`)}
                                        </p>
                                    </div>
                            }



                        </div>
                    </div>
                </nav>
                <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4 main-cont main-cabinet-bg">
                    <nav className="navbar  flex-md-nowrap nawb-header">
                        <div className="title-wrapp">
                            <h2>{t(`Личный кабинет`)}</h2>
                            <div className="link-to-blog">
                                <Link to={`${lang}/my-cabinet`}>{t(`Личный кабинет`)} -</Link>
                                <p>&nbsp;{t(`Настройки профиля`)}</p>
                            </div>
                        </div>
                        <Button click={logOut} text={'Log Out'} />
                    </nav>

                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                        <Switch>
                            <Route
                                path={`${lang}/my-cabinet`}
                                component={StatusPage}
                                exact/>
                            <Route
                                path={`${lang}/my-cabinet/settings`}
                                component={SettingPage}
                            />

                            <Route
                                path={`${lang}/my-cabinet/skin-passport`}
                                component={SkinPassport}
                            />
                            <Route
                                path={`${lang}/my-cabinet/nutrition-page`}
                                component={NutritionPage}
                            />

                            <Route
                                path={`${lang}/my-cabinet/disease-risk/`}
                                component={DiseaseRisk}
                            />
                            <Route
                                path={`${lang}/my-cabinet/psychogenetics`}
                                component={PsychogeneticsPageWrapped}
                            />
                            <Route
                                path={`${lang}/my-cabinet/chronotype`}
                                component={ChronotypePage}
                            />
                            <Route
                                path={`${lang}/my-cabinet/progenitor`}
                                component={ProgenitorPage}
                            />
                            <Route
                                path={`${lang}/my-cabinet/pharmacogenetics`}
                                component={PharmacogeneticsPage}
                            />
                            <Route
                                path={`${lang}/my-cabinet/chronotype`}
                                component={Test}
                            />
                            <Route
                                path={`${lang}/my-cabinet/physicalskills`}
                                component={TrainingsBlock}
                            />

                        </Switch>
                    </div>

                </main>
            </div>
        </div>
    )
}
export default compose(withGenobiosisService(), withTranslation())(withRouter(MainCabinetPage))