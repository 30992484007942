import React from 'react'

import v1_img from './img/plate.png'
import v2_img from './img/clock.png'


import './habits.sass'
// import { withTranslation } from 'react-i18next'

const Habits = ({customerFoodLk}) => {
    // console.log(customerFoodLk)
    return(
                <div className="intoPading">
                    <div className="vitamins-container">
                        <div className="vitaminsTab-content">
                                    <div className="vitaminsTab-content-title">
                                        <div className="vitaminsTab-content-title-name color-ninth">
                                            <h3 className="h3">Частые перекусы</h3>
                                            <div className="vitaminsTab-bar">
                                                <div className="vitaminsTab-bar-line" style={{'width':customerFoodLk.snacksNeeds.percent+'%'}}></div>
                                            </div>
                                        </div>
                                        <div className="vitaminsTab-content-title-info">
                                            <p className="vitaminsTab-content-title-info-title">Риск:</p>
                                            <p className="vitaminsTab-content-title-info-desc">{customerFoodLk.snacksNeeds.chance}</p>
                                        </div>
                                    </div>
                                    <div className="vitaminsTab-content-description border-padding">
                                        <img className="vitaminsTab-content-description-img" src={v1_img} alt="vitamins" />
                                        <div>
                                            <h3 className="h3">Описание</h3>
                                            <p className="vitaminsTab-content-description-text">Для обеспечения хорошего обмена веществ рекомендуется придерживаться схемы дробного питания, которая подразумевает частое употребление пищи небольшими порциями. Это способствует нормальной работе всех органов системы пищеварения организм вырабатывает достаточное количество ферментов, необходимых для расщепления пищи и усвоения полезных веществ. При таком подходе человек не испытывает чувство голода и не переедает, соответственно, риск повышения индекса массы тела снижается.</p>
                                        </div>
                                    </div>
                                    <div className="vitaminsTab-content-list border-padding">
                                        <div className="vitaminsTab-content-list-img">
                                            <span>—</span>
                                        </div>
                                        <ul className="vitaminsTab-content-list-box">
                                            <h3 className="h3">Гены и пищевые привычки</h3>
                                            <p className="vitaminsTab-content-description-text">Каждый этап процесса пищеварения в организме контролируется определенными генами, мутации которых способны нарушить привычный обмен веществ - у человека возникает непреодолимое желание кушать все, не обращая внимания на энергетическую ценность продуктов.  Ген FABP2 отвечает за доставку и усвоение всех насыщенных жирных кислот, но определенные его дефекты способствуют набору лишнего веса за счёт быстрого усвоения жиров в кишечнике. Ген ADRB2 регулирует скорость расщепления сахара в мышцах и печени, а TCF7L2 - выработку инсулина в поджелудочной железе. Мутация последнего может стать причиной развития диабета. Ген CD36 помогает усваивать жирную пищу. Если ген поврежден, человек может употреблять много вредных жиров, чем нанесет сильный ущерб организму. Склонность к частым перекусам контролируется геном MC4R.</p>
                                        </ul>
                                    </div>
                                    <div className="vitaminsTab-content-list border-padding">
                                        <div className="vitaminsTab-content-list-img plus">
                                            <span className="icon-plus"></span>
                                        </div>
                                        <ul className="vitaminsTab-content-list-box">
                                            <h3 className="h3">Наследственность и питание</h3>
                                            <p className="vitaminsTab-content-description-text">Генетические тесты позволяют узнать некоторые особенностях организма, например, оценить силовые качества и выносливость, индивидуальную скорость восстановления после физических нагрузок, а также специфике метаболизма. Неправильное питание отражается на фигуре, поэтому важно скорректировать пищевое поведение, отказаться от вредных привычек, в числе которых и психологическая зависимость от еды.</p>
                                            <p className="vitaminsTab-content-description-text">Если у человека есть желание и необходимость сбросить лишний вес, тогда знание своих генетических предрасположенностей поможет понять причину, вызывающую тягу к сладкому или высококалорийной пище. Склонность к перееданию или частым обильным перекусам может быть вызвана медленной работой сигнальных систем, контролирующих чувство сытости и голода.</p>
                                        </ul>
                                    </div>
                                    <div className="vitaminsTab-content-result">
                                        <h3 className="h3">Ваши результаты</h3>
                                        <ul>
                                            <li className="vitaminsTab-content-description-text">Ген</li>
                                            <li className="vitaminsTab-content-description-text">Полиморфизм</li>
                                            <li className="vitaminsTab-content-description-text">Ваш тип</li>
                                        </ul>
                                        {
                                            customerFoodLk.snacksNeeds.results.map((data, idx) => {
                                                return (
                                                    <ul key={idx}>
                                                        <li className="vitaminsTab-content-description-text">{data.gene}</li>
                                                        <li className="vitaminsTab-content-description-text">{data.polymorphism}</li>
                                                        <li className="vitaminsTab-content-description-text">{data.type}</li>
                                                    </ul>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="vitaminsTab-content-recommendation">
                                        <h3 className="h3">
                                            <span className="icon-like"></span>
                                            <span>Расшифровка и рекомендации генетика</span>
                                        </h3>
                                        <p className="vitaminsTab-content-description-text">{customerFoodLk.snacksNeeds.recommendations}</p>
                                    </div>
                        </div>
                    </div>
                    <div className="vitamins-container">    
                        <div className="vitaminsTab-content">
                                    <div className="vitaminsTab-content-title">
                                        <div className="vitaminsTab-content-title-name color-fourth">
                                            <h3 className="h3">Потребность в разгрузочных днях</h3>
                                            <div className="vitaminsTab-bar">
                                                <div className="vitaminsTab-bar-line" style={{'width':customerFoodLk.fastingDays.percent+'%'}}></div>
                                            </div>
                                        </div>
                                        <div className="vitaminsTab-content-title-info">
                                            <p className="vitaminsTab-content-title-info-title">Потребность:</p>
                                            <p className="vitaminsTab-content-title-info-desc">{customerFoodLk.fastingDays.need}</p>
                                        </div>
                                    </div>
                                    <div className="vitaminsTab-content-description border-padding">
                                        <img className="vitaminsTab-content-description-img" src={v2_img} alt="vitamins" />
                                        <div>
                                            <h3 className="h3">Описание</h3>
                                            <p className="vitaminsTab-content-description-text">Постоянная перегрузка организма жирной и высококалорийной пищей приводит к тому, что процессы метаболизма замедляются, система детоксикации функционирует не должным образом. Это может стать причиной ухудшения самочувствия и возникновения неприятных симптомов, таких как головная боль, тошнота и расстройство пищеварения.</p>
                                            <p className="vitaminsTab-content-description-text">Избежать столь нежелательных последствий поможет периодическая разгрузка организма, которая заключается в смене привычного режима питания. Однако самостоятельно принимать решение о необходимости разгрузочных дней не рекомендуется - сначала следует проконсультироваться с врачом.</p>
                                        </div>
                                    </div>
                                    <div className="vitaminsTab-content-list border-padding">
                                        <div className="vitaminsTab-content-list-img">
                                            <span>—</span>
                                        </div>
                                        <ul className="vitaminsTab-content-list-box">
                                            <h3 className="h3">Склонность к набору веса и гены</h3>
                                            <p className="vitaminsTab-content-description-text">Некоторые гены, которые передались современному человеку от предков, отвечают за формирование подкожно-жировой клетчатки в организме. Если в них обнаружены дефекты, то происходит накопление жировых отложений, что вызывает повышение массы тела и является благоприятным фактором для развития сопутствующих заболеваний.</p>
                                            <p className="vitaminsTab-content-description-text">Наследственный фактор играет немаловажную роль в этом процессе, поскольку раньше люди не имели свободного круглогодичного доступа к жирной пище, что и привело к мутации генов. В современных условиях организм не утратил этой способности к накоплению запасов в виде жировой ткани, несмотря на то, что потребность в энергетических ресурсах не настолько высока, особенно при незначительных нагрузках.</p>
                                        </ul>
                                    </div>
                                    <div className="vitaminsTab-content-list border-padding">
                                        <div className="vitaminsTab-content-list-img plus">
                                            <span className="icon-plus"></span>
                                        </div>
                                        <ul className="vitaminsTab-content-list-box">
                                            <h3 className="h3">Польза разгрузочных дней</h3>
                                            <p className="vitaminsTab-content-description-text">Одним из наиболее эффективных способов коррекции такого типа метаболизма, являются разгрузочные дни. В зависимости от индивидуальной предрасположенности к набору веса на основе результатов генетического теста подобрать оптимальную схему питания - это может быть полный отказ от еды на 24 часа или сниженное потребление калорий. При грамотно составленной программе питания регулярные разгрузочные дни способствуют продлению и повышению качества жизни.</p>
                                        </ul>
                                    </div>
                                    <div className="vitaminsTab-content-result">
                                        <h3 className="h3">Ваши результаты</h3>
                                        <ul>
                                            <li className="vitaminsTab-content-description-text">Ген</li>
                                            <li className="vitaminsTab-content-description-text">Полиморфизм</li>
                                            <li className="vitaminsTab-content-description-text"> ваш тип</li>
                                        </ul>
                                        {
                                            customerFoodLk.fastingDays.results.map((data, idx) => {
                                                return (
                                                    <ul key={idx}>
                                                        <li className="vitaminsTab-content-description-text">{data.gene}</li>
                                                        <li className="vitaminsTab-content-description-text">{data.polymorphism}</li>
                                                        <li className="vitaminsTab-content-description-text">{data.type}</li>
                                                    </ul>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="vitaminsTab-content-recommendation">
                                        <h3 className="h3">
                                            <span className="icon-like"></span>
                                            <span>`Расшифровка и рекомендации генетика</span>
                                        </h3>
                                        <p className="vitaminsTab-content-description-text">{customerFoodLk.fastingDays.recommendations}</p>
                                    </div>
                        </div>
                    </div>
                </div>            
        )
}
export default Habits